import React, { useEffect, useRef, useState } from "react";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import { MONITORING_TIME, MONITORING_TIME_Params } from "pages/newDashboard/dashboardUtils";
import { fetchDashboardAverageHandlingTime } from "redux/actions/services/dashboard";
import { useDispatch, useSelector } from "react-redux";
import AvgHandlingTime from "pages/newDashboard/DayView/AvgHandlingTime";

export default function WeeklyAverageHandlingTime() {
  // const classes = useStyles()();
  // const { t } = useTranslation();
    const dispatch = useDispatch();
    const filterDashboard = useSelector(({ dashboardFilters }) => dashboardFilters);
    const [averageHandlingTime, setAverageHandlingTime] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const fetchAverageHandlingTime = (withLoading = true) => {
        setIsLoading(withLoading);
        dispatch(
            fetchDashboardAverageHandlingTime({
                ...filterDashboard
            })
        ).then((res) => {
            setAverageHandlingTime(res?.data);
            setIsLoading(false);
        });
    }

    const fetchIntervalRef = useRef(null);
    useEffect(() => {
        handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchAverageHandlingTime, MONITORING_TIME_Params)
        return () => {
            clearInterval(fetchIntervalRef.current)
        }
    }, [filterDashboard])

    useEffect(() => {
        fetchAverageHandlingTime()
    }, [filterDashboard]);

  return (
    <AvgHandlingTime avgHandlingTime={averageHandlingTime} isLoading={isLoading} />
  );
}
