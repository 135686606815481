import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    moreInfoTooltipTitle: {
        fontWeight: 700,
        fontSize: "0.9rem",
        color: theme.custom.color.color6,
        marginBottom: theme.spacing(0.8),
    },
    itemDataName: {
        fontWeight: 700,
        fontSize: "0.8rem",
        color: theme.palette.primary.main,
        lineHeight: 1.5
    },
    itemDataValue: {
        fontWeight: 400,
        fontSize: "0.8rem",
        color: theme.palette.primary.main
    },
    infoIcon: {
        color: theme.custom.color.chartColor2,
        fontSize: "1rem"
    }
}));
