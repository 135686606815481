import { useSelector } from "react-redux";
import get from "lodash/get";
import { getUserPermissions } from "util";

// returns a component if the user is permitted
export const HasPermission = ({ name, children }) => {
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const permissions = getUserPermissions(currentUser);

  for (let i = 0; i < permissions[1].modules.length; i += 1) {
    const res = permissions[1].modules[i].actions?.some((action) => action.name.trim() === name && action.enabled);
    if (res) {
      return children;
    }
  }
  return null;
};

// returns a boolean is user has permissions
export const isMenuPermitted = (currentUser, name) => {
  const permissions = getUserPermissions(currentUser);
  const res = permissions[1].modules.some((module) => (
    (module.name.trim() === name || module.label?.trim() === name)
      && module.enabled
  ));
  return !!res;
};

// returns a boolean is user has permissions
export const isPermitted = (currentUser, name) => {
  const permissions = getUserPermissions(currentUser);

  for (let i = 0; i < permissions[1].modules.length; i += 1) {
    const res = permissions[1].modules[i].actions?.some((action) => (
      (action.name.trim() === name || action.label?.trim() === name)
        && action.enabled
    ));
    if (res) {
      return true;
    }
  }
  return false;
};

export const isSubModulePermitted = (currentUser, name) => {
  const permissions = getUserPermissions(currentUser);

  for (let i = 0; i < permissions[1].modules.length; i += 1) {
    if (
      (permissions[1].modules[i].subMenu === name || permissions[1].modules[i].name === name)
      && permissions[1].modules[i].enabled
    ) {
      return true;
    }
  }
  return false;
};

export const isActionPemitted = (currentUser, subMenu, action) => {
  const permissions = getUserPermissions(currentUser);
  let actionsIndex = -1;
  for (let i = 0; i < permissions[1].modules.length; i += 1) {
    if (
      permissions[1].modules[i].label === subMenu
      && permissions[1].modules[i].enabled === true
    ) {
      actionsIndex = i;
      break;
    }
  }
  if (actionsIndex !== -1) {
    for (
      let i = 0;
      i < permissions[1].modules[actionsIndex].actions.length;
      i += 1
    ) {
      const actionElement = permissions[1].modules[actionsIndex].actions[i];
      if (actionElement.name === action && actionElement.enabled === true) return true;
    }
  }
  return false;
};

export const isActionPermitted = (currentUser, isTargetPage, action) => ((isTargetPage && isPermitted(currentUser, action)))

export const disableModuleChange = (moduleName) => ["Tags Module", "Administration", "SR Queues"].includes(moduleName);
export const disableSrQueues = (moduleName) => ["SR Queues"].includes(moduleName);
