import {
    DELETE_EXPORTED_FILE, EXPORT_ANALYSIS_TABLE_DATA, EXPORT_EXCEPTIONS, EXPORT_EXECUTIONS,
    EXPORT_FILE_BY_NAME,
    EXPORT_FILTERED_EXECUTIONS, EXPORT_OCCURENCES, EXPORT_PROCESS_EXECUTION_EXCEPTIONS,
    FETCH_EXPORTED_FILES,
    FETCH_EXPORT_AUDIT_LOGS
} from "../../constants";
import { URL_BASE_EXPORT } from "../../constants/endpoints";
import { onExportSuccess, formatDateTimeIsoParam, formatDateParam } from "util";

const portalTotalItemsDefault = 10_000;
export const exportFile = (filename) => ({
    type: EXPORT_FILE_BY_NAME,
    request: {
        url: `${URL_BASE_EXPORT}exported/${filename}`,
        method: "get",
        responseType: "blob",
    },
    meta: {
        onSuccess: (response) => onExportSuccess(response, "unamed_export.xlsx")
    },
});

export const exportSelectedFiles = (filenames) => ({
    type: EXPORT_FILE_BY_NAME,
    request: {
        url: `${URL_BASE_EXPORT}exportedzip/selected`,
        method: "get",
        responseType: "blob",
        params: {
            filenames: filenames.join(","),
        },
    },
    meta: {
        onSuccess: (response) => onExportSuccess(response, "unamed_export.zip")
    },
});

export const deleteExportedFileByName = (filename, onSuccess, onError) => ({
    type: DELETE_EXPORTED_FILE,
    request: {
        url: `${URL_BASE_EXPORT}deleteexported/${filename}`,
        method: "delete",
    },
    meta: {
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: () => {
            if (onError) onError();
        },
    },
});
export const deleteSelectedExportedFiles = (filenames, onSuccess, onError) => ({
    type: DELETE_EXPORTED_FILE,
    request: {
        url: `${URL_BASE_EXPORT}deleteexported/selected`,
        method: "delete",
        params: {
            filenames: filenames.join(","),
        },
    },
    meta: {
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: () => {
            if (onError) onError();
        },
    },
});

export const fetchExportedFiles = (onSuccess, onError) => ({
    type: FETCH_EXPORTED_FILES,
    request: {
        url: `${URL_BASE_EXPORT}exportedfiles`,
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});
export const exportFilteredExecutionsXLSX = (
    sortField,
    sortOrder,
    processes,
    status,
    launchingDatetime,
    endDatetime,
    searchText,
    resources,
    triggers,
    tags,
    onSuccess,
    onError,
    portalTotalItems = portalTotalItemsDefault
) => ({
    type: EXPORT_FILTERED_EXECUTIONS,
    request: {
        url: encodeURI(`${URL_BASE_EXPORT}executions${searchText ? `?searchText=${searchText}` : ""}`),
        params: {
            sortField,
            sortOrder,
            processes: processes.join(","),
            status: status.join(","),
            triggers: triggers.join(","),
            resources: resources.join(","),
            tagsIds: tags.join(),
            launchingDatetime,
            endDatetime,
            portalTotalItems
        },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        }
    }
});
export const exportExecutionsXLSX = (processId, statusIds, portalTotalItems = portalTotalItemsDefault) => ({
    type: EXPORT_EXECUTIONS,
    request: {
        url: `${URL_BASE_EXPORT}process/executions`,
        params: {
            processId,
            status: statusIds?.join(","),
            portalTotalItems
        },
    },
});
export const exportOccurrencesXLSX = (
    processes,
    fleetIds,
    loadedDatetime,
    completedDatetime,
    searchText,
    isPending = false,
    processExecutionIds = [],
    separateDetails,
    sortOrder,
    sortField,
    tagsIds,
    portalTotalItems = portalTotalItemsDefault
) => {
    const exportUrl = isPending
        ? encodeURI(`${URL_BASE_EXPORT}occurrences/pending${searchText ? `?searchText=${searchText}` : ""}`)
        : encodeURI(`${URL_BASE_EXPORT}occurrences${searchText ? `?searchCriteria=${searchText}` : ""}`);

    return {
        type: EXPORT_OCCURENCES,
        request: {
            url: exportUrl,
            params: {
                fleetIds: fleetIds?.join(","),
                processes: processes?.join(","),
                [isPending ? "filterStartDate" : "loadedDatetime"]: formatDateTimeIsoParam(loadedDatetime),
                [isPending ? "filterEndDate" : "completedDatetime"]: formatDateTimeIsoParam(completedDatetime),
                processExecutionIds: processExecutionIds.join(","),
                separateDetails,
                sort: `${sortOrder},${sortField}`,
                tagsIds,
                portalTotalItems
            },
        },
    };
};
export const exportProcessExecutionExceptionXLSX = (
    processes,
    exceptionsType,
    exceptions,
    fleets,
    executionStartTime,
    executionEndTime,
    selected,
    hidden,
    searchText,
    sortOrder,
    sortField,
    tags,
    portalTotalItems = portalTotalItemsDefault
) => ({
    type: EXPORT_PROCESS_EXECUTION_EXCEPTIONS,
    request: {
        url: encodeURI(
            `${URL_BASE_EXPORT}process-execution/exceptions?processes=${processes?.join()}&exceptionsType=${exceptionsType?.join()}&exceptions=${exceptions?.join()}&fleetIds=${fleets
                .map((e) => e.id)
                .join()}${searchText ? `&searchText=${searchText}` : ""}`,
        ),
        responseType: "blob",
        params: {
            executionStartTime: formatDateTimeIsoParam(executionStartTime),
            executionEndTime: formatDateTimeIsoParam(executionEndTime),
            selectedExceptions: selected?.join(","),
            tagsIds: tags.join(),
            hidden,
            sortField,
            sortOrder,
            portalTotalItems
        },
    },
});
export const exportExceptionXLSX = (
    processes,
    exceptionsType,
    exceptions,
    fleets,
    executionStartTime,
    executionEndTime,
    selected = [],
    hidden,
    searchText,
    processExecutionIds = [],
    workFlowStatus = [],
    handlersIds = [],
    sortField,
    sortOrder,
    separateDetails = false,
    divisionsIds,
    tags,
    portalTotalItems = portalTotalItemsDefault
) => ({
    type: EXPORT_EXCEPTIONS,
    request: {
        url: encodeURI(`${URL_BASE_EXPORT}exceptions?processes=${processes?.join()}&exceptionsType=${exceptionsType?.join()}&exceptions=${exceptions?.join()}&fleetIds=${fleets
            .map((e) => e.id)
            .join()}${searchText ? `&searchCriteria=${searchText}` : ""}`),
        params: {
            executionStartTime: formatDateTimeIsoParam(executionStartTime),
            executionEndTime: formatDateTimeIsoParam(executionEndTime),
            selectedExceptions: selected?.join(","),
            hidden,
            processExecutionIds: processExecutionIds.join(),
            workFlowStatus: workFlowStatus.join(),
            assignersIds: handlersIds.join(),
            tagsIds: tags?.join(),
            sort: `${sortOrder},${sortField}`,
            separateDetails,
            divisionsIds,
            portalTotalItems
        },
    },
});
export const exportAnalysisTableData = (selectedProcesses, from, to, kpi, customKpisIds, tags, page, size, exportAll = false, selectedExceptionsTypes, onSuccess, onError) => {
    const processIds = selectedProcesses.map((e) => e.id);
    const tagsIds = tags?.map((e) => e.id)?.join() ?? "";
    return {
        type: EXPORT_ANALYSIS_TABLE_DATA,
        request: {
            url: `${URL_BASE_EXPORT}analysis/table`,
            params: {
                processIds: `${processIds?.join()}`,
                kpi: `${kpi.join()}`,
                from: formatDateParam(from),
                to: formatDateParam(to),
                customKpisIds: `${customKpisIds.join()}`,
                exceptionsTypes: selectedExceptionsTypes.join(),
                tagsIds,
                page,
                size,
                exportAll
            }
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess();
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                throw error;
            },
        }
    };
};

export const exportLogsData = (auditFilters) => ({
    type: FETCH_EXPORT_AUDIT_LOGS,
    request: {
        url: `${URL_BASE_EXPORT}audit/log`,
        params: {
            searchQuery: auditFilters?.searchQuery,
            actions: auditFilters?.actions?.join(),
            objects: auditFilters?.objects?.join(),
            users: auditFilters?.users?.join(),
            modules: auditFilters?.modules.join(),
            from: formatDateTimeIsoParam(auditFilters?.period?.from),
            to: formatDateTimeIsoParam(auditFilters?.period?.to)
        }
    }
});
