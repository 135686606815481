import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  image: {
    width: 40,
    height: 40,
  },
  avatar: {
    width: 40,
    height: 40,
    fontSize: "0.9rem",
    fontWeight: 600,
  },
  content: {
    whiteSpace: "nowrap",
    fontSize: "0.85rem",
    color: theme.palette.primary.main,
    fontWeight: 700,
    maxWidth: 192,
    textOverflow: "ellipsis",
    overflow: "hidden",

  },
  avatarColor: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  drawerPaper: {
    width: "500px",
    padding: theme.spacing(0, 2, 2, 2),
  },
  configBox: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    "& button": {
      marginTop: theme.spacing(1),
    },
  },
  disabledConfigBox: {
    backgroundColor: "#F5F5F5",
  },
  successIcon: {
    color: theme.palette.success.main,
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  alert: {
    position: "relative",
    margin: "auto",
  },
  circularProgress: {
    marginLeft: "2px",
  },
  containerTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    fontWeight: 700,
    marginBottom: "20px",
  }
}));
