import React, { useEffect } from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { Box, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LaunchRoundedIcon from "@material-ui/icons/LaunchRounded";
import CustomDialog from "../CustomDialog";
import CopyToClipboardButton from "../CopyToClipboardButton";
import useStyles from "./style";

const SUPPORTED_PROTOCOLS = ["https:", "http:", "ftp:", "ftps:", "sftp:"]

const isUrl = (string) => {
  if (string?.startsWith("www.") && string?.slice(4)?.includes(".")) return (`https://${string}`)

    let tempURL;
    try {
      tempURL = new URL(string);
    } catch (error) {
      return false;
    }
    return SUPPORTED_PROTOCOLS.includes(tempURL.protocol);
}

function Details(props) {
  const [detail, setDetail] = React.useState("");
  const [gridSize, setGridSize] = React.useState(12);
  const [sizeDialog, setSizeDialog] = React.useState(null);
  const {
    logDetail, data, validate, openStart, handleClose,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getGridSize = (length) => {
    if (length <= 20) return 12;
    return 12 / Math.floor(length / 20);
  };

  useEffect(() => {
    if (logDetail) {
      setDetail(JSON.parse(logDetail));
      const { length } = Object.entries(detail);
      if (length <= 20) setGridSize(12);
      else {
        setGridSize(12 / Math.floor(length / 20));
      }
    }
    if (data) {
      setSizeDialog(getSizeDialog(data));
    }
  }, [logDetail, data, sizeDialog]);

  const getSizeDialog = (array) => {
    const max = Math.max.apply(
      Math,
      array.map((o) => o?.value?.length ?? 0),
    );
    const maxSize = max * 11 > 1440 ? 1440 : max * 11;

    return `${maxSize}px`;
  };

  const renderValue = (value) => (isUrl(value) ? (
    <a href={typeof isUrl(value) === "string" ? isUrl(value) : value} target="_blank" rel="noreferrer">
      {value}
      <LaunchRoundedIcon className={classes.linkIcon} />
    </a>
        )
        : value || "---")

  return (
    <>
      {validate && (
        <CustomDialog
          open={openStart}
          onClose={handleClose}
          aria-labelledby="details-dialog"
          maxWidth="lg"
          fullWidth
          withDivider
          withCloseButton
          padding={20}
        >
          <DialogContent className={classes.dialogContent}>
            <DialogContentText id="confirm_message-dialog-content">
              {detail != null && (
                <Grid container xs={12}>
                  {detail?.length > 0
                    ? detail.map((row) => Object.entries(row).map((entry) => {
                      const [key, value] = entry;

                      return (
                        <Grid
                          item
                          container
                          xs={getGridSize(Object.entries(row).length)}
                        >
                          <Grid item xs={5}>
                            {" "}
                            <Typography
                              className={classes.field}
                              variant={gridSize === 12 ? "subtitle1" : "subtitle2"}
                              align="left"
                            >
                              {" "}
                              {key}
                              {" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant={gridSize === 12 ? "subtitle1" : "subtitle2"}
                              align="left"
                              className={classes.value}
                            >
                              {" "}
                              {renderValue(value)}
                              {" "}
                            </Typography>
                            {" "}
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                      );
                    }))
                    : Object.entries(detail).map((entry) => {
                      const [key, value] = entry;
                      return (
                        <Grid
                          item
                          container
                          xs={getGridSize(Object.entries(detail).length)}
                        >
                          <Grid item xs={5}>
                            {" "}
                            <Typography
                              className={classes.field}
                              variant={gridSize === 12 ? "subtitle1" : "subtitle2"}
                              align="left"
                            >
                              {" "}
                              {key}
                              {" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant={gridSize === 12 ? "subtitle1" : "subtitle2"}
                              align="left"
                              className={classes.value}
                            >
                              {" "}
                              {renderValue(value)}
                              {" "}
                            </Typography>
                            {" "}
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                      );
                    })}
                </Grid>
              )}
            </DialogContentText>
          </DialogContent>
        </CustomDialog>
      )}
      {!validate && sizeDialog != null && data?.length && (
        <CustomDialog
          open={openStart}
          onClose={handleClose}
          aria-labelledby="details-dialog"
          fullWidth
          classes={{ paper: classes.dialogPaper }}
          title={t("breadcrumbs.label.details")}
          withDivider
          withCloseButton
          padding={20}
        >
          <Box className={classes.dialogContent}>
            {data.map(({ name, value }) => (
              <Grid container xs={12} className={classes.dataGrid}>
                <Grid item xs={5}>
                  <Typography
                    className={classes.field}
                    align="left"
                  >
                    {name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={classes.value}
                    component="div"
                    align="left"
                  >
                    {renderValue(value)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {value && (
                    <CopyToClipboardButton
                      text={value}
                      className={classes.icon}
                    />
                  )}
                </Grid>
              </Grid>
              ))}
          </Box>
        </CustomDialog>
      )}
    </>
  );
}

export default Details;
