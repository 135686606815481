import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    rowActionButtons: {
        color: theme.custom.color.icons.visibility,
    },
    deleteIcon: {
        color: theme.custom.color.icons.delete,
    },
    disableDelete: {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "transparent"
    },
}));
