import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useDispatch } from "react-redux";
import { Switch, Route, useLocation } from "react-router-dom";
import WrapperContainer from "../WrapperContainer";
import { fetchCurrentUser } from "../redux/actions/services";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants";
import SignUp from "../pages/Signup";
import { ToastContainer } from "react-toastify";
import License from "../pages/License";
import i18n from "../i18n";
import supportedLanguages from "../components/Language/supportedLanguages";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const setupLocale = () => {
      const { local, timeZone } = currentUser ?? {};
      if (timeZone) {
          localStorage.setItem("sr-tz", timeZone);
      }
      if (local) {
          i18n.changeLanguage(local);
          localStorage.setItem("lgn", local);
      } else if (location.search.includes("locale=")) {
          const locale = location.search.split("=")[1];
          if (locale && supportedLanguages.map(({ key }) => key).includes(locale)) {
              i18n.changeLanguage(locale);
              localStorage.setItem("lgn", locale);
          }
      }
  }
  useEffect(() => {
      if (location.pathname !== "/license") dispatch(fetchCurrentUser());
  }, [dispatch]);
  useEffect(() => {
      setupLocale()
  }, [currentUser])

  return (
    <>
      <CssBaseline />
      <WrapperContainer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

const AppWithSignUp = () => (
  <>
    <CssBaseline />
    <Switch>
      <Route key="signup-page" path="/aws/signup" component={SignUp} />
      <Route key="license-page" path="/license" component={License} />
    </Switch>
    <App />
  </>
);

export default AppWithSignUp;
