import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  tabs: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    "& .MuiTabs-indicator": {
      backgroundColor: theme.custom.color.color6,
      height: 6,
    },
  },
  tab: {
    border: `1px solid ${theme.custom.color.containersBorder}`,
  },
  firstGrid: {
    width: "100%",
  },
}));
