import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LaunchRoundedIcon from "@material-ui/icons/LaunchRounded";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import clsx from "clsx";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { fetchLinksByProcess, deleteLink } from "../../../../redux/actions/services/links";
import { handleDisplayedLabel } from "util";
import EnhancedTableHead from "../../../../components/DataTable/EnhancedTableHead";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import { EnhancedTableToolbar } from "../../../../components/DataTable/EnhancedTableToolbar";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import DataNotFound from "../../../../components/DataNotFound";
import CustomPagination from "../CustomPagination";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import { SearchFilter } from "../../../../components/Filter";
import { isPermitted } from "components/HasPermission";

const ExternalDocInfo = (props) => {
  const { classes, process } = props;
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const [row, setRow] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState("");
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const { t } = useTranslation();
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("link.name"),
      sortable: false,
    },
    {
      id: "url",
      numeric: false,
      disablePadding: false,
      label: t("link.url"),
      sortable: false,
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: t("link.description"),
      sortable: false,
    },

    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: t("Actions"),
      sortable: false,
    },
  ];
  const sortByList = [
    { code: "name,desc", label: "Name (descending)" },
    { code: "name,asc", label: "Name (ascending)" },
    { code: "description,desc", label: "Description (descending)" },
    { code: "description,asc", label: "Description (ascending)" },
  ];
  const getLinksList = (page, size, sort, searchCriteria) => {
    setIsLoaded(false);
    props
        .fetchLinksByProcess(
            process.id,
            page,
            size,
            sort,
            searchCriteria
        )
        .then((result) => {
            setData(result?.data?.content);
            setCount(result?.data?.totalElements);
        })
        .then(() => setIsLoaded(true));
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
      getLinksList(page, rowsPerPage, orderBy, searchText);
    }
  }, [
    orderBy,
    page,
    rowsPerPage,
    searchText,
  ]);

  useEffect(() => {
    if (props.dataChanged) {
      getLinksList(page, rowsPerPage, orderBy, searchText);
      props.setDataChanged(false)
    }
  }, [props.dataChanged]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getLinksList(newPage, rowsPerPage, orderBy, searchText);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getLinksList(0, event.target.value, orderBy, searchText);
  };

  const handlePrevious = (page) => {
    setPage(page - 1);
    getLinksList(page - 1, rowsPerPage, orderBy, searchText);
  }

  const handleNext = (page) => {
    setPage(page + 1);
    getLinksList(page + 1, rowsPerPage, orderBy, searchText, searchText);
  };
  const handleChangeSortBy = (value) => {
    setOrderBy(value.code)
    getLinksList(page, rowsPerPage, value.code, searchText)
  }

  const handleChangeSearchText = (e) => {
    const { value } = e.target;
    setSearchText(value);
    getLinksList(page, rowsPerPage, orderBy, value)
  };

  const redirect = (event, row) => {
    window.open(row?.url, "_blank")
  };

  const deleteLink = (event, row) => {
    setRow(row);
    setOpenMsgConfirm(true);
  };

  const update = (event, row) => {
    props.onClickEdit(row);
  };
  const cancelDelete = () => {
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    setIsLoading(true);
    props.deleteLink(row.id).then(() => {
      toast.success(t("link.delete.success"))
      setOpenMsgConfirm(false);
      getLinksList(page, rowsPerPage, orderBy, searchText);
      setIsLoading(false);
    });
  };

  return (
    <div className={classes.root}>
      <Paper elevation="0" className={classes.paper}>
        <EnhancedTableToolbar
            filtred
            numSelected={0}
            button={isPermitted(currentUser, "Manage external documentation") && props.button}
            filterWidth={6}
            filterInput={
              <Grid container item spacing={3} alignItems="center">
                <Grid item xs={5} className={classes.searchContainer}>
                  <SearchFilter
                      placeHolder={t("Search")}
                       callback={handleChangeSearchText}
                        className={classes.searchIcon}
                        value={searchText}
                  />
                </Grid>

                <Grid item xs={3}>
                  <CustomAutoComplete
                      options={sortByList ?? []}
                      optionLabel={(option) => handleDisplayedLabel(t(option?.label))}
                      optionUuid="code"
                      value={sortByList.find(({ code }) => orderBy === code)}
                      onChange={(value) => handleChangeSortBy(value)}
                      label={t("sortBy")}
                  />
                </Grid>
              </Grid>
            }
        />
        <TableContainer className={classes.linkTable}>
          {isLoaded
              ? (
                <Table stickyHeader aria-label="Actions Table" classes={{ root: classes.table }}>
                  {count > 0 && <EnhancedTableHead
                      classes={classes}
                      order={orderBy}
                      orderBy={orderBy}
                      rowCount={count}
                      headCells={headCells}
                  /> }
                  <TableBody>
                    {count > 0 ? (
                        data.map(
                            (row) => (
                              <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}
                                  className={classes.tableRow}
                              >
                                <TableCell align="left" className={classes.tableCell}>
                                  {row.name ? row.name : "---"}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    align="left"
                                    className={clsx(classes.tableCell, classes.urlTableCell)}
                                >
                                  <Tooltip title={row.url}>
                                    <Typography noWrap variant="body2" className={classes.urlText}>{row?.url}</Typography>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="left" className={clsx(classes.tableCell, classes.descriptionTableCell)}>
                                  {row?.description}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={clsx(classes.tableCell, classes.actionsTableCell)}
                                >
                                  <Grid container wrap="nowrap">
                                    <Tooltip title={t("tooltip.visit.link")}>
                                      <IconButton className={classes.negativeLeftMargin} onClick={(event) => redirect(event, row)}>
                                        <LaunchRoundedIcon />
                                      </IconButton>
                                    </Tooltip>
                                    {isPermitted(currentUser, "Manage external documentation")
                                    && (
                                      <Tooltip title={t("tooltip.edit.link")}>
                                        <IconButton onClick={(event) => update(event, row)}>
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {isPermitted(currentUser, "Manage external documentation")
                                    && (
                                      <Tooltip title={t("tooltip.delete.link")}>
                                        <IconButton
                                            onClick={(event) => deleteLink(event, row)}
                                        >
                                          <DeleteIcon className={classes.deleteIcon} />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            ),
                        )
                    ) : <DataNotFound
                            padding={50}
                            message={t("no.link.message")}
                        />}
                  </TableBody>
                </Table>
              )
              : <CircularLoader />}
        </TableContainer>

        {count > 0 && <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
            onChangePage={handleChangePage}
            onNext={handleNext}
            onPrevious={handlePrevious}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />}
      </Paper>
      {openMsgConfirm && (
        <ConfirmMessage
            message={t("confirm.delete.link")}
            openStart={openMsgConfirm}
            onCancel={cancelDelete}
            onConfirm={confirmDelete}
            isDelete="true"
            buttonConfirm={t("Delete")}
            buttonCancel="Cancel"
            isLoading={isLoading}
        />
      )}
    </div>
  );
};
const mapDispatchToProps = {
  fetchLinksByProcess,
  deleteLink,
};
export default connect(null, mapDispatchToProps)(ExternalDocInfo);
