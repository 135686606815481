import { lighten, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
        paddingLeft: ({ paddingLeft }) => (paddingLeft || theme.spacing(0)),
        paddingRight: theme.spacing(0),
        columnGap: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                marginBottom: theme.spacing(1),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
                marginBottom: theme.spacing(2),
            },
    title: {
        flexGrow: "1",
        marginBottom: 20,
        color: theme.palette.primary.main
    },
}));
