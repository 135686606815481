import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "transparent",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    tableHeaderContainer: {
        margin: theme.spacing(3, 0, 1),
        paddingRight: theme.spacing(2),
    },
    rowActionButtons: {
        color: theme.custom.color.icons.visibility,
        padding: theme.spacing(0.75)
    },
    deleteIcon: {
        color: theme.custom.color.icons.delete,
    },
    switchLabel: {
        marginRight: 0,
        marginLeft: theme.spacing(0.5)
    },
    hideIcon: {
        color: theme.custom.color.icons.error,
        fontSize: 18,

    },
    showIcon: {
        color: theme.custom.color.icons.valide,
        fontSize: 18,
    },
    avatarColor: {
        backgroundColor: theme.palette.primary.main,
    },
    alertContainer: {
        marginBottom: theme.spacing(2)
    },
    dialog: {
        "& .MuiDialog-paper": {
            width: "830px",
            maxWidth: "unset",
        },
    },
    dialogContainer: {
        "& .MuiDialog-paperScrollPaper": {
            maxHeight: "calc(100% - 20px)",
        },
    },
    disableDelete: {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "transparent"
    },
    instanceOwnerTag: {
        color: theme.custom.color.icons.visibility
    },
    permissionsForm: {
        border: `1px solid ${theme.custom.color.containersBorder}`,
        padding: theme.spacing(0.5, 1),
        marginTop: 10,
    },
    moduleLabel: {
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    subModuleLabel: {
        fontWeight: 600,
        color: theme.palette.primary.main,
        fontSize: "0.9rem"
    },
    moduleDivider: {
        backgroundColor: theme.custom.color.containersBorder,
        width: 2,
        height: "60%",
        marginRight: 20
    },
    copyButton: {
        marginLeft: 10
    }
}));
