import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ArrowForward as ExitIcon } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import {
  resetStepper,
  resetQualification,
} from "../../../redux/slices/stepperSlice";
import { OPPORTUNITY_ASSESSMENT_BASE_URL } from "util/helpers/route.helper";
import ProcessStepper from "../../../components/ProcessStepper";
import ConfirmMessage from "../../../components/ConfirmMessage";
import CustomButton from "../../../components/CustomButton";
import PageHeader from "../../../components/PageHeader";
import useStyles from "./style";

const NewProcessPage = () => {
  const classes = useStyles();
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [processName, setProcessName] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const isMobileSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(resetStepper());
  }, [dispatch]);
  const onConfirm = () => {
    setIsLoading(true);
    setTimeout(() => {
      setOpenMsgConfirm(false);
      history.push(OPPORTUNITY_ASSESSMENT_BASE_URL);
      dispatch(resetQualification());
      setIsLoading(false);
    }, 500);
  };
  const onCancel = () => {
    setOpenMsgConfirm(false);
  };
  const openMsgDialog = () => {
    setOpenMsgConfirm(true);
  };
  const handleFieldValueChange = (newValue) => {
    setProcessName(newValue);
  };
  return (
    <div>
      <Grid container className={classes.root}>
        <Grid container item xs={12} className={classes.pageHeader}>
          <Grid item xs={6}>
            <PageHeader title={processName || (id ? t("Edit process") : t("New process"))} />
          </Grid>
          <Grid
            container
            item
            xs={6}
            justify="flex-end"
            alignItems="flex-start"
          >
            {/* <Button
                        className={classes.marginRight}
                        color="primary"
                        variant="contained"
                        endIcon={<SaveAsDraftIcon />}
                    >Save as draft</Button> */}
            <CustomButton
              color="secondary"
              onClick={openMsgDialog}
              variant="outlined"
              endIcon={<ExitIcon />}
            >
              {t("Exit")}
            </CustomButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card className={clsx(isMobileSm && classes.mobileCard)}>
            <CardContent className={classes.cardContent}>
              <ProcessStepper processName={processName} setProcessName={setProcessName} handleFieldValueChange={handleFieldValueChange} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {openMsgConfirm && (
        <ConfirmMessage
          message={t(
            "Your progress will be lost, are you sure you want to exit ?",
          )}
          isLoading={isLoading}
          buttonConfirm={t("Exit")}
          buttonCancel={t("Cancel")}
          openStart={openMsgConfirm}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </div>
  );
};

export default NewProcessPage;
