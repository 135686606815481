import React, { useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import getTime from "date-fns/getTime";
import format from "date-fns/format";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import i18n from "../../../../i18n";
import CustomButton from "../../../../components/CustomButton";
import useStyles from "./style";

export function addWeeks(date, weeks) {
  date.setDate(date.getDate() + 7 * weeks);
  return date;
}

export function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

const CustomToolbar = (props) => {
  const classes = useStyles();
  const [viewState, setViewState] = useState(props.view);
  const [alignment, setAlignment] = React.useState(props.view);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const { t } = useTranslation();

  const goToDayView = () => {
    props.onView("day");
    setViewState("day");
  };

  const goToWeekView = () => {
    props.onView("week");
    setViewState("week");
  };

  const goToWorkWeekView = () => {
    props.onView("work_week");
    setViewState("work_week");
  };

  const goToBack = () => {
    if (viewState === "week") {
      props.onNavigate("prev", addWeeks(props.date, -1));
    } else if (viewState === "work_week") {
      props.onNavigate("prev", addDays(props.date, -3));
    } else {
      props.onNavigate("prev", addDays(props.date, -1));
    }
  };

  const goToNext = () => {
    if (viewState === "week") {
      props.onNavigate("next", addWeeks(props.date, +1));
    } else if (viewState === "work_week") {
      props.onNavigate("next", addDays(props.date, +3));
    } else {
      props.onNavigate("next", addDays(props.date, +1));
    }
  };

  const goToToday = () => {
    props.onNavigate("current", new Date());
  };

  const handleOpenWarnings = (event, date) => props.handleOpenWarnings(event, date);
  const formatDate = () => {
    const { language } = i18n;
    const enPattern = "MM/dd/yyyy";
    const defaultPattern = "dd/MM/yyyy";
    if (language === "en") return enPattern;
    return defaultPattern;
  };
  return (
    <Box className={classes.toolbar}>
      <CustomButton
        variant="outlined"
        className={classes.todayButton}
        onClick={goToToday}
        href="#contained-buttons"
      >
        {t("Today")}
      </CustomButton>
      <div className={classes.arrow}>
        <IconButton onClick={goToBack}>
          <ArrowBackIos />
        </IconButton>
        <label className={classes.label}>
          {t(format(props.date, "EEEE "))
            + format(props.date, formatDate())}
        </label>
        {viewState === "day" && props.warningsCount && props.warningsCount.length > 0
            && props.warningsCount.map((warning) => (
                getTime(warning?.iDayOfWeek) === getTime(props.date)
                && warning?.numberOfWarningForChosenDate > 0
                && (
                <IconButton onClick={(event) => handleOpenWarnings(event, props.date)}>
                  <Badge
                          badgeContent={
                            warning.numberOfWarningForChosenDate
                          }
                          classes={{ badge: classes.alertWarning }}
                      >
                    <ReportProblemOutlinedIcon fontSize="small" />
                  </Badge>
                </IconButton>
                )
            ))}
        <IconButton onClick={goToNext}>
          <ArrowForwardIos />
        </IconButton>
      </div>
      <Box style={{ alignSelf: "center" }}>
        <ToggleButtonGroup
          color="secondary"
          value={alignment}
          exclusive
          onChange={handleChange}
          className={classes.view}
        >
          <ToggleButton value="day" onClick={goToDayView}>
            {t("Day")}
          </ToggleButton>
          <ToggleButton value="work_Week" onClick={goToWorkWeekView}>
            {t("3 DAYS")}
          </ToggleButton>
          <ToggleButton value="week" onClick={goToWeekView}>
            {t("Week")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default CustomToolbar;
