import { makeStyles, fade, darken } from "@material-ui/core/styles";
import { TABLE_ROW_STYLE } from "../../components/TableComponents/CustomTableRow";

export default makeStyles((theme) => ({
  root: {
    ...TABLE_ROW_STYLE,
    cursor: "pointer"
  },
  cardContent: {
    paddingBottom: "5px !important"
  },
  rootTabs: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
    padding: theme.spacing(0, 4),
  },
  tabs: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.common.white,
    },
  },
  firstGrid: {
    width: "100%",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    padding: "0",
  },
  action: {
    cursor: "default",
    width: "100%",
    position: "relative",
  },
  contentFilter: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  actionHead: {
    justifyContent: "right",
    paddingLeft: theme.spacing(4.5),
  },
  select: {
    padding: "12px",
  },

  downloadIcon: {
    color: theme.palette.success.main,
  },

  tableRow: {
    background: theme.palette.common.white,
    cursor: "pointer",
    padding: "20px 0px",
  },
  tableCell: {
    fontWeight: "400",
    paddingLeft: theme.spacing(3),
  },
  tableHeadLabel: {
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(16),
    padding: "10px 0",
    color: "gray",
  },

  tableCellHead: {
    borderBottom: "none",
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: fade(theme.palette.primary.main, 0.5),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  card: {
    background: "transparent",
    width: "100%",
    height: "100%",
    padding: theme.spacing(2, 4),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  center: {
    textAlign: "center",
    alignItems: "center",
  },
  right: {
    textAlign: "right",
    alignItems: "right",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
    maxWidth: 700,
  },
  deleteIcon: {
    color: theme.custom.color.icons.delete,
    padding: 0,
  },
  showIcon: {
    color: theme.custom.color.color1,
    padding: 0,
  },
  autoCompleteRight: {
    width: "85%",
    marginLeft: "52%",
  },
  tableCellAction: {
    fontWeight: "400",
  },
  image: {
    width: 40,
    height: 40,
  },
  processCard: {
    paddingLeft: theme.spacing(1),
  },
  exportButton: {
    height: 40,
    width: 150,
  },
  analyticsButton: {
    height: 40,
  },
  field: {
    fontWeight: 700,
    wordWrap: "break-word",
  },
  value: {
    wordWrap: "break-word",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    marginTop: theme.spacing(3),
  },
  itemExceptionData: {
    paddingLeft: theme.spacing(0.5),
    overflowWrap: "break-word",
  },
  showHiddenSwitch: {
    height: "42px",
    marginRight: "-25px",
    marginBottom: "3px"
  },
  clearBtn: {
    backgroundColor: theme.custom.color.color4,
    marginLeft: theme.spacing(0.5),
    color: "white",
    "&:hover": {
      background: darken(theme.custom.color.color4, 0.2),
    },
  },
  input: {
    fontSize: "1rem",
    fontFamily: "Roboto",
    fontWeight: "400",
  },
  dateInput: {
    marginTop: 0,
  },
  resourceAvatar: {
    width: 40,
    height: 40,
    marginLeft: "auto",
    marginRight: "auto",
  },
  actionButtons: {
    margin: "auto",
    marginRight: 0,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1FR)",
    columnGap: 12,
    maxHeight: 37,
  },
  exceptionReason: {
    maxWidth: 250,
    wordWrap: "break-word",
    fontWeight: 600,
    fontSize: "0.8rem",
    color: theme.palette.primary.main
  },
  exceptionReasonTooltip: {
    maxWidth: 600,
  },
  exceptionReasonTooltipWrapper: {
    position: "relative",
    maxWidth: 600,
    maxHeight: 350,
    overflowY: "hidden",
  },
  exceptionReasonText: {
    overflowY: "auto",
    fontWeight: 400,
    fontSize: "0.65rem",
    maxHeight: 350,
  },
  iconCopy: {
    position: "absolute",
    bottom: 5,
    right: 0,
    width: 40,
    height: 40,
    color: theme.custom.color.color6,
    backgroundColor: theme.palette.background.default,
    fontSize: 21,
    "&:hover": {
      color: theme.custom.color.color6,
      backgroundColor: theme.palette.background.default,
    }
  },
  exceptionReasonButton: {
    display: "flex",
    cursor: "default",
  },
  replayIcon: {
    color: theme.custom.color.icons.retry
  },
  rowActionButtons: {
    padding: 0,
    color: theme.custom.color.icons.visibility,
  },
  groupContainer: {
    paddingRight: "18px !important",
  },
  itemDataName: {
    fontWeight: 700,
    fontSize: "0.8rem",
    color: theme.palette.primary.main
  },
  itemDataValue: {
    fontWeight: 400,
    fontSize: "0.8rem",
    color: theme.palette.primary.main
  },
  autoMarginLeft: {
    marginLeft: "auto"
  },
  topPadding: {
    paddingTop: "24.5px !important",
  }
}));
