import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReplayIcon from "@material-ui/icons/Replay";
import { updatePageNumber } from "redux/slices/exceptionFilter";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  minimizeString,
} from "util";
import { HasPermission } from "../../../components/HasPermission";
import { SrWorkflowIcon } from "components/CustomIcons";
import DataNotFound from "../../../components/DataNotFound";
import AttemptChip from "../../../components/AttemptChip";
import WorkflowChip from "../ItemExceptions/Workflow/WorkflowChip";
import CustomTableContainer from "../../../components/TableComponents/CustomTableContainer";
import CustomTable from "../../../components/TableComponents/CustomTable";
import CustomTableRow from "../../../components/TableComponents/CustomTableRow";
import CustomTableCell from "../../../components/TableComponents/CustomTableCell";
import ProcessIconName from "../../../components/TableComponents/ProcessIconName";
import ExceptionsNotFound from "../../../assets/Exceptions.svg";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import AdditionalInfoTooltip from "components/AdditionalInfoTooltip";
import useStyles from "../style";

function ExceptionsContent({
  isSelected,
  onClickRow,
  data,
  getDate,
  getDateDifference,
  deleteException,
  handleClick,
  deletePermission,
  isItemList,
  endDateLabel,
  setRetryItems,
  setWorkflowItem,
  setOriginalItemId,
  rowCount,
  numSelected,
  numSelectedRetry,
  indeterminateCheck,
  checkedAll,
  onSelectAllClick,
  exceptionsToHide,
  exceptionsToShow,
  onDelete,
  showHidden,
  onShow,
  openWorkflowBulkDrawer,
  setRetryItemsHeader,
}) {
  const selectRow = (e, row) => {
    if (e.target.id !== "checkbox" && onClickRow) onClickRow(row);
  };
  const [isExceptionReasonTooltipOpen, setIsExceptionReasonTooltipOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const itemsLabels = [
    { label: "" },
    { label: t("Item Data") },
    { label: endDateLabel },
    { label: t("Exception Type") },
    { label: t("Exception Reason") },
    {
      label: rowCount > 0 && (
        <Grid container justify="flex-end" spacing={1}>
          {numSelected > 0 && (
          <>
            <Grid item>
              <HasPermission name="Workflow">
                <Tooltip title={t("exception-workflow.open-bulk")} placement="top">
                  <IconButton onClick={openWorkflowBulkDrawer} className={classes.rowActionButtons}>
                    <Badge badgeContent={numSelected}>
                      <SrWorkflowIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </HasPermission>
            </Grid>
            {numSelectedRetry > 0 && (
            <Grid item>
              <HasPermission name="Retry item exceptions">
                <Tooltip title={t("retry")} placement="top">
                  <IconButton onClick={setRetryItemsHeader} className={classes.rowActionButtons}>
                    <Badge badgeContent={numSelectedRetry}>
                      <ReplayIcon className={classes.replayIcon} />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </HasPermission>
            </Grid>)}
          </>)}
          <Grid item>
            <Checkbox
              indeterminate={numSelected > 0 && indeterminateCheck && !checkedAll}
              checked={checkedAll}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all rows" }}
              className={classes.rowActionButtons}
            />
          </Grid>
        </Grid>
      ),
    },
  ];
  const exceptionsLabels = [
    { label: "" },
    { label: t("Launching date") },
    { label: t("Exception Type") },
    { label: t("Exception Reason") },
    {
      label: rowCount > 0 && (
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            {showHidden && (
            <HasPermission name={deletePermission}>
              <Tooltip title={t("tooltip.action.show")} placement="top">
                <IconButton
                    className={classes.showIcon}
                    aria-label="view"
                    onClick={onShow}
                    disabled={
                        numSelected === 0 || exceptionsToShow?.length === 0
                    }
                >
                  <Badge
                      badgeContent={
                          exceptionsToShow?.length > 0 && exceptionsToShow?.length
                      }
                  >
                    <VisibilityIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </HasPermission>)}
          </Grid>
          <Grid item>
            <HasPermission name={deletePermission}>
              <Tooltip title={t("tooltip.action.hide")} placement="top">
                <IconButton
                  className={classes.deleteIcon}
                  aria-label="view"
                  onClick={onDelete}
                  disabled={
                      numSelected === 0 || exceptionsToHide?.length === 0
                  }
              >
                  <Badge
                    badgeContent={
                        exceptionsToHide?.length > 0 && exceptionsToHide?.length
                    }
                >
                    <VisibilityOffIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </HasPermission>
          </Grid>
          <Grid item>
            <Checkbox
              indeterminate={numSelected > 0 && indeterminateCheck && !checkedAll}
              checked={checkedAll}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all rows" }}
              className={classes.rowActionButtons}
          />
          </Grid>
        </Grid>
        )
    },
  ];
  const additionalInfos = (row) => [
    { name: t("Start date"), value: row?.startDateTime ? getDate(row?.startDateTime) : "---" },
    ...(!isItemList ? [{ name: t("End date"), value: row?.endDateTime ? getDate(row?.endDateTime) : "---" }] : []),
    ...(!isItemList ? [{ name: t("Execution Time"), value: row?.startDateTime && row?.endDateTime ? getDateDifference(row?.executionDuration) : "---" }] : []),
  ];
  const dispatch = useDispatch();
  const handleAttempClick = (originalItemId) => {
    setOriginalItemId(originalItemId);
    dispatch(updatePageNumber({ pageNo: 0 }));
  };

  const processName = (row) => (
    <CustomTableCell width={250}>
      <ProcessIconName
        imgSrc={row?.processDto?.icon}
        processName={row?.processDto?.processDescription?.processDisplayName}
      />
    </CustomTableCell>
  );
  const processDetail = (row) => (
    <CustomTableCell width={200}>
      <Grid container direction="column" spacing={1}>
        {row?.parsedData?.filter((item) => item?.displayInItemsList)?.length
            ? row?.parsedData?.map(
            (parsed, i) => parsed.displayInItemsList && (
              <Box component="span" key={`item-${i}`}>
                <Typography variant="span" className={classes.itemDataName} noWrap>
                  {parsed?.name}
                  {" "}
                </Typography>
                <Tooltip title={parsed?.value}>
                  <Typography variant="span" className={classes.itemDataValue}>
                    {": "}
                    {`${parsed?.value?.substring(0, 10)}...` || "---"}
                  </Typography>
                </Tooltip>
              </Box>
              )
          ) : (
            <Typography variant="span" className={classes.itemDataValue}>--- : ---</Typography>)}
      </Grid>
    </CustomTableCell>
    );
  const processCreationDate = (row) => (
    <CustomTableCell
      text={row?.startDateTime ? getDate(row?.startDateTime) : "---"}
    />
  );
  const processEndDate = (row) => (
    <CustomTableCell
      text={row?.endDateTime ? getDate(row?.endDateTime) : "---"}
    />
  );
  const processExecutionType = (row) => (
    <CustomTableCell
      text={row?.exceptionType ? t(row?.exceptionType) : "---"}
    />
  );

  const processExecutionReason = (row) => (
    <CustomTableCell
    >
      <Tooltip
          placement="left-start"
          classes={{ tooltip: classes.exceptionReasonTooltip }}
          interactive
          open={isExceptionReasonTooltipOpen === row?.id}
          onOpen={() => setIsExceptionReasonTooltipOpen(row?.id)}
          onClose={() => {
            setIsExceptionReasonTooltipOpen(null);
          }}
          title={
            <Box className={classes.exceptionReasonTooltipWrapper}>
              <Typography
                  variant="body2"
                  align="left"
                  component="div"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={classes.exceptionReasonText}
              >
                {row?.exceptionReason}
              </Typography>
              {row?.exceptionReason && row?.exceptionReason?.length > 1300 && (
              <CopyToClipboardButton
                      className={classes.iconCopy}
                      text={row?.exceptionReason}
                  />
              )}
            </Box>
          }
      >
        <ButtonBase className={classes.exceptionReasonButton} disableRipple>
          <Typography
              align="left"
              className={classes.exceptionReason}
          >
            {row?.exceptionReason
                ? minimizeString(row?.exceptionReason, 300)
                : "---"}
          </Typography>
        </ButtonBase>
      </Tooltip>
    </CustomTableCell>
  );
  const processActions = (row) => (
    <CustomTableCell>
      <Grid container justify="flex-end" alignItems="center" wrap="nowrap" spacing={1}>
        <Grid item>
          <AdditionalInfoTooltip data={additionalInfos(row)} />
        </Grid>
        {isItemList && (
        <>
          <Grid item>
            <WorkflowChip item={row} />
          </Grid>
          <Grid item>
            <AttemptChip
                attempt={row.attempt}
                handleAttempClick={handleAttempClick}
                originalItemId={row.originalItemId}
            />
          </Grid>
          <Grid item>
            <HasPermission name="Workflow">
              <Tooltip title={t("exception-workflow.open")}>
                <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setWorkflowItem(row);
                    }}
                    className={classes.rowActionButtons}
                >
                  <SrWorkflowIcon />
                </IconButton>
              </Tooltip>
            </HasPermission>
          </Grid>
          {row?.processDto?.isRetryable && (
          <Grid item>
            <HasPermission name="Retry item exceptions">
              <Tooltip title={t("retry")}>
                <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setRetryItems([row]);
                    }}
                    className={classes.rowActionButtons}
                >
                  <ReplayIcon className={classes.replayIcon} />
                </IconButton>
              </Tooltip>
            </HasPermission>
          </Grid>
          )}
        </>)}
        {!isItemList && (
        <Grid item>
          <HasPermission name={deletePermission}>
            <Tooltip
                title={
                  row.isDeleted
                      ? t("tooltip.action.show")
                      : t("tooltip.action.hide")
                }
            >
              <IconButton
                  className={
                    row.isDeleted ? classes.showIcon : classes.deleteIcon
                  }
                  aria-label="view"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteException(e, row);
                  }}
              >
                {row.isDeleted ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </Tooltip>
          </HasPermission>
        </Grid>
        )}
        <Grid item>
          <Checkbox
            id="checkbox"
            checked={isSelected(row?.id)}
            onChange={(event) => handleClick(event, row)}
            className={classes.rowActionButtons}
          />
        </Grid>
      </Grid>
    </CustomTableCell>
  );

  return data?.length > 0 ? (
    <CustomTableContainer>
      <CustomTable>
        <CustomTableHeader labels={isItemList ? itemsLabels : exceptionsLabels} />
        {data?.map((row, index) => (
            isItemList ? (
              <CustomTableRow key={index} onClick={(e) => selectRow(e, row)}>
                {processName(row)}
                {processDetail(row)}
                {processEndDate(row)}
                {processExecutionType(row)}
                {processExecutionReason(row)}
                {processActions(row)}
              </CustomTableRow>
            ) : (
              <CustomTableRow key={index} onClick={(e) => selectRow(e, row)}>
                {processName(row)}
                {processCreationDate(row)}
                {processExecutionType(row)}
                {processExecutionReason(row)}
                {processActions(row)}
              </CustomTableRow>
            )
          ))}
      </CustomTable>
    </CustomTableContainer>
  ) : (
    (isItemList && (
      <DataNotFound
        message={t("no.items.exception.message")}
        icon={ExceptionsNotFound}
      />
    ))
      || (!isItemList && (
        <DataNotFound
          message={t("no.process.exception.message")}
          icon={ExceptionsNotFound}
        />
      ))
  );
}

export default ExceptionsContent;
