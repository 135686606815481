import { fade, makeStyles } from "@material-ui/core/styles";

const getTranslationOffset = (percentage, isLg = false) => {
    if (percentage < 10) return { translationX: isLg ? 0 : 2, translationY: isLg ? 0 : 5 };
    if (percentage < 20 && percentage >= 10) return { translationX: isLg ? 0 : 2, translationY: isLg ? -7 : 5 };
    if (percentage < 30 && percentage >= 20) return { translationX: isLg ? 5 : 2, translationY: isLg ? -25 : 5 };
    if (percentage < 40 && percentage >= 30) return { translationX: isLg ? 15 : 2, translationY: isLg ? -30 : 5 };
    if (percentage < 50 && percentage >= 40) return { translationX: isLg ? 20 : 2, translationY: isLg ? -35 : 5 };
    if (percentage < 60 && percentage >= 50) return { translationX: isLg ? 20 : 2, translationY: isLg ? -40 : 10 };
    if (percentage < 70 && percentage >= 60) return { translationX: isLg ? 30 : 0, translationY: isLg ? -38 : 15 };
    if (percentage < 80 && percentage >= 70) return { translationX: isLg ? 40 : -5, translationY: isLg ? -30 : 15 };
    if (percentage < 90 && percentage >= 80) return { translationX: isLg ? 50 : -10, translationY: isLg ? -20 : 15 };
    if (percentage >= 90) return { translationX: isLg ? 52 : -10, translationY: isLg ? -8 : 15 };
    return { translationX: 0, translationY: 0 };
}

const useStyles = (props) => makeStyles((theme) => ({
    rootContainer: {
      marginTop: 0,
    },
    paddingTop: {
      paddingTop: theme.spacing(2)
    },
    container: {
      padding: 30,
      marginTop: 10,
      marginLeft: -8,
    },
    item: {
        display: "flex",
        flexDirection: "column"
    },
    statesContainer: {
      padding: 15,
    },
    stateIcon: {
      width: 40,
      height: 40,
      color: theme.custom.color.chartColor1,
      marginInline: "30px",
    },
    stateTitle: {
      fontSize: "1.3rem",
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    stateSubtitle: {
      fontSize: 14,
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    divider: {
      height: 30,
      width: 3,
      backgroundColor: theme.custom.color.runningBackground,
      opacity: 0.4,
    },
    userImage: {
      width: theme.spacing(7),
      margin: theme.spacing(3, "auto"),
      display: "block"
    },
    timerImage: {
      width: theme.spacing(5),
      margin: theme.spacing(3, "auto"),
      display: "block"
    },
    timerImageFillContainer: {
        width: theme.spacing(12.5),
        margin: theme.spacing(6.5, "auto"),
        display: "block",
    },
    cardHeaderTitle: {
      fontSize: "1.25rem",
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
    textBold: {
      color: theme.palette.primary.main,
      fontWeight: 800,
      fontSize: "1.25rem",
    },
    textRegular: {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: "1rem",
    },
    textTime: {
      color: theme.palette.primary.main,
      fontWeight: 650,
        [theme.breakpoints.down(1600)]: {
            fontSize: "1.35rem",
        },
        [theme.breakpoints.up(1600)]: {
            fontSize: "1.5rem",
        }
    },
    textLight: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: ".85rem",
    },
    iconColor: {
      color: theme.custom.color.chartColor3,
      marginTop: theme.spacing(0.5),
    },
    chartContainer: {
      position: "relative",
      width: "100%",
      margin: theme.spacing(0, 0, 4, 0),
    },
    needleImage: {
      width: "50px",
      position: "absolute",
      bottom: "20%",
      left: "calc(50% - 45px)",
      transform: `rotate(${props?.rotation}deg)`,
      transformOrigin: "100% 50%",
    },
    circleImage: {
      width: "35px",
      position: "absolute",
      bottom: "32px",
      [theme.breakpoints.down(1600)]: {
          left: "110px",
          transform: props?.translationX && `translate(${props.translationX + getTranslationOffset(props.percentage).translationX}px, ${props.translationY + getTranslationOffset(props.percentage).translationY}px)`,
      },
      [theme.breakpoints.up(1600)]: {
          left: "130px",
          transform: props?.translationY && `translate(${props.translationX + getTranslationOffset(props.percentage, true).translationX}px, ${props.translationY + getTranslationOffset(props.percentage, true).translationY}px)`,
      },
    },
    centeredContent: {
      position: "absolute",
      [theme.breakpoints.down(1600)]: {
          top: 65,
      },
      [theme.breakpoints.up(1600)]: {
          top: 110,
      },
    },
    sectionContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(1.5, 2),
        border: `1px solid ${fade(theme.custom.color.containersBorder, 0.4)}`,
        marginTop: 20,
        margin: theme.spacing(0, 1),
        height: "100%",
    },
    cardContainer: {
      border: `1px solid ${fade(theme.custom.color.containersBorder, 0.4)}`,
      height: "100%",
      margin: theme.spacing(2, 1, 0),
    },
    sectionTitle: {
        fontSize: "1rem",
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    sectionFirstItem: {
        "& div": {
            fontSize: "0.7rem",
            color: theme.custom.color.chartColor3,
            fontWeight: 700,
        },
    },
    sectionSecondItem: {
        "& div": {
            fontSize: "0.7rem",
            color: theme.palette.primary.main,
            fontWeight: 700,
        },
    },
    handlingTimeValue: {
        fontSize: "0.8rem",
        color: theme.palette.primary.main,
        fontWeight: 800,
    },
    filterContainer: {
      justifyContent: "space-between",
      alignItems: "center"
    },
    chip: {
        borderRadius: theme.spacing(1.5),
        fontWeight: 700,
        fontSize: "1rem",
    },
    darkChip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText,
    },
    fushiaChip: {
        backgroundColor: fade(theme.custom.color.chartColor3, 0.2),
        color: theme.custom.color.chartColor3,
    },
    lightChip: {
        backgroundColor: theme.custom.color.chartColor2,
        color: theme.palette.secondary.contrastText,
    },
    defaultChip: {
        backgroundColor: theme.custom.color.chartColor1,
        color: theme.palette.primary.main,
    },
    halfHeight: {
        height: "50%",
    },
    cardContent: {
        paddingTop: 0,
        paddingBottom: 0,
    }
  }));

export default useStyles;
