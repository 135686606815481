import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { React } from "react";

const Pagination = ({ currentPages, setCurrentPages, listkey }) => {
    const { t } = useTranslation();
    const { currentPage, totalPages } = currentPages[listkey] ?? {};
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPages((prevState) => ({ ...prevState, [listkey]: { ...prevState?.[listkey], currentPage: currentPage + 1 } }));
        }
    };
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPages((prevState) => ({ ...prevState, [listkey]: { ...prevState?.[listkey], currentPage: currentPage - 1 } }));
        }
    };

    return (
      <Box display="flex" alignItems="center" justifyContent="space-between" pl={1} visibility={totalPages > 0 ? "visible" : "hidden"}>
        <Typography variant="caption" color="primary">
          {t("page.of", {
                    page: currentPage,
                    pages: totalPages
                })}
        </Typography>
        <Box>
          <IconButton size="small" onClick={handlePrevPage} disabled={!currentPage || currentPage === 1} color="primary">
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={handleNextPage} disabled={!currentPage || currentPage === totalPages} color="primary">
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    )
}

export default Pagination;
