import React from "react";
import { OPPORTUNITY_ASSESSMENT_BASE_URL } from "util/helpers/route.helper";
import AlertsManagement from "pages/AlertsManagement";
import ExceptionsPage from "pages/ExceptionsPage";
import ViewProcessPage from "pages/ProcessPage/ViewProcessPage";
import FormPasswordReset from "pages/Profile/ResetPassword/FormPasswordReset";
import Profile from "pages/Profile";
import NewProcessPage from "pages/ProcessPage/NewProcessPage";
import AddUserForm from "pages/Administration/UserManagement/AddUserForm";
import OrchestratorForm from "pages/Administration/OrchestratorManagement/OrchestratorForm";
import FleetForm from "pages/Administration/FleetManagement/FleetForm";
import RobotForm from "pages/Administration/RobotManagement/RobotForm";
import OrchestratorLicense from "pages/Administration/OrchestratorManagement/OrchestratorLicense/OrchestratorLicense";

import { ROLES } from "util/constants";
import QueueForm from "pages/Administration/QueueManagement/QueueForm";
import FinancialParameters from "../pages/Financial";
import UsersAdministration from "../pages/Administration/UsersAdministration";
import RobotsAdministration from "../pages/Administration/RobotsAdministration";
import DivisionManagement from "../pages/Administration/DivisionManagement";
import DivisionModuleForm from "../pages/Administration/DivisionManagement/DivisionModuleForm";
import TagManagement from "pages/Administration/TagManagement";
import ProcessExecutionPage from "pages/ProcessExecutionPage";
import ProcessItemPage from "pages/ProcessItemPage";
import { isSRQueuesDisabled } from "../util/constants/PermitedFeature";
import {
  exceptionCurrentModuleRoutes,
  GROUPS_BASE_URL,
  ORCHESTRATORS_BASE_URL,
  QUEUES_BASE_URL,
  RESOURCE_BASE_URL, TAGS_BASE_URL, USERS_BASE_URL
} from "util/index";

const routes = [
  // Alerts
  {
    key: "default-alerts",
    path: "/alerts/defaultAlerts",
    component: () => <AlertsManagement />,
    permissionKey: "Default Alerts",
  },
  {
    key: "custon-alerts",
    path: "/alerts/customAlerts",
    component: () => <AlertsManagement />,
    permissionKey: "Custom Alerts",
  },

  // Exceptions
  {
    key: "processes-exceptions",
    path: `/items/${exceptionCurrentModuleRoutes.ITEMS.route}`,
    component: () => <ExceptionsPage />,
    permissionKey: "Item Exceptions",
  },

  // Process
  {
    key: "view-process-page",
    path: `${OPPORTUNITY_ASSESSMENT_BASE_URL}/rpa/view/:id`,
    component: () => <ViewProcessPage />,
  },
  {
    key: "create-process-page",
    path: `${OPPORTUNITY_ASSESSMENT_BASE_URL}/rpa/edit/:id`,
    component: () => <NewProcessPage />,
  },
  {
    // key: "process-history",
    key: "processes-exceptions",
    path: "/executions/history",
    component: () => <ProcessExecutionPage />,
    permissionKey: "Executions",
  },
  {
    key: "process-exceptions",
    path: `/exceptions/${exceptionCurrentModuleRoutes.PROCESSES.route}`,
    component: () => <ProcessExecutionPage />,
    permissionKey: "Executions",
  },

  // Profile
  {
    key: "user-profile",
    path: "/profile",
    component: () => <Profile />,
  },
  {
    key: "update-user-password",
    path: "/protected/user/profile/:userId",
    component: () => <FormPasswordReset />,
  },

  // Items
  {
    key: "completed-items",
    path: "/items/completed",
    component: () => <ProcessItemPage />,
    permissionKey: "Process Item",
  },
  {
    key: "pending-items-list",
    path: "/items/pending",
    component: () => <ProcessItemPage />,
    permissionKey: "Process Item",
  },
  {
    key: "items-exceptions",
    path: "/items/exception",
    component: () => <ProcessItemPage />,
    permissionKey: "Item Exceptions",
  },

  // Administration
  {
    key: "add-new-fleet",
    path: `${GROUPS_BASE_URL}/add`,
    component: (props) => (
      <UsersAdministration
        {...props}
        showEditModal
        modalComponent={<FleetForm />}
      />
    ),
    role: ROLES.admin,
  },
  {
    key: "financial-parameters",
    path: "/Financial",
    component: () => <FinancialParameters />,
  },
  {
    key: "admin-fleet-landing",
    path: `${GROUPS_BASE_URL}/:mode/:idFleet`,
    component: (props) => (
      <UsersAdministration
        {...props}
        showEditModal
        modalComponent={<FleetForm />}
      />
    ),
    role: ROLES.admin,
  },
  {
    key: "add-new-Orchestrators",
    path: `${ORCHESTRATORS_BASE_URL}/add`,
    component: (props) => (
      <RobotsAdministration
        {...props}
        showEditModal
        modalComponent={<OrchestratorForm />}
      />
    ),
    role: ROLES.admin,
  },
  {
    key: "add-update-queue",
    path: `${QUEUES_BASE_URL}/add`,
    component: (props) => (
      <RobotsAdministration
        {...props}
        showEditModal
        modalComponent={<QueueForm />}
      />
    ),
    permissionKey: "Add SR Queue",
    role: ROLES.admin
  },
  {
    key: "add-update-queue",
    path: `${QUEUES_BASE_URL}/:mode/:queueId`,
    component: (props) => (
      <RobotsAdministration
        {...props}
        showEditModal
        modalComponent={<QueueForm />}
      />
    ),
    permissionKey: "Edit SR Queue",
  },
  {
    key: "view-resource",
    path: `${RESOURCE_BASE_URL}/:mode/:idResource`,
    component: (props) => (
      <RobotsAdministration
        {...props}
        showEditModal
        modalComponent={<RobotForm />}
      />
    ),
    role: ROLES.admin,
  },
  {
    key: "Orchestrators-Licence",
    path: `${ORCHESTRATORS_BASE_URL}/licence/:idOrchestrator`,
    component: (props) => (
      <RobotsAdministration
        {...props}
        standAloneModalComponent={<OrchestratorLicense />}
      />
    ),
    permissionKey: "User Administration",
    role: ROLES.admin,
  },
  {
    key: "smartroby-Licence",
    path: `${GROUPS_BASE_URL}/licence`,
    component: (props) => (
      <UsersAdministration
        {...props}
        standAloneModalComponent={<OrchestratorLicense allData title="sr.sync.license" />}
      />
    ),
    permissionKey: "User Administration",
    role: ROLES.admin,
  },
  {
    key: "Orchestrators-Form-Orchestrators",
    path: `${ORCHESTRATORS_BASE_URL}/:mode/:idOrchestrator`,
    component: (props) => (
      <RobotsAdministration
        {...props}
        showEditModal
        modalComponent={<OrchestratorForm />}
      />
    ),
    role: ROLES.admin,
  },
  {
    key: "admin-user-landing",
    path: USERS_BASE_URL,
    component: () => <UsersAdministration />,
    permissionKey: "User Administration",
    role: ROLES.admin,
  },
  {
    key: "edit-create-user",
    path: [`${USERS_BASE_URL}/add`, `${USERS_BASE_URL}/:mode/:idUser`],
    component: (props) => (
      <UsersAdministration
        {...props}
        showEditModal
        modalComponent={<AddUserForm />}
      />
    ),
    permissionKey: "User Administration",
    role: ROLES.admin,
  },
  {
    key: "view-process-page",
    path: "/items/exception/currentUser",
    component: (props) => <ProcessItemPage
    {...props}
    currentUserItems
  />,
    permissionKey: "Item Exceptions",
  },
  {
    key: "add-new-division",
    path: "/userAdministration/divisions/add",
    component: (props) => (
      <DivisionManagement
            {...props}
            showEditModal
            modalComponent={<DivisionModuleForm />}
        />
    ),
    role: ROLES.admin,
  },
  {
    key: "edit-existing-division",
    path: "/userAdministration/divisions/edit/:idDivision",
    component: (props) => (
      <DivisionManagement
            {...props}
            showEditModal
            modalComponent={<DivisionModuleForm isEdit />}
            isEdit
        />
    ),
    role: ROLES.admin,
  },
  {
    key: "view-existing-division",
    path: "/resourceAdministration/divisions/view/:id",
    component: (props) => (
      <DivisionManagement
            {...props}
            showEditModal
            modalComponent={<DivisionModuleForm />}
        />
    ),
    role: ROLES.admin,
  },

  {
    key: "add-new-tag",
    path: `${TAGS_BASE_URL}/add`,
    component: (props) => (
      <TagManagement
            {...props}
            showEditModal
        />
    ),
    permissionKey: "Add tag",
    role: ROLES.admin
  },
  {
    key: "edit-existing-tag",
    path: `${TAGS_BASE_URL}/:mode/:idTag`,
    component: (props) => (
      <TagManagement
            {...props}
            showEditModal
        />
    ),
    permissionKey: "Tags Module",
    role: ROLES.admin
  },
  {
    key: "UserAdministration",
    path: [
      GROUPS_BASE_URL,
      ...!process.env.REACT_APP_DISABLE_PARTITIONING ? ["/userAdministration/divisions"] : [],
      USERS_BASE_URL,
      TAGS_BASE_URL
    ],
    component: () => <UsersAdministration />,
    role: ROLES.admin,
  },
  {
    key: "ResourceAdministration",
    path: [
      ORCHESTRATORS_BASE_URL,
      RESOURCE_BASE_URL,
      ...!isSRQueuesDisabled ? [QUEUES_BASE_URL] : [],
    ],
    component: () => <RobotsAdministration />,
    role: ROLES.admin,
    moduleEnabled: ["Tags Module", "SR Queues"]
  }
];

export default routes;
