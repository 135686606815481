import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import LaunchIcon from "@material-ui/icons/Launch";
import Replay from "@material-ui/icons/Replay";
import get from "lodash/get";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDateByLanguage } from "util";
import {
  ASK_DELETE_QUEUE,
  CONFIRM_DELETE_QUEUE,
  ASK_ENABLE_QUEUE,
  ASK_DISABLE_QUEUE,
  ASK_DELETE_ONE_QUEUE,
  ASK_DELETE_QUEUE_FROM_ONE,
  QUEUE_DELETED,
} from "util/constants";

import {
  openQueue,
  updateQueueAction,
  triggerQueueAction,
} from "redux/slices/executionManagerSlice.js";
import {
  deleteSrQueue,
  enableOrDisableSrQueue,
  handlePreDeleteSRQueue,
} from "../../../../../redux/actions/services";
import StatusBadge from "../../../../../components/StatusBadge";
import ConfirmMessage from "components/ConfirmMessage";
import CustomSwitch from "../../../../Services/components/CustomSwitch";
import {
  isPermitted,
} from "components/HasPermission/index.js";
import CustomTableRow from "../../../../../components/TableComponents/CustomTableRow";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import useStyles from "../../../../../components/TableComponents/style";

export default function QueueTableRow({
  row,
  selected,
  setSelected,
  resetEntity,
  fetchEntities,
  fetchEntitiesReset,
  enableDeleteMultiple,
  entityBaseUrl,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getFieldData = (valuePath) => get(row, valuePath);
  const active = getFieldData("status") === "ACTIVE";
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState({
    switch: false,
    deleteAction: false,
    archive: false,
  });
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const isCurrentUser = Number(currentUser?.id) === row?.id;
  const displayFields = [
    {
      id: "status",
      customContent: <StatusBadge level={getFieldData("status")} />,
      width: 50
    },
    {
      id: "name",
      valuePath: "name",
    },
    {
      id: "creationDate",
      valuePath: "createdAt",
      format: true,
    },
    {
      id: "activeSince",
      valuePath: "activeSince",
      format: true,
    },
    {
      id: "nbLicences",
      valuePath: "nbLicences",
    },
  ];
  const { queueAction, processesAction, entityId } = useSelector(
    ({ executionManager }) => executionManager
  );

  useEffect(() => {
    if (!processesAction && queueAction === CONFIRM_DELETE_QUEUE) {
      dispatch(
        deleteSrQueue(toDeleteId, processesAction, onSuccessDeleteQueue),
      );
    } else {
      handleQueueActions();
    }
  }, [queueAction]);
  const handleQueueActions = () => {
    if (queueAction && entityId === getFieldData("id")) {
      const actions = {
        CONFIRM_DELETE_QUEUE: {
          action: onDeleteQueueEntity,
        },
        ASK_DELETE_ONE_QUEUE: {
          action: confirmDelete,
        },
        CONFIRM_DISABLE_QUEUE: {
          action: onUpdateQueueStatus,
        },
        CONFIRM_ENABLE_QUEUE: {
          action: onUpdateQueueStatus,
        },
        CANCEL_QUEUE_ACTIONS: {
          action: onCancelStatus,
        },
      };
      actions[queueAction]?.action();
    }
  };
  const onCancelStatus = () => {
    onCancel();
    cancelConfirm();
    dispatch(
      triggerQueueAction({
        queueAction: null,
        processesAction: [],
        id: null,
      }),
    );
  };

  const resetLoaders = () => setIsLoading({ switch: false, deleteAction: false, archive: false });

  const handleChangeStatus = () => {
    const id = getFieldData("id");
    const orchId = row?.orchestrator?.id;

    dispatch(
      triggerQueueAction({
        queueAction: active ? ASK_DISABLE_QUEUE : ASK_ENABLE_QUEUE,
        id,
        orchId,
      }),
    );
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    if (queueAction === ASK_DELETE_ONE_QUEUE) {
      dispatch(updateQueueAction({ queueAction: ASK_DELETE_QUEUE_FROM_ONE }));
    } else {
      // Déclencher l'action au parent (QueueManagement)
      const orchId = row?.orchestrator?.id;
      dispatch(
        triggerQueueAction({
          queueAction: ASK_DELETE_QUEUE,
          id: toDeleteId,
          orchId,
        }),
      );
    }
  };

  const onDeleteQueueEntity = () => {
    setIsLoading({ ...isLoading, deleteAction: true });
    dispatch(
      handlePreDeleteSRQueue(
        entityId,
        processesAction,
        onSuccessPreDeleteQueue,
      ),
    );
  };

  const onSuccessPreDeleteQueue = (res) => {
    if (res?.data === true) {
      dispatch(deleteSrQueue(entityId, processesAction, onSuccessDeleteQueue));
    }
  };

  const onSuccessDeleteQueue = (res) => {
    if (res?.status === 200) {
      toast.success(t("queue deleted successfully"));
      terminateQueueDeleteAction();
      fetchEntitiesReset();
    } else {
      toast.error(t(res?.response?.data?.detail || "Unexpected Error"));
      terminateQueueDeleteAction();
    }
    resetLoaders();
    setOpenMsgConfirm(false);
  };
  const terminateQueueDeleteAction = () => {
    dispatch(
      triggerQueueAction({
        queueAction: QUEUE_DELETED,
        processesAction: [],
        id: null,
      }),
    );
  };

  const onUpdateQueueStatus = () => {
    const id = getFieldData("id");
    setIsLoading({ ...isLoading, switch: true });
    dispatch(handlePreDeleteSRQueue(id, processesAction)).then((res) => {
      if (res?.status === 200 && res?.data === true) {
        dispatch(
          enableOrDisableSrQueue(
            id,
            active ? "DISABLE" : "ACTIVE",
            processesAction,
          ),
        ).then((err) => {
          resetLoaders();
          if (err?.response?.data?.detail) {
            toast.error(t(err?.response?.data?.detail));
          } else {
            fetchEntities();
            dispatch(
              triggerQueueAction({
                queueAction: null,
                processesAction: [],
                id: null,
              }),
            );
          }
          setOpenConfirmationPopup(false);
        });
      }
    });
  };

  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  return (
    <>
      <CustomTableRow
        onClick={() => history.push(`${entityBaseUrl}/view/${getFieldData("id")}`)}
      >
        {displayFields.map((field, i) => (
          <CustomTableCell
            key={i}
            title={t(field?.title)}
            text={
              field.format
                ? formatDateByLanguage(getFieldData(field?.valuePath))
                : getFieldData(field?.valuePath)
            }
            width={field?.width}
          >
            {field.customContent}
          </CustomTableCell>
        ))}
        <CustomTableCell>
          <Grid container xs={12} justify="flex-end" alignItems="center">
            <Grid item>
              <Tooltip
                  placement="top"
                  title={t("queue.management.open-queue")}
                >
                <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(openQueue(row.id));
                    }}
                    className={classes.rowActionButtons}
                  >
                  <LaunchIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                  placement="top"
                  title={t("queue.management.reset-queue")}
                >
                <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      resetEntity(row.id);
                    }}
                    className={classes.rowActionButtons}
                  >
                  <Replay />
                </IconButton>
              </Tooltip>
            </Grid>
            {isPermitted(currentUser, "Edit SR Queue") && (
            <Grid item className={classes.switchContainer}>
              <Tooltip
                    placement="top"
                    title={t(`tooltip.action.${active ? "disable" : "enable"}`)}
                  >
                <CustomSwitch
                      checked={active}
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => e.stopPropagation()}
                      handleChange={() => {
                        setOpenConfirmationPopup(true);
                      }}
                      disabled={isCurrentUser}
                      value={active}
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      size="small"
                      labelClassName={classes.switchLabel}
                    />
              </Tooltip>
            </Grid>
              )}
            {isPermitted(currentUser, "Edit SR Queue") && (
              <Grid item>
                <CustomEditIcon
                    aria-label="edit"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(
                        `/queues/edit/${getFieldData(
                          "id",
                        )}`,
                      );
                    }}
                    className={classes.rowActionButtons}
                />
              </Grid>
              )}
            {isPermitted(currentUser, "Delete SR Queue") && (
              <Grid item>
                <CustomDeleteIcon
                    className={classes.rowActionButtons}
                    disabled={isCurrentUser}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(getFieldData("id"));
                      setOpenMsgConfirm(true);
                    }}
                  />
              </Grid>
              )}
            {enableDeleteMultiple && isPermitted(currentUser, "Delete SR Queue") && (
              <Grid item>
                <Checkbox
                  className={classes.rowActionButtons}
                  checked={
                    currentUser?.id !== row?.id
                    && selected?.map((item) => item?.id)?.includes(parseInt(row.id, 10))
                  }
                  disabled={isCurrentUser}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const list = [...selected];
                    e.target.checked
                      ? list.push(row)
                      : list.splice(
                            list.findIndex((item) => item.id === parseInt(getFieldData("id"), 10)),
                          1,
                        );
                    setSelected(list);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          active
            ? t("queue.management.disable.confirm-msg")
            : t("queue.management.enable.confirm-msg")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={
          active
            ? t("queue.management.disable.confirm")
            : t("queue.management.enable.confirm")
        }
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />

      <ConfirmMessage
        message={t("queue.management.delete.confirm-msg")}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
    </>
  );
}
