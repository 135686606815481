import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { toast } from "react-toastify";
import {
  deleteDivision,
  enableOrDisableDivision,
} from "../../../../../redux/actions/services";
import {
  formatDateByLanguage,
  isFleetAdministrator,
} from "util";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomSwitch from "../../../../Services/components/CustomSwitch";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import CustomTableRow from "../../../../../components/TableComponents/CustomTableRow";
import StatusBadge from "../../../../../components/StatusBadge";
import AvatarTableCell from "../../../../../components/TableComponents/AvatarTableCell";
import { isPermitted } from "../../../../../components/HasPermission";
import useStyles from "../../../../../components/TableComponents/style";

const headerCells = {
  fields: [
    {
      id: "status",
      label: "",
    },
    {
      id: "division",
      label: "",
    },
    {
      id: "fleet",
      label: "divisions.fleet",
    },
    {
      id: "creationDate",
      label: "divisions.creation.date",
    },
  ],
};
const displayFields = [
  {
    id: "status",
    verticalAlign: "middle",
    valuePath: "status",
    customContent: ({ valuePath }) => <StatusBadge level={valuePath} />,
  },
  {
    id: "divisionName",
    verticalAlign: "middle",
    valuePath: "divisionName",
    avatarPath: "logo",
    customContent: ({
                      indexRow, valuePath, indexField, valueAvatarPath
                    }) => (
                      <AvatarTableCell
                          indexRow={indexRow}
                          valPath={valuePath}
                          valAvatarPath={valueAvatarPath}
                          indexField={indexField}
                      />
    ),
  },
  {
    id: "fleet",
    verticalAlign: "middle",
    valuePath: "fleet.label",
  },
  {
    id: "creationDate",
    verticalAlign: "middle",
    valuePath: "creationDate",
    format: (v) => formatDateByLanguage(v, false),
  },
];

export default function DivisionTableRow({
  row,
  selected,
  setSelected,
  entityBaseUrl,
  fetchEntities,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getFieldData = (valuePath) => get(row, valuePath);
  const active = getFieldData("status") === "ACTIVE";
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState({
    switch: false,
    deleteAction: false,
  });

  const resetLoaders = () => setIsLoading({ switch: false, deleteAction: false });

  const handleChangeStatus = () => {
    const id = getFieldData("id");
    setIsLoading({ ...isLoading, switch: true });
    dispatch(enableOrDisableDivision(id, active ? "DISABLE" : "ACTIVE")).then(
      (err) => {
        resetLoaders();
        if (err?.response?.data?.detail) {
          toast.error(t(err?.response?.data?.detail));
        } else {
          fetchEntities();
        }
        setOpenConfirmationPopup(false);
      },
    );
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    onDeleteEntity();
  };

  const onDeleteEntity = () => {
    setIsLoading({ ...isLoading, deleteAction: true });
    dispatch(deleteDivision(toDeleteId)).then((res) => {
      if (res?.status === 200) {
        toast.success(t("division deleted successfully"));
        setTimeout(() => fetchEntitiesReset(), 1500);
      } else if (res?.response?.data?.detail) toast.error(t(res?.response?.data?.detail));
      else toast.error(t("Unexpected Error"));
      setOpenMsgConfirm(false);
      resetLoaders();
    });
  };

  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const isCurrentUser = Number(currentUser?.id) === row?.id;

  const deleteDivisionAlert = (
    <Box>
      <Typography>{t("divisions.module.delete.confirmMsg")}</Typography>
    </Box>
  );

  return (
    <>
      <CustomTableRow
        onClick={() => history.push(`${entityBaseUrl}/view/${getFieldData("id")}`)}
      >
        {displayFields.map((field, i) => (field?.customContent ? (
          <CustomTableCell verticalAlign="middle">
            {field.customContent({
                valuePath: getFieldData(field?.valuePath),
                indexRow: getFieldData("id"),
                indexField: get(field, "id"),
                valueAvatarPath: getFieldData(field?.avatarPath),
              })}
          </CustomTableCell>
          ) : (
            <CustomTableCell
              key={i}
              title={t(headerCells?.fields?.[i]?.label)}
              text={
                field.format
                  ? field.format(getFieldData(field.valuePath))
                  : getFieldData(field?.valuePath)
              }
              verticalAlign={field?.verticalAlign}
              width={field?.width}
            />
          )),)}
        <CustomTableCell verticalAlign="middle">
          <Grid container xs={12} justify="flex-end" alignItems="center">
            { (isPermitted(currentUser, "Edit division") || isFleetAdministrator(currentUser)) && (
              <Grid item>
                <Tooltip
                  placement="top"
                  title={t(`tooltip.action.${active ? "disable" : "enable"}`)}
                >
                  <CustomSwitch
                    id="admin-enable-disable-switch"
                    checked={active}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    handleChange={() => {
                      setOpenConfirmationPopup(true);
                    }}
                    disabled={isCurrentUser || getFieldData("isInstanceOwner")}
                    value={active}
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    size="small"
                    labelClassName={classes.switchLabel}
                  />
                </Tooltip>
              </Grid>
            )}
            {isPermitted(currentUser, "Edit division") && (
              <Grid item>
                <Tooltip placement="top" title={t("tooltip.action.edit")}>
                  <IconButton
                    id="admin-edit-btn"
                    aria-label="edit"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`${entityBaseUrl}/edit/${getFieldData("id")}`);
                    }}
                    size="small"
                  >
                    <EditIcon className={classes.rowActionButtons} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {isPermitted(currentUser, "Delete division") && (
              <Grid item>
                <Tooltip placement="top" title="tooltip.action.delete">
                  <IconButton
                    id="fleet-admin-delete-btn"
                    disabled={isCurrentUser}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(getFieldData("id"));
                      setOpenMsgConfirm(true);
                    }}
                    size="small"
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            { (isPermitted(currentUser, "Delete division") || isFleetAdministrator(currentUser)) && (
              <Grid item>
                <Checkbox
                  id="fleet-admin-select-checkbox"
                  className={classes.rowActionButtons}
                  checked={
                    !isCurrentUser && selected.includes(parseInt(row.id, 10))
                  }
                  disabled={isCurrentUser}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const list = [...selected];
                    e.target.checked
                      ? list.push(parseInt(getFieldData("id"), 10))
                      : list.splice(
                          list.indexOf(parseInt(getFieldData("id"), 10)),
                          1,
                        );
                    setSelected(list);
                  }}
                  size="small"
                />
              </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          active
            ? t("divisions.module.desaDes.confirmMsg")
            : t("divisions.module.enaDes.confirmMsg")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={
          active
            ? t("divisions.module.desaDes.button.confirm")
            : t("divisions.module.enaDes.button.confirm")
        }
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />
      <ConfirmMessage
        message={deleteDivisionAlert}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
    </>
  );
}
