import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useTranslation } from "react-i18next";
import { formatImagePath } from "util/index";
import useStyles from "../style";

function ProcessesNameCard({ processes }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.containerTitle}>
          {t("processes")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {
                  processes?.length > 0 ? (
                      processes.map((process) => (
                        <Box
                              component={Grid}
                              mb="10px !important"
                              container
                              item
                              directiom="row"
                              alignItems="center"
                              justify="center"
                          >
                          <Grid item xs={3}>
                            {process?.icon ? (
                              <img
                                          src={formatImagePath(process.icon)}
                                          alt="Service"
                                          className={classes.image}
                                      />
                                  ) : (
                                    <ProcessDefaultIcon className={classes.image} />
                                  )}
                          </Grid>
                          <Grid item xs={9}>
                            <Tooltip title={process?.processDescription?.processDescription} placement="right">
                              <ButtonBase>
                                <Typography variant="subtitle1" className={classes.content}>
                                  {process?.processDescription?.processDescription}
                                </Typography>
                              </ButtonBase>
                            </Tooltip>
                          </Grid>
                        </Box>
                      ))
                  ) : (
                    <Alert severity="warning" className={classes.alert}>
                      <Typography variant="subtitle1">
                        {t("administration.orchestrator.process.list.not.available")}
                      </Typography>
                    </Alert>
                  )
              }
      </Grid>
    </Grid>
  )
}

export default ProcessesNameCard;
