import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useTheme from "@material-ui/core/styles/useTheme";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CheckOutlined as AcceptIcon,
  CloseOutlined as RejectIcon,
  ChevronRightOutlined as ExitIcon,
  Send as SubmitIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Replay,
  Archive,
  AssignmentOutlined,
} from "@material-ui/icons";
import { CriteriaTypes } from "util/constants";
import { round, truncateString } from "util";
import { OPPORTUNITY_ASSESSMENT_BASE_URL } from "util/helpers/route.helper";
import ViewProcessTabs from "./ViewProcessTabs";
import CircularLoader from "../../../components/Loaders/CircularLoader";

import useStyle from "./style";
import ConfirmMessage from "../../../components/ConfirmMessage";
import { HasPermission } from "../../../components/HasPermission";
import PdfModal from "./pdfModal.js";
import { toast } from "react-toastify";
import { useQuery } from "@redux-requests/react";
import { LOAD_PROCESS } from "redux/constants";
import { getProcess } from "redux/slices/viewProcessSlice";
import camelCase from "lodash/camelCase";
import {
  archiveProcess, loadProcess, qualifyProcess, deleteProcess
} from "redux/actions/services/opportunities";
import CustomButton from "../../../components/CustomButton";

const NewProcessPage = () => {
  const classes = useStyle();

  const dispatch = useDispatch();

  const history = useHistory();

  const theme = useTheme();

  const { id } = useParams();

  const { data = [], loading: isProcessLoading } = useQuery({ type: LOAD_PROCESS })
  const process = data || []

  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [msgConfirm, setMsgConfirm] = useState("");
  const [buttonConfirm, setButtonConfirm] = useState("");
  const [exportDataToPdf, setExportDataToPdf] = useState({});

  useEffect(() => {
    dispatch(loadProcess(id)).then(() => {
      dispatch(getProcess({ process: { ...process } }))
    })
  }, [dispatch, id, history]);

  const isMobileXs = useMediaQuery(theme.breakpoints.down("xs"));

  const onSuccessQualifyProcess = () => {
    history.push("/")
    history.replace(`${OPPORTUNITY_ASSESSMENT_BASE_URL}/rpa/view/${process.id}`);
    setOpenMsgConfirm(false)
    setIsLoading(false)
    succesSubmit()
  }

  const onErrorQualifyProcess = () => {
    toast.error(t("Something.Wrong"))
    setOpenMsgConfirm(false)
    setIsLoading(false)
  }

  const handleQualifyProcess = (process, status) => {
    // Clone process object
    const processClone = { ...process };

    // status = accepted | rejected
    processClone.status = status;
    //  processClone.qualifiedBy = { id: 1 };
    dispatch(qualifyProcess(processClone, onSuccessQualifyProcess, onErrorQualifyProcess))
  };

  const succesSubmit = () => {
    switch (status) {
      case "deleted":
        toast.success(t("Case deleted successfully"))
        break;
      case "submitted":
        toast.success(t("Case saved and submitted for approval"))
        break;
      case "accepted":
        toast.success(t("Case accepted as an opportunity"))
        break;
      case "rejected":
        toast.success(t("Case rejected "))
        break;
      case "archived":
        toast.success(t("rpaQual.process.archived.success"))
        break;
      case "roadMap":
        toast.success(t("rpaQual.process.roadMap.success"))
        break;
      case "reconsider":
        toast.success(t("rpaQual.process.reconsider.success"))
        break;
      default:
        break;
    }
  };

  const handleSubmitProcess = (process) => {
    // Clone process object
    const processClone = { ...process };

    // Set process status to 'submitted'
    processClone.status = "submitted";
    handleQualifyProcess(processClone, processClone.status);
  };
  const { t } = useTranslation();

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  const handleReconsiderProcess = (process) => {
    // Clone process object
    const processClone = { ...process };

    // Set process status to 'submitted'
    processClone.status = "submitted";
    handleQualifyProcess(processClone, processClone.status);
  };

  const handleAddToRoadMap = (process) => {
    // Clone process object
    const processClone = { ...process };

    // Set process status to 'submitted'
    processClone.status = "roadMap";
    handleQualifyProcess(processClone, processClone.status);
  };

  const onSuccessDeleteAndArchive = () => {
    history.replace(OPPORTUNITY_ASSESSMENT_BASE_URL);
    succesSubmit()
    setIsLoading(false)
    setOpenMsgConfirm(false)
  }

  const handleDeleteProcess = (process) => {
    dispatch(deleteProcess(process, onSuccessDeleteAndArchive, onErrorQualifyProcess))
  }

  const handleArchiveProcess = (process) => {
    dispatch(archiveProcess(process, onSuccessDeleteAndArchive, onErrorQualifyProcess))
  }

  const confirmChange = () => {
    setIsLoading(true);
    if (status === "accepted" || status === "rejected") handleQualifyProcess(process, status)

    else if (status === "deleted") handleDeleteProcess(process)

    else if (status === "submitted") handleSubmitProcess(process)

    else if (status === "reconsider") handleReconsiderProcess(process)

    else if (status === "archived") handleArchiveProcess(process)

    else if (status === "roadMap") handleAddToRoadMap(process)

    else onConfirm()
  };
  const changeData = (status) => {
    let msgConfirm = "";
    let buttonConfirm;
    switch (status) {
      case "deleted":
        buttonConfirm = t("Delete");
        if (process.status === "submitted") {
          msgConfirm = t(
            "This case is submitted and awaiting approval. Are you sure you want to delete it ?",
          );
        } else {
          msgConfirm = t(
            "This case is not yet submitted. Are you sure you want to delete it ?",
          );
        }
        break;
      case "submitted":
        buttonConfirm = t("Submit");
        msgConfirm = t("Are you sure you want to save and submit this case ?");
        break;
      case "accepted":
        buttonConfirm = t("Accept");
        msgConfirm = t(
          "Are you sure you want to accept this case as an opportunity for automation ?",
        );
        break;
      case "rejected":
        buttonConfirm = t("Reject");
        msgConfirm = t("Are you sure you want to reject this case ?");
        break;
      case "delete":
        buttonConfirm = t("Reject");
        msgConfirm = t("Are you sure you want to reject this case ?");
        break;
      case "reconsider":
        buttonConfirm = t("rpaQual.transition.reconsider");
        msgConfirm = t("rpaQual.transition.reconsiderMsg");
        break;
      case "archived":
        buttonConfirm = t("rpaQual.transition.archive");
        msgConfirm = t("rpaQual.transition.archiveMsg");
        break;
      case "roadMap":
        buttonConfirm = t("Submit");
        msgConfirm = t("rpaQual.transition.addToRoadMapMsg");
        break;

      default:
        break;
    }
    setButtonConfirm(buttonConfirm);
    setStatus(status);
    setOpenMsgConfirm(true);
    setMsgConfirm(msgConfirm);
    setIsLoading(false);
  };
  const onConfirm = () => {
    setOpenMsgConfirm(false);
    history.push("/");
    history.replace(OPPORTUNITY_ASSESSMENT_BASE_URL);
  };

  const openMsgDialog = () => {
    setMsgConfirm(
      t("Your progress will be lost, are you sure you want to exit ?"),
    );
    setButtonConfirm(t("Exit"));
    setOpenMsgConfirm(true);
    setStatus("exited");
  };

  const {
    stepsLoaded,
    steps,
    process: processStore,
  } = useSelector((state) => state.viewProcess);

  const handleExport = () => {
    const exportDataToPdf = {
      steps: [],
      result: {}
    };
    stepsLoaded
    && !isProcessLoading
    && steps.forEach((step) => {
      const criteria = step.criterias
                  && step.criterias
                    .slice()
                    .sort((a, b) => a.id - b.id)
                    .map((criteria) => ({
                      name: criteria.name,
                      // eslint-disable-next-line no-nested-ternary
                      type: criteria?.assessments.length > 0
                              && criteria.type === CriteriaTypes.String ? (
                          `assessment.${
                            processStore.qualifications.find(
                              (q) => q.criteria.id === criteria.id,
                            )?.assessment?.name
                          }`
                        // eslint-disable-next-line no-nested-ternary
                        ) : criteria.type === CriteriaTypes.Boolean ? (
                          processStore.qualifications.find(
                            (q) => q.criteria.id === criteria.id,
                          )?.assessment.name === "true"
                            ? "Yes"
                            : "No"
                        ) : (
                          processStore.qualifications.find(
                            (q) => q.criteria.id === criteria.id,
                          )?.value
                        ),
                      comment: processStore.qualifications.find(
                        (q) => q.criteria.id === criteria.id,
                      )?.comment,
                    }));

      exportDataToPdf.steps.push({ name: step.name, criteria });
    });

    const result = {
      name: "Result",
      values: {
        graph: {
          x: round(processStore.feasibility, 1),
          y: round(processStore.impact, 1),
        },
        fteCosts: round(processStore.fteCosts, 2),
        fteGains: round(processStore.fteGains, 2),
        developerCosts: round(processStore.developerCosts, 2),
        baCosts: round(processStore.baCosts, 2),
        runningCosts: round(processStore.runningCosts, 2),
        feasibility: processStore.feasibility,
        totalSaving: round(processStore.fteCosts * processStore.fteGains, 2),
        totalDevelopementCosts: round(processStore.developerCosts + processStore.baCosts, 2),
        // eslint-disable-next-line no-nested-ternary
        payback: round(processStore.payback || processStore.roi, 2) >= 5
          ? `>= 5 ${t("processStore.years")}`
          // eslint-disable-next-line no-nested-ternary
          : round(processStore.payback || processStore.roi, 2) < 5
                    && round(processStore.payback || processStore.roi, 2) >= 3
            ? `< 5 ${t("processStore.years")}`
            // eslint-disable-next-line no-nested-ternary
            : round(processStore.payback || processStore.roi, 2) < 3
                    && round(processStore.payback || processStore.roi, 2) >= 1
              ? `< 3 ${t("processStore.years")}`
              : round(processStore.payback || processStore.roi, 2) < 1
                    && round(processStore.payback || processStore.roi, 2) >= 0
                ? `< 1 ${t("processStore.year")}`
                : t("Unremarkable"),
      },
    };

    exportDataToPdf.result = result;
    setExportDataToPdf(exportDataToPdf);
  };

  return (
    <Grid item container>
      {isProcessLoading && <CircularLoader />}
      {!isProcessLoading && (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-start">
            <Typography
              variant="h5"
              className={classes.pageHeader}
              color="textSecondary"
            >
              {truncateString(t(process.name), 100)}
            </Typography>
          </Box>
        </Grid>
        <Grid
          className={classes.actionsContainer}
          container
          item
          xs={12}
          justify="space-between"
          alignItems="flex-start"
          direction={isMobileXs ? "column" : "row"}
        >
          <Grid container item xs={4}>
            <Box
              marginBottom={isMobileXs ? "1rem" : 0}
              bgcolor={
                theme.custom.palette.status[camelCase(process.status)] || theme.custom.palette.status.pending
              }
              className={clsx(classes.status)}
            >
              <Typography variant="body1">
                {/* eslint-disable-next-line no-nested-ternary */}
                {process.status === "submitted"
                  ? t("PENDING")
                  : process.status === "not submitted"
                    ? t("NOT VALIDATED")
                    : t(process.status).toUpperCase()}
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={8} direction="row" justify="flex-end">
            {process?.isArchived && (
              <Typography
                className={classes.marginRight}
                style={{ fontStyle: "italic" }}
                color="secondary"
                variant="h6"
              >
                {t("rpaQual.display.archived")}
              </Typography>
            )}
            <PdfModal exportPdf={handleExport} pdfData={exportDataToPdf} />

            {!process?.isArchived && (
            <>
              {process.status === "submitted" && (
              <>
                <HasPermission name="Accept / Reject / Reconsider">
                  <CustomButton
                    view="primary"
                    id="accept-rpa-qual-opportunity-btn"
                    fullWidth={isMobileXs}
                    onClick={changeData.bind(this, "accepted")}
                    className={clsx(
                      classes.marginRight,
                      classes.acceptBtn,
                      isMobileXs && classes.marginBottom,
                    )}
                    endIcon={<AcceptIcon />}
                  >
                    {t("Accept")}
                  </CustomButton>
                </HasPermission>
                <HasPermission name="Accept / Reject / Reconsider">
                  <CustomButton
                    view="primary"
                    id="reject-rpa-qual-opportunity-btn"
                    fullWidth={isMobileXs}
                    onClick={changeData.bind(this, "rejected")}
                    className={clsx(
                      classes.marginRight,
                      classes.rejecttBtn,
                      isMobileXs && classes.marginBottom,
                    )}
                    endIcon={<RejectIcon />}
                  >
                    {t("Reject")}
                  </CustomButton>
                </HasPermission>
                <HasPermission name="Delete opportunity">
                  <CustomButton
                    id="delete-rpa-qual-opportunity-btn"
                    fullWidth={isMobileXs}
                    onClick={changeData.bind(this, "deleted")}
                    className={clsx(
                      classes.marginRight,
                      classes.deleteOutlinedBtn,
                      isMobileXs && classes.marginBottom,
                    )}
                    color="primary"
                    variant="outlined"
                    endIcon={<DeleteIcon />}
                  >
                    {t("rpaQual.transition.delete")}
                  </CustomButton>
                </HasPermission>
              </>
              )}
              {process.status === "not submitted" && (
              <>
                <CustomButton
                  view="primary"
                  id="submit-rpa-qual-opportunity-btn"
                  fullWidth={isMobileXs}
                  onClick={changeData.bind(this, "submitted")}
                  className={clsx(
                    classes.marginRight,
                    isMobileXs && classes.marginBottom,
                  )}
                  endIcon={<SubmitIcon />}
                >
                  {t("Validate")}
                </CustomButton>
                <CustomButton
                  id="edit-rpa-qual-opportunity-btn"
                  fullWidth={isMobileXs}
                  onClick={() => history.push(
                    `${OPPORTUNITY_ASSESSMENT_BASE_URL}/rpa/edit/${process.id}`,
                  )}
                  className={clsx(
                    classes.marginRight,
                    isMobileXs && classes.marginBottom,
                  )}
                  color="secondary"
                  variant="outlined"
                  endIcon={<EditIcon />}
                >
                  {t("Edit")}
                </CustomButton>
                <HasPermission name="Delete opportunity">
                  <CustomButton
                    id="delete-rpa-qual-opportunity-btn"
                    fullWidth={isMobileXs}
                    onClick={changeData.bind(this, "deleted")}
                    className={clsx(
                      classes.marginRight,
                      classes.deleteBtn,
                      isMobileXs && classes.marginBottom,
                    )}
                    color="primary"
                    variant="outlined"
                    endIcon={<DeleteIcon />}
                  >
                    {t("rpaQual.transition.delete")}
                  </CustomButton>
                </HasPermission>
              </>
              )}

              {process.status === "rejected" && (
              <>
                <HasPermission name="Archive / Backlog">
                  <CustomButton
                    view="primary"
                    id="archive-rpa-qual-opportunity-btn"
                    fullWidth={isMobileXs}
                    onClick={changeData.bind(this, "archived")}
                    className={clsx(
                      classes.marginRight,
                      isMobileXs && classes.marginBottom,
                    )}
                    endIcon={<Archive />}
                  >
                    {t("rpaQual.transition.archive")}
                  </CustomButton>
                </HasPermission>
                <HasPermission name="Accept / Reject / Reconsider">
                  <CustomButton
                    id="reconsider-rpa-qual-opportunity-btn"
                    fullWidth={isMobileXs}
                    onClick={changeData.bind(this, "reconsider")}
                    className={clsx(
                      classes.marginRight,
                      isMobileXs && classes.marginBottom,
                    )}
                    color="secondary"
                    variant="outlined"
                    endIcon={<Replay />}
                  >
                    {t("rpaQual.transition.reconsider")}
                  </CustomButton>
                </HasPermission>
                <HasPermission name="Delete opportunity">
                  <CustomButton
                    view="primary"
                    id="delete-rpa-qual-opportunity-btn"
                    fullWidth={isMobileXs}
                    onClick={changeData.bind(this, "deleted")}
                    className={clsx(
                      classes.marginRight,
                      classes.deleteBtn,
                      isMobileXs && classes.marginBottom,
                    )}
                    endIcon={<DeleteIcon />}
                  >
                    {t("rpaQual.transition.delete")}
                  </CustomButton>
                </HasPermission>
              </>
              )}

              {process.status === "accepted" && (
              <>
                <HasPermission name="Accept / Reject / Reconsider">
                  <CustomButton
                    id="reconsider-rpa-qual-opportunity-btn"
                    fullWidth={isMobileXs}
                    onClick={changeData.bind(this, "reconsider")}
                    className={clsx(
                      classes.marginRight,
                      isMobileXs && classes.marginBottom,
                    )}
                    color="secondary"
                    variant="outlined"
                    endIcon={<Replay />}
                  >
                    {t("rpaQual.transition.reconsider")}
                  </CustomButton>
                </HasPermission>
                <HasPermission name="Archive / Backlog">
                  <CustomButton
                    view="primary"
                    id="roadmap-rpa-qual-opportunity-btn"
                    fullWidth={isMobileXs}
                    onClick={changeData.bind(this, "roadMap")}
                    className={clsx(
                      classes.marginRight,
                      isMobileXs && classes.marginBottom,
                    )}
                    endIcon={<AssignmentOutlined />}
                  >
                    {t("rpaQual.transition.addToRoadMap")}
                  </CustomButton>
                </HasPermission>
                <HasPermission name="Delete opportunity">
                  <CustomButton
                    view="primary"
                    id="delete-rpa-qual-opportunity-btn"
                    fullWidth={isMobileXs}
                    onClick={changeData.bind(this, "deleted")}
                    className={clsx(
                      classes.marginRight,
                      classes.deleteBtn,
                      isMobileXs && classes.marginBottom,
                    )}
                    endIcon={<DeleteIcon />}
                  >
                    {t("rpaQual.transition.delete")}
                  </CustomButton>
                </HasPermission>
              </>
              )}

              {process.status === "roadMap" && (
              <HasPermission name="Archive / Backlog">
                <CustomButton
                  view="primary"
                  id="archive-rpa-qual-opportunity-btn"
                  fullWidth={isMobileXs}
                  onClick={changeData.bind(this, "archived")}
                  className={clsx(
                    classes.marginRight,
                    isMobileXs && classes.marginBottom,
                  )}
                  endIcon={<Archive />}
                >
                  {t("rpaQual.transition.archive")}
                </CustomButton>
              </HasPermission>
              )}
            </>
            )}
            <CustomButton
              id="exit-rpa-qual-opportunity-btn"
              fullWidth={isMobileXs}
              className={clsx(isMobileXs && classes.marginBottom)}
              onClick={openMsgDialog}
              color="secondary"
              variant="outlined"
              endIcon={<ExitIcon />}
            >
              {t("Exit")}
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
      )}
      {!isProcessLoading && (
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.cardContent}>
            <ViewProcessTabs loadProcess={loadProcess} />
          </CardContent>
        </Card>
      </Grid>
      )}
      {openMsgConfirm && (
      <ConfirmMessage
        message={msgConfirm}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmChange}
        buttonConfirm={buttonConfirm}
        buttonCancel={t("Cancel")}
        isLoading={isLoading}
      />
      )}
    </Grid>
  );
};

export default NewProcessPage;
