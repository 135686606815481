import { fade, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    customButton: {
        transition: "0.3s",
        color: theme.custom.color.button.textDefault,
        fontWeight: 700,
        fontSize: "0.8rem",
        paddingInline: "20px",
        padding: "5px 13px",
        "&:hover": {
            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.07), 0px 1px 10px 0px rgba(0,0,0,0.06)"
        }
    },
    button: {
        fontWeight: 700,
        fontSize: "0.8rem"
    },
    primary: {
        backgroundColor: theme.custom.color.button.primary,
        "&:hover": {
            backgroundColor: theme.custom.color.button.primaryHover,
        }
    },
    secondary: {
        backgroundColor: theme.custom.color.button.secondary,
        "&:hover": {
            backgroundColor: theme.custom.color.button.secondaryHover,
        }
    },
    tertiary: {
        backgroundColor: theme.custom.color.button.tertiary,
        "&:hover": {
            backgroundColor: theme.custom.color.button.tertiaryHover,
        }
    },
    cancel: {
        backgroundColor: theme.custom.color.button.cancel,
        color: theme.custom.color.button.textDark,
        "&:hover": {
            backgroundColor: theme.custom.color.button.cancelHover,
        }
    },
    disabled: {
        backgroundColor: theme.custom.color.button.disabled,
        color: theme.custom.color.button.disabledText,
    },
    cancelModal: {
        backgroundColor: theme.custom.color.button.cancelModal,
        color: theme.custom.color.button.textDark,
        "&:hover": {
            backgroundColor: theme.custom.color.button.cancelModalHover,
        },
        fontWeight: 700
    },
    cancelOutlined: {
        backgroundColor: theme.custom.color.button.cancelModal,
        color: theme.custom.color.button.textDark,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: "0.3125rem",
        fontWeight: 700,
        "&:hover": {
            backgroundColor: theme.custom.color.button.cancelModalHover,
        }
    },
    outlinedPrimary: {
        backgroundColor: theme.custom.color.button.cancelModal,
        color: theme.custom.color.button.textDark,
        border: `1px solid ${theme.custom.color.button.textDark}`,
        "&:hover": {
            backgroundColor: theme.custom.color.button.cancelModalHover,
        },
        fontWeight: 700,
        boxShadow: theme.shadows[23]
    },
    outlinedSecondary: {
        backgroundColor: theme.custom.color.button.cancelModal,
        color: theme.custom.color.button.tertiary,
        border: `1px solid ${theme.custom.color.button.tertiary}`,
        "&:hover": {
            backgroundColor: theme.custom.color.button.cancelModalHover,
        },
        fontWeight: 700,
        boxShadow: theme.shadows[23]
    },
    secondaryText: {
        color: theme.custom.color.button.textLight,
        "&:hover": {
            backgroundColor: theme.custom.color.button.transparentBg,
            boxShadow: "none",
        },
        padding: 0
    },
    secondaryVariant: {
        backgroundColor: theme.custom.color.button.textLight,
        "&:hover": {
            backgroundColor: fade(theme.custom.color.button.textLight, 0.8),
            boxShadow: "none",
        },
        padding: 0
    },
    iconBtn: {
        padding: theme.spacing(1),
        minWidth: 0,
        height: 35,
        width: 35
    }
}));
