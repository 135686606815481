import frLocale from "date-fns/locale/fr";
import esLocale from "date-fns/locale/es";
import arLocale from "date-fns/locale/ar-SA";
import deLocale from "date-fns/locale/de";
import plLocale from "date-fns/locale/pl";
import enUSLocale from "date-fns/locale/en-US";
import { exceptionCurrentModuleRoutes } from "util/helpers/exception.helper";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const arrayIsNullOrEmpty = (arr) => !(Array.isArray(arr) && arr.length);

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const getLocale = () => {
    const ln = localStorage.getItem("lgn");
    const localeMap = {
        fr: frLocale,
        es: esLocale,
        ar: arLocale,
        de: deLocale,
        pl: plLocale,
    };
    return localeMap[ln] || enUSLocale;
};

export const getLocales = () => ({
    fr: frLocale,
    es: esLocale,
    ar: arLocale,
    de: deLocale,
    pl: plLocale,
})

export const getApiDomain = () => process.env.REACT_APP_DOMAIN;
export const defaultBePeriods = 30;
export const fetchAllProcesses = true;
export const isEnvParamEnabled = (param) => process.env?.[param] === "true";
export const reorderMenu = (menus) => menus.reduce((acc, menu) => {
    const { group, ...rest } = menu;
    const existingGroup = acc.find((item) => item.group === group);

    if (existingGroup) {
        existingGroup.menus.push(rest);
    } else {
        acc.push({ group, menus: [rest] });
    }

    return acc;
}, [])

export const findIndexByKey = (array, value, key = "id") => array.findIndex((menu) => menu?.[key] === value);

export const itemsPathLabel = "items"
export const exceptionsPathLabel = "exceptions"
export const alertsPathLabel = "alerts"
export const headerSubMenus = {
    [itemsPathLabel]: {
        items: [
            {
                path: `/${itemsPathLabel}/completed`,
                label: "custom.kpi.completed",
            },
            {
                path: `/${itemsPathLabel}/pending`,
                label: "items.pending",
            }
        ]
    },
    [exceptionsPathLabel]: {
        items: [
            {
                path: `/${exceptionsPathLabel}/exception`,
                label: "exceptions.item.tab.title",
            },
            {
                path: `/${exceptionsPathLabel}/${exceptionCurrentModuleRoutes.PROCESSES.route}`,
                label: "exceptions.processes.tab.title",
            },
        ]
    },
    [alertsPathLabel]: {
        items: [
            {
                path: "/alerts/defaultAlerts",
                label: "Default Alerts",
            },
            {
                path: "/alerts/customAlerts",
                label: "Custom Alerts",
            },
        ]
    },
    profile: {
        items: [
            {
                path: "/profile",
                label: "Profile",
            }
        ]
    },

}
