import { formatDateTimeIsoParam } from "../../../util";
import {
  FETCH_AUDIT_ACTIONS,
  FETCH_AUDIT_LOGS, FETCH_AUDIT_MODULES,
  FETCH_AUDIT_USERS
} from "../../constants";
import {
  URL_AUDIT_ACTIONS,
  URL_AUDIT_LOGS,
  URL_AUDIT_USERS,
  URL_AUDIT_MODULES
} from "../../constants/endpoints";

const fetchAuditLogs = (filter = {}, onSuccess, onError) => {
  const {
    searchQuery,
    pageNo,
    pageSize,
    sortBy,
    actions,
    objects,
    users,
    modules,
    period: {
      from,
      to,
    }
  } = filter;
  return {
    type: FETCH_AUDIT_LOGS,
    request: {
      url: URL_AUDIT_LOGS,
      params: {
        searchQuery,
        page: pageNo,
        size: pageSize,
        sort: sortBy.map(({ name, dir }) => `${name},${dir}`).join(),
        actions: actions.join(),
        objects: objects.join(),
        users: users.join(),
        modules: modules.join(),
        from: formatDateTimeIsoParam(from),
        to: formatDateTimeIsoParam(to),
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
      onError: (error) => {
        if (onError) onError(error);
        throw error;
      },
    },
  };
};

const fetchAuditActions = (filter = {}, onSuccess, onError) => {
  const {
    searchQuery,
    modules,
    users,
    period: {
      from,
      to,
    }
  } = filter;
  return {
    type: FETCH_AUDIT_ACTIONS,
    request: {
      url: URL_AUDIT_ACTIONS,
      params: {
        searchQuery,
        users: users.join(),
        modules: modules.join(),
        from: formatDateTimeIsoParam(from),
        to: formatDateTimeIsoParam(to),
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
      onError: (error) => {
        if (onError) onError(error);
        throw error;
      },
    },
  };
};

const fetchAuditUsers = (filter = {}, onSuccess, onError) => {
  const {
    searchQuery,
    modules,
    actions,
    period: {
      from,
      to,
    }
  } = filter;
  return {
    type: FETCH_AUDIT_USERS,
    request: {
      url: URL_AUDIT_USERS,
      params: {
        searchQuery,
        modules: modules.join(),
        actions: actions.join(),
        from: formatDateTimeIsoParam(from),
        to: formatDateTimeIsoParam(to),
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
      onError: (error) => {
        if (onError) onError(error);
        throw error;
      },
    },
  };
};
const fetchAuditModules = (filter = {}, onSuccess, onError) => {
  const {
    searchQuery,
    users,
    actions,
    period: {
      from,
      to,
    }
  } = filter;
  return {
    type: FETCH_AUDIT_MODULES,
    request: {
      url: URL_AUDIT_MODULES,
      params: {
        searchQuery,
        users: users.join(),
        actions: actions.join(),
        from: formatDateTimeIsoParam(from),
        to: formatDateTimeIsoParam(to),
      },
    },
    meta: {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
        return response;
      },
      onError: (error) => {
        if (onError) onError(error);
        throw error;
      },
    },
  };
};

const AuditService = {
  fetchAuditLogs,
  fetchAuditUsers,
  fetchAuditActions,
  fetchAuditModules,
}

export default AuditService;
