import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  dialogContent: {
    width: "100%"
  },
  nameCell: {
    width: "20%",
    padding: theme.spacing(1),
  },
  oldValueCell: {
    width: "35%",
    padding: theme.spacing(1)
  },
  newValueCell: {
    width: "45%",
    padding: theme.spacing(1)
  },
  fieldContainer: {
    "&:not(:last-child) > .MuiTableCell-root": {
      marginBottom: "8px",
    },
    backgroundColor: theme.palette.hoverColor,
  },
  dialogDefaultText: {
    fontSize: "1rem",
    fontWeight: "400",
    color: theme.palette.primary.main,
  },
  radio: {
    color: theme.palette.primary.main,
    "&.Mui-checked": {
      "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
        {
            color: theme.palette.primary.main,
        },
      "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
        color: theme.custom.color.color6,
      },
    },
  },
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    fontWeight: "600",
  },
  tableHeader: {
    backgroundColor: theme.palette.background.default,
  },
  tableTitle: {
    color: theme.palette.primary.main,
    fontSize: "0.9rem",
    fontWeight: "700",
  },
  table: {
    marginTop: "8px",
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
    borderCollapse: "separate",
    borderSpacing: "0px 8px",
  },
  notRetryableAlert: {
    fontSize: "0.9rem",
    fontWeight: "600",
  },
  toastContainer: {
    background: theme.custom.color.color6,
  },
  scrollTable: {
    maxHeight: "600px",
    overflowY: "scroll"
  },
}));
