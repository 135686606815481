import React, { useEffect, useState } from "react";
import SelectTextField from "../../../../../components/ReactHookField/SelectTextField";
import TextFieldForm from "../../../../../components/ReactHookField/TextFieldForm";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchFleetsForFilter } from "../../../../../redux/actions/services";

export default function DivisionInfo({ getFormField, isViewMode, isEdit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fleets, setFleets] = useState([]);

  useEffect(() => {
    dispatch(fetchFleetsForFilter()).then((res) => {
      setFleets(res.data);
    });
  }, []);

  return (
    <Grid item spacing={2} xs={10}>
      <SelectTextField
        fieldName="fleetId"
        fullWidth
        rules={{ required: t("ERRORS_EMPTY_FIELD") }}
        label={t("division.fleet")}
        options={fleets?.map(({ companyName, id }) => (
          <MenuItem value={id} key={id}>
            {companyName}
          </MenuItem>
        ))}
        readOnly={isViewMode || isEdit}
      />
      <TextFieldForm
        fieldName="divisionName"
        rules={{
          required: t("ERRORS_EMPTY_FIELD"),
        }}
        shrink={!!getFormField("divisionName")}
        label={t("division.name")}
        fullWidth
        readOnly={isViewMode}
      />
      <TextFieldForm
        fieldName="description"
        rules={{
          required: t("ERRORS_EMPTY_FIELD"),
        }}
        fullWidth
        label={t("division.description")}
        shrink={!!getFormField("description")}
        readOnly={isViewMode}
      />
    </Grid>
  );
}
