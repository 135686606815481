import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  tabs: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    "& .MuiTabs-indicator": {
      backgroundColor: theme.custom.color.color6,
      height: 6,
    },
  },
  tab: {
    border: `1px solid ${theme.custom.color.containersBorder}`,
  },
  noMargin: {
    margin: 0,
  },
  firstGrid: {
    width: "100%",
  },
  dialog: {
    "& .MuiDialog-paper": {
      width: "830px",
      // height:"730px",
      maxWidth: "unset",
    },
  },
  dialogContent: {
    padding: "0px !important",
  },
  dialogContainer: {
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "calc(100% - 20px)",
    },
  },
}));
