import theme from "../../style/orchestratorTheme";

export const defaultRecommendedOccupancy = 17;
export const tagStatus = {
    ACTIVE: "ACTIVE",
    DISABLED: "DISABLED"
}
export const displayStatus = {
    IDLE: "idle",
    WORKING: "working",
    OFFLINE: "offline",
};
export const getStatusBGColor = (status) => {
    const statusColors = {
        [displayStatus.IDLE]: theme.custom.color.blueStatus,
        [displayStatus.WORKING]: theme.custom.color.greenStatus,
    }
    return statusColors[status?.toLowerCase()] || theme.custom.color.color6;
};
export const getProcessAbbreviation = (name) => {
    if (!name) return "MP";

    const words = name.split(" ");

    const filteredWords = words.filter((word) => /^[A-Za-z]/.test(word));

    if (!filteredWords.length) return "MP";

    const firstLetter = filteredWords[0].charAt(0).toUpperCase();
    if (filteredWords.length === 1) return firstLetter;
    const lastLetter = filteredWords[filteredWords.length - 1].charAt(0).toUpperCase();

    return firstLetter + lastLetter;
};
export const getGraphLabels = (labels, t) => labels.map((label) => {
    let result = t(label);
    if (result === label) {
        let parts = result.split("hours ago");
        if (parts.length > 1) {
            result = t("hours ago").replace("#NB#", parts[0]);
        } else if (!result.includes("ago")) {
            parts = result.split(" ");
            if (parts.length > 1) {
                result = `${t(parts[0])} ${parts[1]}`;
            }
        }
    }
    return result;
});
export const currencyList = [
    { code: "EUR", label: "EUR", symbole: "€" },
    { code: "USD", label: "USD", symbole: "$" },
    { code: "PLN", label: "PLN", symbole: "zł" },
    { code: "MAD", label: "MAD", symbole: "MAD" },
    { code: "GBP", label: "GBP", symbole: "£" },
    { code: "JPY", label: "JPY", symbole: "¥" },
    { code: "CAD", label: "CAD", symbole: "C$" },
    { code: "SAR", label: "SAR", symbole: "SAR" },
    { code: "AED", label: "AED", symbole: "AED" },
    { code: "KD", label: "KD", symbole: "KWD" },
    { code: "EGP", label: "EGP", symbole: "EGP" },
    { code: "Rand", label: "Rand", symbole: "R" },
    { code: "FCFA", label: "FCFA", symbole: "CFA" },
];
export const DEFAULT_CURRENCY = { code: "EUR", label: "EUR" };
export const getCurrencySymbol = (currency) => {
    switch (currency) {
        case "EUR": return "€";
        case "USD": return "$";
        case "PLN": return "zł";
        case "GBP": return "£";
        case "JPY": return "¥";
        case "CAD": return "C$";
        case "SAR": return "SAR";
        case "AED": return "AED";
        case "KD": return "KWD";
        case "EGP": return "EGP";
        case "Rand": return "R";
        case "FCFA": return "CFA";
        default: return currency ?? "";
    }
};
export const kpiTypes = {
    FINANCIAL_KPI: {
      code: "FINANCIAL_KPI",
    },
    PRODUCTIVITY_KPI: {
      code: "PRODUCTIVITY_KPI",
    },
    EFFICIENCY_KPI: {
      code: "EFFICIENCY_KPI",
    }
}
export const maxItemDetailsCount = (data, maxAllowed = 3) => {
    let maxCount = 0
    if (!data) return maxCount
    data?.forEach(({ parsedData }) => {
        if (parsedData?.length > maxCount) maxCount = parsedData?.length
    })
    if (maxAllowed) return maxCount > maxAllowed ? maxAllowed : maxCount
    return maxCount
}
