import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setProcessesAction } from "../../../../redux/slices/executionManagerSlice";
import {
  ASK_DELETE_QUEUE,
  CONFIRM_DELETE_QUEUE,
  ASK_ENABLE_QUEUE,
  CONFIRM_ENABLE_QUEUE,
  ASK_DISABLE_QUEUE,
  CONFIRM_DISABLE_QUEUE,
  CANCEL_QUEUE_ACTIONS,
  ASK_DELETE_QUEUE_FROM_ONE,
} from "util/constants"
import CustomDialog from "components/CustomDialog";

export function ProcessAssignDialog({
  openProcessAssignDialog,
  setOpenProcessAssignDialog,
  hasSchedules,
  dialogMsg,
  dialogMsg2,
  assignedProcesses,
  setAssignedProcesses,
  availableProcesses,
  setAvailableProcesses,
  selectedProcessId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [processesActionQueue, setProcessesActionForQueue] = useState([]);
  const { queueAction } = useSelector(({ executionManager }) => executionManager);

  const redragProcessToOrigin = (processId) => {
    const processAssigned = assignedProcesses.find(
      ({ id }) => id === processId
    );
    if (processAssigned) {
      setAssignedProcesses((prevState) => prevState.filter(({ id }) => id !== processId));
      setAvailableProcesses((prevState) => [...prevState, processAssigned]);
    } else {
      const processAvailable = availableProcesses.find(
        ({ id }) => id === processId
      );
      setAssignedProcesses((prevState) => [...prevState, processAvailable]);
      setAvailableProcesses((prevState) => prevState.filter(({ id }) => id !== processId));
    }
  };
  const postAssignedsProcessesAction = (action) => {
    if (action === "continue") {
      dispatch(
        setProcessesAction({
          processesAction: [],
          queueAction: getFeedBackAction(queueAction),
        })
      );
    } else {
      const newProcessesAction = [];
      if (assignedProcesses) {
        // Handle list of process
        assignedProcesses.forEach((assProc) => {
          /* newProcessesAction = processesAction.filter(
          (el) => el.id !== assProc.id
        ); */
          newProcessesAction.push({
            id: assProc.id,
            action,
          });
        });
        setProcessesActionForQueue(...newProcessesAction);
        dispatch(
          setProcessesAction({
            processesAction: newProcessesAction,
            queueAction: getFeedBackAction(queueAction),
          })
        );
        setOpenProcessAssignDialog(false);
      } else {
        postAssignProcessAction(action);
      }
    }
  };

  const postAssignProcessAction = (action) => {
    const newProcessesAction = processesActionQueue.filter(
      (el) => el.id !== selectedProcessId
    );
    newProcessesAction.push({
      id: selectedProcessId,
      action,
      resourceId: null,
    });
    switch (action) {
      case "DELETE":
        setOpenProcessAssignDialog(false);
        redragProcessToOrigin(selectedProcessId);
        break;
      case "KEEP": {
         setOpenProcessAssignDialog(false);
        break;
      }
      default:
        break;
    }
    setProcessesActionForQueue(newProcessesAction);
  };

   // eslint-disable-next-line consistent-return
   const getFeedBackAction = (askingAction) => {
     switch (askingAction) {
       case ASK_DELETE_QUEUE:
         return CONFIRM_DELETE_QUEUE;
       case ASK_DELETE_QUEUE_FROM_ONE:
         return CONFIRM_DELETE_QUEUE;
       case ASK_ENABLE_QUEUE:
         return CONFIRM_ENABLE_QUEUE;
       case ASK_DISABLE_QUEUE:
         return CONFIRM_DISABLE_QUEUE;
       default:
         break;
     }
   };

   const cancelActionsProcess = () => {
    setOpenProcessAssignDialog(false);
    dispatch(
       setProcessesAction({
         processesAction: [],
         queueAction: CANCEL_QUEUE_ACTIONS,
       })
     );
   };

  const popupTitles = dialogMsg?.split(".");

  return (
    <CustomDialog
        open={openProcessAssignDialog}
        title={
          popupTitles && popupTitles.length > 1 ? popupTitles?.[0] : null
        }
        contentText={
          popupTitles && popupTitles.length > 1
              ? popupTitles?.[1]
              : popupTitles?.[0]
        }
        dialogHelper={dialogMsg2}
        aria-labelledby="confirm_message-dialog-title"
        maxWidth="xs"
        actionElements={[
          {
            label: t("Cancel"),
            onClick: () => cancelActionsProcess(),
            view: "cancelModal",
          },
          {
            label: t("No"),
            onClick: () => postAssignedsProcessesAction("DELETE"),
            view: "cancelOutlined",
            hidden: !hasSchedules,
          },
          {
            label: t("Yes"),
            onClick: () => postAssignedsProcessesAction("KEEP"),
            view: "primary",
            hidden: !hasSchedules,
          },
          {
            label: t("Continue"),
            onClick: () => {
              postAssignedsProcessesAction("continue")
              setOpenProcessAssignDialog(false) },
            view: "primary",
            hidden: hasSchedules,
          },
        ]}
    />
  );
}

export default ProcessAssignDialog;

ProcessAssignDialog.defaultProps = {
  hasSchedulesWithoutResources: false
}
