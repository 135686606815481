import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import "../../style.css";
import { Doughnut } from "react-chartjs-2";
import { colors } from "util";
import { LINE_CHART_TOOLTIP_OPTIONS } from "pages/newDashboard/dashboardUtils";

function ExceptionsProcesses({
  classes, data, t, width, height, legendOpts, formatExceptionGraphNumbers, getExceptionPercentage, layout
}) {
  const [kpi, setKpi] = useState({});
  const [legend, setLegend] = useState();

  const formatData = () => (data
    ? {
      labels: data.labels ? data.labels : [],
      datasets: [
        {
          label: data.labels ? data.labels : [],
          data: data.values ? data.values : [],
          backgroundColor: colors,
          borderWidth: 0,
        },
      ],
    }
    : {});

  useEffect(() => {
    setKpi(formatData());
  }, [data]);

  const onRefChange = React.useCallback((node) => {
    if (node !== null) {
      setLegend(node.chartInstance.generateLegend());
    }
  }, [kpi]);

    const handleLegendClick = (event, datasetIndex, charInstance) => {
        const { target } = event;
        const datasetMeta = charInstance.getDatasetMeta(0);
        const data = datasetMeta.data[datasetIndex];
        const totalDisplayedSets = datasetMeta.data.filter((item) => !item.hidden).length;
        if (totalDisplayedSets > 1 || data.hidden) {
            data.hidden = !data.hidden;
            target.classList.toggle("crossed-line");
            charInstance.update();
        }
    };

  return (
    <Grid className={classes.container}>
      <CardHeader
        title={t("exception.analytics.by.process")}
        titleTypographyProps={{
          variant: "h3",
          style: { fontWeight: "bold" },
        }}
        classes={{
          action: classes.select,
        }}
      />
      <CardContent className={classes.cardContent}>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          direction="column"
          spacing={1}
          style={{ height: "calc(100% - 50px)" }}
        >
          <Grid item xs={12} className={classes.graphContainer}>
            <Doughnut
              ref={onRefChange}
              data={kpi}
              width={width}
              height={height}
              options={{
                legend: legendOpts,
                layout,
                legendCallback: (charInstance) => (
                  <List className={classes.chartLegend}>
                    {kpi?.labels
                        && kpi?.labels.map((label, index) => (
                          <ListItem
                            key={`legend-item-excproces-${index}`}
                            className={classes.noPadding}
                          >
                            <div>
                              <span
                                style={{
                                  backgroundColor: kpi?.datasets[0]?.backgroundColor[index],
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                }}
                              />
                              {" "}
                              &nbsp;&nbsp;
                              <Typography
                                component="span"
                                onClick={(e) => handleLegendClick(e, index, charInstance)}
                                className={classes.chartItemLegendItem}
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%",
                                  position: "absolute",
                                  left: 20,
                                  top: "-4px",
                                }}
                                title={kpi?.labels && kpi?.labels[index]}
                              >
                                {kpi?.labels && kpi?.labels[index]}
                              </Typography>
                            </div>
                          </ListItem>
                        ))}
                  </List>
                  ),
                tooltips: {
                  callbacks: {
                    label(tooltipItem) {
                      return `${formatExceptionGraphNumbers(tooltipItem, kpi)} - ${getExceptionPercentage(tooltipItem, kpi)}`;
                    },
                  },
                  ...LINE_CHART_TOOLTIP_OPTIONS
                },
              }}
            />
            <div className={classes.legendContainer}>{legend}</div>
          </Grid>
        </Grid>
      </CardContent>
    </Grid>
  );
}

export default ExceptionsProcesses;
