import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import CalendarToday from "@material-ui/icons/CalendarToday";
import FormControl from "@material-ui/core/FormControl";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import { useQuery } from "@redux-requests/react";
import { useParams } from "react-router-dom";
import { FETCH_CURRENT_USER } from "redux/constants";
import { fetchPermissionsRoles } from "redux/actions/services";
import { useDispatch } from "react-redux";
import {
  REGEX,
  getLocale,
  isValidSiren,
  isValidSiret,
  formatDatePickerByLanguage,
} from "util";
import CustomSwitch from "../../../../Services/components/CustomSwitch";
import ConfirmMessage from "components/ConfirmMessage";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";

const FleetInfo = ({ disableFields, today }) => {
  const currentUser = useQuery({ type: FETCH_CURRENT_USER }).data;
  const {
    watch,
    formState: { errors },
    register,
    setValue,
    getValues,
      control
  } = useFormContext();
  const { t } = useTranslation();
  const dateFns = new DateFnsUtils();
  const [openInstanceOwnerConfirm, setOpenInstanceOwnerConfirm] = useState(false);
  const [roles, setRoles] = useState([]);

  const [openRoleChangeConfirmValue, setOpenRoleChangeConfirmValue] = useState(null);
  const { mode = "add" } = useParams();
  const dispatch = useDispatch();
  const handleInstanceOwnerChange = () => (watch("instanceOwner") ? setValue("instanceOwner", false) : setOpenInstanceOwnerConfirm(true));

  const switchFields = [
    {
      label: "instance.owner",
      fieldName: "instanceOwner",
      disabled: disableFields,
      isAuthorized:
        mode === "edit"
        && currentUser?.fleet?.instanceOwner
        && currentUser?.fleet?.id !== watch("fleetId"),
      onChange: handleInstanceOwnerChange
    },
  ]

  const confirmInstanceOwnerConfirm = () => {
    setValue("instanceOwner", true)
    setOpenInstanceOwnerConfirm(false)
  };

  const cancelInstanceOwnerConfirm = () => {
    setValue("instanceOwner", false)
    setOpenInstanceOwnerConfirm(false)
  };

  const onSuccess = (res) => {
    setRoles(res?.data?.content);
  };

  const handleRoleChange = (value) => (mode === "add" ? setValue("role", value) : setOpenRoleChangeConfirmValue(value))

  const onRoleChangeCancel = () => {
    setOpenRoleChangeConfirmValue(null);
  }

  const onRoleChangeConfirm = () => {
    setValue("role", openRoleChangeConfirmValue);
    setOpenRoleChangeConfirmValue(null);
  }

  useEffect(() => {
    dispatch(fetchPermissionsRoles({ searchCriteria: "" }, onSuccess, () => "", t))
  }, []);

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      spacing={5}
    >
      <Grid item>
        <CustomTextField
          {...register("companyName", {
            required: t("fleet.management.formControl.required"),
          })}
          id="companyName"
          label={`${t("fleet.management.formLabel.companyName")} *`}
          fullWidth
          InputLabelProps={{
            shrink: !!watch("companyName"),
          }}
          error={!!errors.companyName?.message}
          valued={!disableFields && getValues("companyName")}
          InputProps={{
            readOnly: disableFields,
          }}
          helperText={errors.companyName && errors.companyName?.message}
        />
      </Grid>
      {process.env.REACT_APP_HIDE_FIELDS_FROM_FLEET === "false" && (
        <Grid item>
          <CustomTextField
            {...register("siret", {
              validate: (value) => isValidSiret(value),
            })}
            id="siret"
            label={t("fleet.management.formLabel.siret")}
            disabled={disableFields}
            InputLabelProps={{
              shrink: !!watch("siret"),
            }}
            fullWidth
            error={!!errors.siret?.message}
            helperText={errors.siret && t("fleet.management.formControl.siret.notValid")}
          />
        </Grid>
      )}
      {process.env.REACT_APP_HIDE_FIELDS_FROM_FLEET === "false" && (
        <>
          <Grid item>
            <CustomTextField
              {...register("siren", {
                validate: (value) => isValidSiren(value),
              })}
              id="siren"
              label={t("fleet.management.formLabel.siren")}
              disabled={disableFields}
              fullWidth
              InputLabelProps={{
                shrink: !!watch("siren"),
              }}
              error={!!errors.siren?.message}
              helperText={errors.siren && t("fleet.management.formControl.siren.notValid")}
            />
          </Grid>
          <Grid item>
            <CustomTextField
              {...register("vatNumber", {
                required: t("fleet.management.formControl.required"),
                pattern: {
                  value: REGEX.ALPHANUM,
                  message: t("fleet.management.formControl.number"),
                },
              })}
              id="vatNumber"
              label={`${t("fleet.management.formLabel.vatNumber")} *`}
              disabled={disableFields}
              fullWidth
              InputLabelProps={{
                shrink: !!watch("vatNumber"),
              }}
              error={!!errors.vatNumber?.message}
              helperText={errors.vatNumber && errors.vatNumber?.message}
            />
          </Grid>
        </>
      )}
      <Grid item>
        <CustomTextField
          {...register("phoneNumber", {
            pattern: {
              value: REGEX.TEL,
              message: t("fleet.management.formControl.phone"),
            },
          })}
          id="phoneNumber"
          label={t("fleet.management.formLabel.phoneNumber")}
          fullWidth
          InputLabelProps={{
            shrink: !!watch("phoneNumber"),
          }}
          error={!!errors.phoneNumber?.message}
          valued={!disableFields && getValues("phoneNumber")}
          InputProps={{
            readOnly: disableFields,
          }}
          helperText={errors.phoneNumber && errors.phoneNumber?.message}
        />
      </Grid>
      <Grid item>
        <CustomTextField
          {...register("email", {
            required: t("fleet.management.formControl.required"),
            pattern: {
              value: REGEX.EMAIL,
              message: t("fleet.management.formControl.email"),
            },
          })}
          id="email"
          label={`${t("fleet.management.formLabel.email")} *`}
          fullWidth
          InputLabelProps={{
            shrink: !!watch("email"),
          }}
          error={!!errors.email?.message}
          valued={!disableFields && getValues("email")}
          InputProps={{
            readOnly: disableFields,
          }}
          helperText={errors.email && errors.email?.message}
        />
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <Controller
              control={control}
              name="role"
              rules={{
                required: t("fleet.management.formControl.required"),
              }}
              render={(field) => (
                <CustomAutoComplete
                      {...field}
                      label={t("default.group.role")}
                      optionLabel="name"
                      options={roles ?? []}
                      value={roles?.find(({ id }) => getValues("role")?.id === id)}
                      onChange={(value) => {
                        handleRoleChange(value);
                      }}
                      error={!!errors.role?.message}
                      helperText={!!errors.role && errors.role?.message}
                      disableCloseOnSelect={false}
                      disableClearable
                      readOnly={disableFields}
                  />
              )}
          />
        </FormControl>
      </Grid>
      {process.env.REACT_APP_HIDE_FIELDS_FROM_FLEET === "false" && (
        <>
          <Grid item>
            <CustomTextField
              {...register("address", {
                required: t("fleet.management.formControl.required"),
              })}
              id="address"
              label={`${t("fleet.management.formLabel.address")} *`}
              disabled={disableFields}
              fullWidth
              InputLabelProps={{
                shrink: !!watch("address"),
              }}
              error={!!errors.address?.message}
              helperText={errors.address && errors.address?.message}
            />
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={getLocale()}
            >
              <DatePicker
                InputProps={{
                  endAdornment: <CalendarToday />,
                }}
                {...register("clientSince", {
                  validate: (value) => dateFns.isValid(value),
                })}
                onChange={(date) => {
                  setValue("clientSince", date, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                defaultValue={today}
                value={getValues("clientSince")}
                label={`${t("fleet.management.formLabel.clientSince")} *`}
                autoOk
                showTodayButton
                format={formatDatePickerByLanguage(false)}
                disabled={disableFields}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                fullWidth
                invalidDateMessage=""
                invalidLabel=""
                error={!!errors.clientSince?.message}
                cancelLabel={t("user.button.cancel")}
                todayLabel={t("Today")}
                helperText={errors.clientSince && t("fleet.management.formControl.invalidDate")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </>
      )}
      <Grid item>
        {switchFields.map((field, i) => (field.isAuthorized ? (
          <Grid alignItems="center" key={`${field.fieldName}-${i}`}>
            <CustomSwitch
                label={t(field.label)}
                labelPlacement="start"
                key={watch(field.fieldName)}
                {...register(field.fieldName)}
                checked={watch(field.fieldName)}
                disabled={field.disabled}
                handleChange={field.onChange}
              />
          </Grid>
          ) : null))}
      </Grid>
      {openInstanceOwnerConfirm && (
        <ConfirmMessage
            message={t("instance.owner.confirmMsg").split("/n").map((line) => <Typography component="h6">{line}</Typography>)}
            openStart={openInstanceOwnerConfirm}
            onCancel={cancelInstanceOwnerConfirm}
            onConfirm={confirmInstanceOwnerConfirm}
            buttonConfirm={t("Confirm")}
            buttonCancel={t("Cancel")}
        />
      )}
      {openRoleChangeConfirmValue && (
        <ConfirmMessage
            message={t("group.change.role")}
            openStart={!!openRoleChangeConfirmValue}
            onCancel={onRoleChangeCancel}
            onConfirm={onRoleChangeConfirm}
            buttonConfirm={t("Confirm")}
            buttonCancel={t("Cancel")}
        />
      )}
    </Grid>
  );
};

export default FleetInfo;
