import { fade, makeStyles } from "@material-ui/core/styles";

const menuButtonPaddingLeft = 24;
const menuButtonPaddingTop = 14;
const logoReducedWidth = 30;

export default makeStyles((theme) => ({
  menuLeft: {
    position: "fixed",
    whiteSpace: "nowrap",
    width: "max-content",
    backgroundColor: theme.custom.color.containerBackground,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    border: 0,
    overflowY: "hidden"
  },
  menuButton: {
    padding: theme.spacing(3, 0, 3, 3),
    paddingLeft: menuButtonPaddingLeft,
    paddingTop: menuButtonPaddingTop,
    display: "flex",
    alignItems: "center",
    position: "relative",
    overflow: "revert",
    float: "right",
    ...theme.mixins.toolbar,
  },
  menuLeftClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.custom.variables.menuLeftCollapsedWidth,
  },
  tab: {
    minWidth: 0,
    textTransform: "inherit",
    padding: theme.spacing(0.6),
    lineHeight: "1.3rem",
    fontSize: "0.8rem",
    color: theme.palette.primary.main,
    opacity: 1,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(3),
    minHeight: "revert",
    "&:hover": {
      color: theme.custom.color.chartColor2,
    },
    "&:hover span svg": {
      fill: theme.custom.color.chartColor2,
      stroke: theme.custom.color.chartColor2,
    }
  },
  tabIcon: {
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
    width: 18,
    marginBottom: "0 !important"
  },
  tabIconExpanded: {
    marginRight: theme.spacing(1),
  },
  tabIconSelected: {
    fill: theme.custom.color.chartColor3,
    stroke: theme.custom.color.chartColor3
  },
  indicator: {
    display: "none"
  },
  tabWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "revert",
    textAlign: "left"
  },
  tabWrapperExpanded: {
    width: "100%",
  },
  scrollable: {
    paddingLeft: theme.spacing(3),
    overflowX: "hidden",
    maxHeight: "100%",
    "& .MuiTabs-flexContainer": {
      display: "flex",
      flexFlow: "column",
      flex: "auto 1",
      maxHeight: "100%",
    }
  },
  groupTitle: {
    color: fade(theme.palette.primary.main, 0.5),
    fontSize: "0.7rem",
    fontWeight: 400,
    textTransform: "uppercase",
  },
  tabSelected: {
    color: theme.custom.color.chartColor3,
    "&:hover": {
      color: theme.custom.color.chartColor3,
    },
    "&:hover span svg": {
      fill: theme.custom.color.chartColor3,
      stroke: theme.custom.color.chartColor3,
    }
  },
  logo: {
    width: 135,
    cursor: "pointer",
  },
  riyadbanklogo: {
    width: 110,
  },
  logoReduced: {
    width: 27,
  },
  toggleButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.7),
    boxShadow: "0px 0px 6px 1px #E1E6FF",
},
  toggleButtonOpen: {
    position: "absolute",
    right: 10,
    top: "calc(50% - 5px)",
    transform: "translateY(-50%)",
  },
  toggleButtonClose: {
    position: "fixed",
    top: menuButtonPaddingTop + 3,
    left: menuButtonPaddingLeft + logoReducedWidth + 6,
  },
  toggleButtonIcon: {
    stroke: theme.palette.primary.main,
    width: 9,
    height: 9,
  },
  menuContainer: {
    overflowX: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "auto",
    maxHeight: "min-content",
    "&::-webkit-scrollbar": {
      display: "revert",
    }
  },
  footerMenu: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: 60
  }
}));
