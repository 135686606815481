import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    sectionContainer: {
      position: "relative",
      border: `1px solid ${theme.custom.color.containersBorder}`,
      padding: theme.spacing(3, 5),
      marginTop: 30,
      display: "grid",
      gridTemplateColumns: "1fr",
      rowGap: "10px"
    },
    sectionTitle: {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        left: 26,
        top: -13,
        backgroundColor: theme.palette.background.default,
        padding: "0 10px",
    },
    sectionTitleText: {
        fontSize: "1rem",
        fontWeight: 700,
        color: theme.palette.primary.main,
        marginRight: 5
    },
    dialogDefaultText: {
        fontSize: "1rem",
        fontWeight: "400",
        color: theme.palette.primary.main,
        marginLeft: 5,
        textTransform: "capitalize"
    },
    radio: {
        color: theme.palette.primary.main,
        "&.Mui-checked": {
            "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                {
                    color: theme.palette.primary.main,
                },
            "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                color: theme.custom.color.color6,
            },
        },
    },
}));
