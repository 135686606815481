import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import TrashIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useQuery } from "@redux-requests/react";
import { deleteDivisions } from "../../../../../redux/actions/services";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import { isPermitted } from "../../../../../components/HasPermission";
import useStyles from "../../../../../components/TableComponents/style";

export default function DivisionTableHeader({
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedIndeterminate,
  fetchEntities,
  page,
  rowsPerPage,
  fetchedData,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setOpenDeletePopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const { data } = useQuery({ type: fetchEntities().type });

  const onlyCurrentUserSelected = selected.length === 1 && selected[0] === Number(currentUser.id);
  const onlyCurrentUserInList = fetchedData.length === 1 && fetchedData[0].id === Number(currentUser.id);

  const deleteAll = (newSelected = selected) => {
    dispatch(
       deleteDivisions(
        newSelected,
        () => {
          setIsLoading(false);
          dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
          setSelected([]);
          handleCloseConfirmationPopup();
          toast.success(t("division deleted successfully"));
        },
        () => {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        },
      ),
    );
  };

  const onClick = () => {
    setIsLoading(true);
    deleteAll(selected);
  };

  return (
    <Grid
      container
      xs={12}
      justify="space-between"
      className={classes.tableHeaderContainer}
    >
      <Grid container item xs={12} justify="flex-end" alignItems="center">
        {isPermitted(currentUser, "Delete division") && data?.totalElements > 0 && (
          <>
            <Grid item>
              <Tooltip placement="top" title={t("tooltip.action.delete")}>
                <IconButton
                  aria-label="delete"
                  disabled={
                    isEmpty(selected)
                    || onlyCurrentUserSelected
                    || onlyCurrentUserInList
                  }
                  onClick={() => {
                    setOpenDeletePopup(true);
                  }}
                  size="small"
                >
                  <TrashIcon className={isEmpty(selected) ? classes.rowActionButtons : classes.deleteIcon} />
                </IconButton>
              </Tooltip>
              <ConfirmMessage
                message={t("divisions.module.deleteClientsActionMessage")}
                openStart={openDeletePopup}
                onCancel={handleCloseConfirmationPopup}
                onConfirm={onClick}
                buttonConfirm={t("Delete")}
                buttonCancel={t("Cancel")}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item>
              <Checkbox
                checked={checkedAll && !onlyCurrentUserInList}
                className={classes.rowActionButtons}
                indeterminate={checkedIndeterminate && !onlyCurrentUserSelected}
                onChange={(e) => {
                  handleSelectedAll(e.target.checked);
                }}
                disabled={onlyCurrentUserInList}
                size="small"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
