/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { orderBy } from "lodash";
import {
  IconButton,
  Box,
  Avatar,
  TablePagination,
  Typography,
  Tooltip,
  Grid,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  ButtonBase,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  HelpOutlineOutlined as NotSubmittedIcon,
  ExtensionOutlined as DraftIcon,
  BackupOutlined as SubmittedIcon,
  TimelapseOutlined as PendingIcon,
  ArrowDropDown,
  ArrowDropUp,
  ArchiveOutlined as Archive,
  Replay,
  AssignmentOutlined,
  DeleteOutline,
  CheckCircleOutline,
  RemoveCircleOutline,
} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import SubmitIcon from "@material-ui/icons/Send";
import { useSelector, useDispatch } from "react-redux";
import {
  formatDateByLanguage, formtNumberByLanguage, round, truncateString,
} from "util";
import { OPPORTUNITY_ASSESSMENT_BASE_URL } from "util/helpers/route.helper";
import {
  changeOpportunitiesPage,
  changeMyCasesPage,
  changeMyCasesRowsPerPage,
  changeOpportunitiesRowsPerPage,
} from "../../redux/slices/processesSlice";
import useStyles from "./Style";
import { HasPermission } from "../HasPermission";
import DataNotFound from "../DataNotFound";
import RpaOppNotFound from "../../assets/RPA_OPPURTINITIES.svg";

const TableRPA = ({
  processes, adminTable, brief, changeProcess
}) => {
  const [data, setData] = useState([...processes]);
  const [sortingDirection, setSortingDirection] = useState("desc");
  const [sortingColumn, setSortingColumn] = useState("updatedAt");
  const {
    pagination = 0,
    myCasesLoaded = true,
  } = useSelector((state) => state.processes);

  useEffect(() => {
    if (Array.isArray(processes) && processes.length > 0) {
      const newData = [...processes];
      const indexes = newData
        .map((e, i) => (e.status === "draft" ? i : ""))
        .filter(String);

      indexes.forEach((el) => {
        const newObj = { ...newData[el] };
        newObj.feasibility = Number.MIN_SAFE_INTEGER;
        newObj.impact = Number.MIN_SAFE_INTEGER;
        newObj.roi = Number.MIN_SAFE_INTEGER;
        newData[el] = newObj;
      });
      setData(newData);
    }
  }, [processes]);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    if (adminTable) {
      dispatch(changeOpportunitiesPage({ newPage }));
    } else {
      dispatch(changeMyCasesPage({ newPage }));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (adminTable) {
      dispatch(
        changeOpportunitiesRowsPerPage({
          rowsPerPage: parseInt(event.target.value),
        }),
      );
      dispatch(changeOpportunitiesPage({ newPage: 0 }));
    } else {
      dispatch(
        changeMyCasesRowsPerPage({ rowsPerPage: parseInt(event.target.value) }),
      );
      dispatch(changeMyCasesPage({ newPage: 0 }));
    }
  };

  const classes = useStyles();

  const history = useHistory();

  const handleProcessClick = (process) => {
    if (process.status.toLowerCase() === "draft") {
      history.push(`${OPPORTUNITY_ASSESSMENT_BASE_URL}/rpa/edit/${process.id}`);
    } else {
      history.push(`${OPPORTUNITY_ASSESSMENT_BASE_URL}/rpa/view/${process.id}`);
    }
  };

  const invertSortingDirection = {
    asc: "desc",
    desc: "asc",
  };

  const [headerIndicators, setHeaderIndicators] = useState([
    {
      indicator: 0,
      name: "Name",
      property: "name",
      direction: "asc",
      currentField: false,
      display: false,
    },
    {
      indicator: 0,
      name: "createdBy",
      property: "createdBy",
      direction: "asc",
      currentField: false,
      display: false,
    },
    {
      indicator: 0,
      name: "Date created",
      property: "createdAt",
      direction: "asc",
      currentField: false,
      display: false,
    },
    {
      indicator: 0,
      name: "updatedBy",
      property: "lastModifiedBy",
      direction: "asc",
      currentField: false,
      display: false,
    },
    {
      indicator: 0,
      name: "Last update",
      property: "updatedAt",
      direction: "asc",
      currentField: false,
      display: false,
    },
    {
      indicator: 1,
      name: "Feasibility",
      property: "feasibility",
      direction: "asc",
      currentField: false,
      display: false,
    },
    {
      indicator: 1,
      name: "Impact",
      property: "impact",
      direction: "asc",
      currentField: false,
      display: false,
    },
    {
      indicator: 1,
      name: "Roi",
      property: "roi",
      direction: "asc",
      currentField: false,
      display: false,
    },
    {
      indicator: 2,
      name: "Status",
      property: "status",
      direction: "asc",
      currentField: false,
      display: false,
    },
  ]);

  const removeFilterIcon = () => {
    const tempData = [...headerIndicators];
    tempData.forEach((el) => {
      if (!el.currentField) {
        el.direction = "asc";
        el.currentField = false;
        el.display = false;
      }
    });
    return tempData;
  };

  const handleColumnSorting = (header) => {
    setSortingDirection(invertSortingDirection[sortingDirection]);
    setSortingColumn(header.property);
    const tmp = removeFilterIcon();
    tmp
      .filter((el) => el.name !== header.name)
      .forEach((el) => {
        el.direction = "asc";
        el.currentField = false;
        el.display = false;
      });
    const index = tmp.findIndex((el) => el.name === header.name);

    const newObj = { ...tmp[index] };
    newObj.direction = invertSortingDirection[newObj.direction];
    newObj.currentField = true;
    newObj.display = true;
    tmp[index] = newObj;
    setHeaderIndicators(tmp);
  };

  const handleMouseEnterHeader = (header) => {
    const tempData = [...headerIndicators];
    const index = tempData.findIndex((el) => el.name === header.name);
    const newObj = { ...tempData[index] };
    newObj.display = true;
    tempData[index] = newObj;
    setHeaderIndicators(tempData);
  };
  const handleMouseLeaveHeader = () => {
    const tmp = removeFilterIcon();
    setHeaderIndicators(tmp);
  };

  const deleteButtonIcon = (process) => (
    <Tooltip placement="top" title={t("Delete")}>
      <IconButton
        className={classes.deleteIcon}
        edge="end"
        aria-label="view"
        onClick={() => changeProcess(process, "deleted")}
      >
        <DeleteOutline />
      </IconButton>
    </Tooltip>
  );

  return (
    ((myCasesLoaded && processes?.length === 0 && !adminTable)
      || (processes?.length === 0
        && adminTable)) ? (
          <DataNotFound
              message={t("rpaQual.opportunity.list.empty")}
              icon={RpaOppNotFound}
            />
    )
    : (<Paper className={classes.tableContainer} component={Paper}>
      <TableContainer className={classes.hideXScroll}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headerIndicators
                && headerIndicators
                  .filter((el) => el.indicator === 0)
                  .map((column, i) => (
                    <TableCell
                      key={`header-cell-${i}`}
                      className={classes.sortableField}
                      onClick={() => handleColumnSorting(column)}
                      onMouseEnter={() => handleMouseEnterHeader(column)}
                      onMouseLeave={() => handleMouseLeaveHeader(column)}
                      colSpan={1}
                    >
                      <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                      >
                        <Typography
                          variant="subtitle1"
                          className={classes.columnHeader}
                        >
                          {t(column?.name)}
                        </Typography>
                        {column.direction === "asc" ? (
                          <ArrowDropUp
                            style={{
                              fill: column.currentField ? "#303167" : "#3c4D6e",
                              display: column.display ? "inline" : "none",
                            }}
                          />
                        ) : (
                          <ArrowDropDown
                            style={{
                              fill: column.currentField ? "#303167" : "#3c4D6e",
                              display: column.display ? "inline" : "none",
                            }}
                          />
                        )}
                      </Grid>
                    </TableCell>
                  ))}

              <TableCell
                className={clsx(
                  classes.factorThWidth,
                  classes.factorsContainer,
                )}
              >

                <Grid
                  container
                  item
                  xs={12}
                  wrap="nowrap"
                >
                  {headerIndicators
                    && headerIndicators
                      .filter((el) => el.indicator === 1)
                      .map((column, i) => (
                        <Box
                          component={Grid}
                          mr="10px"
                          style={{ display: "flex", justifyContent: "center" }}
                          container
                          itempaddingLeft="100"
                          columns={3}
                          alignItems="center"
                          justify="center"
                          wrap="nowrap"
                          key={`header-indicators-cell-${i}`}
                          onClick={() => handleColumnSorting(column)}
                          onMouseEnter={() => handleMouseEnterHeader(column)}
                          onMouseLeave={() => handleMouseLeaveHeader(column)}
                          className={classes.sortableField}
                        >
                          <Tooltip title={t(column.name)}>
                            <ButtonBase>
                              <Typography
                                  variant="subtitle1"
                                  className={classes.columnHeader}
                              >
                                {t(column.name)}
                              </Typography>
                            </ButtonBase>
                          </Tooltip>
                          {column.direction === "asc" ? (
                            <ArrowDropUp
                              style={{
                                fill: column.currentField
                                  ? "#303167"
                                  : "#3c4D6e",
                                display: column.display ? "inline" : "none",

                              }}
                            />
                          ) : (
                            <ArrowDropDown
                              style={{
                                fill: column.currentField
                                  ? "#303167"
                                  : "#3c4D6e",
                                display: column.display ? "inline" : "none",
                              }}
                            />
                          )}
                        </Box>
                      ))}
                </Grid>
              </TableCell>

              {headerIndicators
                && headerIndicators
                  .filter((el) => el.indicator === 2)
                  .map((column) => (
                    <TableCell
                      className={classes.sortableField}
                      onClick={() => handleColumnSorting(column)}
                      onMouseEnter={() => handleMouseEnterHeader(column)}
                      onMouseLeave={() => handleMouseLeaveHeader(column)}
                    >
                      <Grid
                        container
                        item
                        alignItems="center"
                        direction="row"
                        wrap="nowrap"
                      >
                        <Typography
                          variant="subtitle1"
                          className={classes.columnHeader}
                        >
                          {t(column.name)}
                        </Typography>
                        {column.direction === "asc" ? (
                          <ArrowDropUp
                            style={{
                              fill: column.currentField ? "#303167" : "#3c4D6e",
                              display: column.display ? "inline" : "none",
                            }}
                          />
                        ) : (
                          <ArrowDropDown
                            style={{
                              fill: column.currentField ? "#303167" : "#3c4D6e",
                              display: column.display ? "inline" : "none",
                            }}
                          />
                        )}
                      </Grid>
                    </TableCell>
                  ))}
              <TableCell>
                <Typography variant="subtitle1">{t("Actions")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {((myCasesLoaded && processes?.length === 0 && !adminTable)
              || (processes?.length === 0
                && adminTable)) && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <DataNotFound
                      message={t("rpaQual.opportunity.list.empty")}
                      icon={RpaOppNotFound}
                    />
                  </TableCell>
                </TableRow>
            )}
            {/* {((isMyCasesLoading && !adminTable) ||
              (isOpportunitiesLoading && adminTable)) &&
              [1, 2, 3, 4, 5].map((value) => (
                <TableRow key={value}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Skeleton variant="circle" width={32} height={32} />
                      <Box width="50%" marginLeft={2}>
                        <Skeleton variant="text" width={"100%"} />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell className={classes.factorsContainer}>
                    <Box display="flex" alignItems="center">
                      <Box width="33.33%">
                        <Skeleton variant="text" width={"100%"} />
                      </Box>
                      <Box width="33.33%" marginLeft={2}>
                        <Skeleton variant="text" width={"100%"} />
                      </Box>
                      <Box width="33.33%" marginLeft={2}>
                        <Skeleton variant="text" width={"100%"} />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              ))} */}
            {processes?.length > 0
              && ((myCasesLoaded && !adminTable)
                || adminTable)
              && orderBy(data, sortingColumn, sortingDirection).map((process) => (
                <TableRow className={classes.tableRow} key={process.id}>
                  <TableCell
                    className={classes.processName}
                    onClick={handleProcessClick.bind(this, process)}
                    component="th"
                    scope="row"
                  >
                    <Typography variant="body2">
                      {truncateString(process.name, 50)}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={handleProcessClick.bind(this, process)}>
                    <Box display="flex" alignItems="center">
                      <Avatar className={classes.avatar}>
                        {process?.createdBy?.charAt(0)?.toUpperCase()}
                      </Avatar>
                      <Typography variant="body2">
                        {process?.createdBy}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell onClick={handleProcessClick.bind(this, process)}>
                    <Typography variant="body2">
                      {formatDateByLanguage(process.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell onClick={handleProcessClick.bind(this, process)}>
                    <Box display="flex" alignItems="center">
                      <Avatar className={classes.avatar}>
                        {process?.lastModifiedBy?.charAt(0)?.toUpperCase()}
                      </Avatar>
                      <Typography variant="body2">
                        {process?.lastModifiedBy}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell onClick={handleProcessClick.bind(this, process)}>
                    <Typography variant="body2">
                      {formatDateByLanguage(process.updatedAt)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    onClick={handleProcessClick.bind(this, process)}
                    className={classes.factorsContainer}
                  >
                    {process.status.toLowerCase() !== "draft" && (
                    <Grid container item xs={12}>
                      <Grid
                        container
                        item
                        xs={4}
                        justify="center"
                      >
                        <span
                          className={
                                process.feasibility >= 0
                                  && process.feasibility < 2.5
                                  ? classes.factorComplex
                                  : process.feasibility >= 2.5
                                    && process.feasibility < 4
                                    ? classes.factorMedium
                                    : classes.factorSimple
                              }
                        >
                          {process.status.toLowerCase() !== "draft"
                            ? formtNumberByLanguage(round(process.feasibility, 1))
                            : "----"}
                        </span>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        justify="center"
                      >
                        <span
                          className={
                                process.impact >= 0 && process.impact < 2.5
                                  ? classes.factorComplex
                                  : process.impact >= 2.5 && process.impact < 4
                                    ? classes.factorMedium
                                    : classes.factorSimple
                              }
                        >
                          {process.status.toLowerCase() !== "draft"
                            ? formtNumberByLanguage(round(process.impact, 1))
                            : "----"}
                        </span>

                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        justify="center"
                      >
                        <span
                          className={
                                process.roi >= 5 || process.roi < 0
                                  ? classes.factorComplex
                                  : 5 - process.roi >= 2.5
                                    && 5 - process.roi < 4
                                    ? classes.factorMedium
                                    : 5 - process.roi >= 4
                                      ? classes.factorSimple
                                      : classes.factorComplex
                              }
                        >
                          {process.roi >= 5 || process.roi < 0
                            ? formtNumberByLanguage(round(0, 1))
                            : formtNumberByLanguage(round(5 - process.roi, 1))}
                        </span>

                      </Grid>
                    </Grid>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={handleProcessClick.bind(this, process)}
                    className={
                        process.status.toLowerCase() === "accepted"
                          ? clsx(classes.statusAccepted, classes.statusText)
                          : process.status.toLowerCase() === "rejected"
                            ? clsx(classes.statusRejected, classes.statusText)
                            : process.status.toLowerCase() === "roadmap"
                              ? clsx(classes.statusRejected, classes.roadmapIcon)
                              : process.status.toLowerCase() === "draft"
                                ? clsx(classes.statusDraft, classes.statusText)
                                : process.status.toLowerCase() === "submitted"
                                  && !adminTable
                                  ? clsx(classes.statusSubmitted, classes.roadmapIcon)
                                  : (process.status.toLowerCase() === "submitted" || process.status.toLowerCase() === "reconsider")
                                    && adminTable
                                    ? clsx(classes.statusPending, classes.roadmapIcon)
                                    : clsx(classes.statusNotSubmitted, classes.statusText)
                      }
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">
                        {process.status.toLowerCase() === "accepted" && (
                        <CheckCircleOutline
                          className={classes.statusIcon}
                        />
                        )}
                        {process.status.toLowerCase() === "rejected" && (
                        <RemoveCircleOutline
                          className={classes.statusIcon}
                        />
                        )}
                        {process.status.toLowerCase() === "not submitted" && (
                        <NotSubmittedIcon className={classes.statusIcon} />
                        )}
                        {process.status.toLowerCase() === "draft" && (
                        <DraftIcon className={classes.statusIcon} />
                        )}
                        {process.status.toLowerCase() === "roadmap" && (
                        <AssignmentOutlined
                          className={clsx(
                            classes.statusIcon,
                            classes.roadmapIcon,
                          )}
                        />
                        )}
                        {(process.status.toLowerCase() === "submitted" || process.status.toLowerCase() === "reconsider")
                            && !adminTable && (
                              <SubmittedIcon className={classes.statusIcon} />
                        )}
                        {((process.status.toLowerCase() === "submitted" || process.status.toLowerCase() === "reconsider"))
                            && adminTable && (
                              <PendingIcon className={classes.statusIcon} />
                        )}
                      </Typography>
                      <Box marginLeft={1}>
                        <Typography variant="body2">
                          {(process.status.toLowerCase() === "submitted" || process.status.toLowerCase() === "reconsider") && adminTable
                            ? t("Pending")
                            : t(`rpaQual.status.${process.status}`)}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {!process.isArchived ? (
                      <Box display="flex">
                        <Grid
                          container
                          item
                          justify="flex-end"
                          xs={12}
                          className={classes.actionButtons}
                        >
                          {process.status.toLowerCase() === "accepted" && (
                          <Grid container item xs={12}>
                            <HasPermission name="Accept / Reject / Reconsider">
                              <Grid container item xs={4}>
                                <Tooltip
                                  placement="top"
                                  title={t("rpaQual.transition.reconsider")}
                                >
                                  <IconButton
                                    className={classes.otherIcons}
                                    edge="end"
                                    aria-label="view"
                                    onClick={() => changeProcess(process, "submitted")}
                                  >
                                    <Replay fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </HasPermission>
                            <HasPermission name="Archive / Backlog">
                              <Grid container item xs={4}>
                                <Tooltip
                                  placement="top"
                                  title={t(
                                    "rpaQual.transition.addToRoadMap",
                                  )}
                                >
                                  <IconButton
                                    className={classes.roadmapIcon}
                                    edge="end"
                                    aria-label="view"
                                    onClick={() => changeProcess(process, "roadMap")}
                                  >
                                    <AssignmentOutlined fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </HasPermission>
                            <HasPermission name="Delete opportunity">
                              <Grid container item xs={4}>
                                {deleteButtonIcon(process)}
                              </Grid>
                            </HasPermission>
                          </Grid>
                          )}
                          {process.status.toLowerCase() === "rejected" && (
                          <Grid container item xs={12}>
                            <HasPermission name="Archive / Backlog">
                              <Grid container item xs={4}>
                                <Tooltip
                                  placement="top"
                                  title={t("rpaQual.transition.archive")}
                                >
                                  <IconButton
                                    className={classes.otherIcons}
                                    edge="end"
                                    aria-label="view"
                                    onClick={() => changeProcess(process, "archived")}
                                  >
                                    <Archive fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </HasPermission>
                            <HasPermission name="Accept / Reject / Reconsider">
                              <Grid container item xs={4}>
                                <Tooltip
                                  placement="top"
                                  title={t("rpaQual.transition.reconsider")}
                                >
                                  <IconButton
                                    className={classes.otherIcons}
                                    edge="end"
                                    aria-label="view"
                                    onClick={() => changeProcess(process, "submitted")}
                                  >
                                    <Replay fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </HasPermission>
                            <HasPermission name="Delete opportunity">
                              <Grid container item xs={4}>
                                {deleteButtonIcon(process)}
                              </Grid>
                            </HasPermission>
                          </Grid>
                          )}

                          {(process.status.toLowerCase() === "draft"
                              || process.status.toLowerCase() === "not submitted") && (
                                <Grid container item xs={12}>
                                  {process.status.toLowerCase() !== "not submitted" && (
                                    <Grid container item xs={4}>
                                      &nbsp;
                                    </Grid>
                                  )}
                                  {process.status.toLowerCase() === "not submitted" && (
                                    <Grid container item xs={4}>
                                      <Tooltip
                                        placement="top"
                                        title={t("rpaQual.action.submit")}
                                      >
                                        <IconButton
                                          className={classes.editIcon}
                                          edge="end"
                                          aria-label="view"
                                          onClick={() => changeProcess(process, "submitted")}
                                        >
                                          <SubmitIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  )}
                                  <Grid container item xs={4}>
                                    <Tooltip placement="top" title={t("Edit")}>
                                      <IconButton
                                        className={classes.editIcon}
                                        edge="end"
                                        aria-label="view"
                                        onClick={() => history.push(
                                          `${OPPORTUNITY_ASSESSMENT_BASE_URL}/rpa/edit/${process.id}`,
                                        )}
                                      >
                                        <EditIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                  <HasPermission name="Delete opportunity">
                                    <Grid container item xs={4}>
                                      {deleteButtonIcon(process)}
                                    </Grid>
                                  </HasPermission>
                                </Grid>
                          )}

                          {(process.status.toLowerCase() === "submitted" || process.status.toLowerCase() === "reconsider") && (
                          <Grid container item xs={12}>
                            <HasPermission name="Accept / Reject / Reconsider">
                              <Grid container item xs={4}>
                                <Tooltip
                                  placement="top"
                                  title={t("Accept")}
                                >
                                  <IconButton
                                    className={classes.accept}
                                    edge="end"
                                    aria-label="view"
                                    onClick={() => changeProcess(process, "accepted")}
                                  >
                                    <CheckCircleOutline fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </HasPermission>
                            <HasPermission name="Accept / Reject / Reconsider">
                              <Grid container item xs={4}>
                                <Tooltip
                                  placement="top"
                                  title={t("Reject")}
                                >
                                  <IconButton
                                    className={classes.rejectIcon}
                                    edge="end"
                                    aria-label="view"
                                    onClick={() => changeProcess(process, "rejected")}
                                  >
                                    <RemoveCircleOutline fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </HasPermission>
                            <HasPermission name="Delete opportunity">
                              <Grid container item xs={4}>
                                {deleteButtonIcon(process)}
                              </Grid>
                            </HasPermission>
                          </Grid>
                          )}

                          {process.status.toLowerCase() === "roadmap" && (
                          <Grid container item xs={12}>
                            <Grid container item xs={4}>
                                  &nbsp;
                            </Grid>
                            <Grid container item xs={4}>
                                  &nbsp;
                            </Grid>
                            <Grid container item xs={4}>
                              <HasPermission name="Archive / Backlog">
                                <Tooltip
                                  placement="top"
                                  title={t("rpaQual.transition.archive")}
                                >
                                  <IconButton
                                    className={classes.otherIcons}
                                    edge="end"
                                    aria-label="view"
                                    onClick={() => changeProcess(process, "archived")}
                                  >
                                    <Archive fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </HasPermission>
                            </Grid>
                          </Grid>
                          )}
                        </Grid>
                      </Box>
                    ) : (
                      <Typography
                        style={{ fontStyle: "italic" }}
                        color="secondary"
                        variant="body2"
                      >
                        {t("rpaQual.display.archived")}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        !brief && (
          <TablePagination
            rowsPerPageOptions={[6, 10, 20]}
            component="div"
            count={
              (adminTable
                ? pagination.opportunitiesTotalElements
                : pagination.myCasesTotalElements) ?? 0
            }
            rowsPerPage={
              adminTable
                ? pagination.opportunitiesRowsPerPage
                : pagination.myCasesRowsPerPage
            }
            page={
              adminTable ? pagination.opportunitiesPage : pagination.myCasesPage
            }
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t("Rows Per page")}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("pagination.of")} ${count}`}
          />
        )
      }
      {/* eslint-disable-next-line react/jsx-indent */}
       </Paper>)
  );
};

export default TableRPA;
