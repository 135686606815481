import {
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Duration, MAX_GRAPH_PROCESSES } from "util/constants";
import { accumulate, colors } from "util";

import { LINE_CHART_TOOLTIP_OPTIONS } from "pages/newDashboard/dashboardUtils";

function ExceptionsTrend({
  classes, data, t, width, height, legendOpts, formatExceptionGraphNumbers, getExceptionPercentage, layout
}) {
  const [kpi, setKpi] = useState({});
  const [legend, setLegend] = useState();

  const buildChartDataset = (data) => {
    const values = data?.values;
    const tempData = Array.from(new Set(values.flatMap((item) => Object.keys(item)))).map(
      (k) => ({
        name: k,
        values: values.map((o) => (Object.keys(o).includes(k) ? o[k] : 0)),
      }),
    );
    if (tempData.length > MAX_GRAPH_PROCESSES) {
      const sumValues = Array(tempData[0]?.values.length).fill(0);
      tempData?.slice(MAX_GRAPH_PROCESSES).forEach((item) => {
        item.values.forEach((value, index) => {
          sumValues[index] += value;
        });
      });
      return tempData?.slice(0, MAX_GRAPH_PROCESSES)?.concat({
        name: t("Others"),
        values: sumValues
      })
    }
    return tempData
  };

  const formatData = (values) => (data
    ? {
      labels: data.labels,
      datasets: values?.map((processName, i) => ({
        label: processName?.name,
        data: accumulate(processName?.values),
        backgroundColor: colors[i],
        borderColor: "#FFF",
        borderWidth: 1,
        barThickness: 20,
      })),
    }
    : {});

  useEffect(() => {
    const values = buildChartDataset(data);
    setKpi(formatData(values));
  }, [data]);

  const onRefChange = React.useCallback((node) => {
    if (node !== null) {
      setLegend(node.chartInstance?.generateLegend());
    }
  }, [kpi]);

  return (
    <div className={classes.container}>
      <CardHeader
        title={t("exception.analytics.by.trend")}
        titleTypographyProps={{
          variant: "h3",
          style: { fontWeight: "bold" },
        }}
        classes={{
          action: classes.select,
        }}
      />
      <CardContent className={classes.cardContent}>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          direction="column"
          spacing={1}
          style={{ height: "calc(100% - 50px)" }}
        >
          <Grid item xs={12} className={classes.graphContainer}>
            <Bar
              ref={onRefChange}
              width={width}
              height={height}
              data={kpi}
              options={{
                legend: legendOpts,
                layout: {
                          padding: {
                            ...layout.padding,
                            top: 26
                         }
                        },
                tooltips: {
                  mode: "nearest",
                  callbacks: {
                    label(tooltipItem) {
                      // eslint-disable-next-line max-len
                      return `${formatExceptionGraphNumbers(tooltipItem, kpi)} - ${getExceptionPercentage(tooltipItem, kpi, Duration.DAY)}`;
                    },
                  },
                  ...LINE_CHART_TOOLTIP_OPTIONS
                },
                legendCallback: () => {
                  const labels = kpi?.datasets.map((el) => el.label);
                  return (
                    <List
                      className={classes.chartLegend}
                      style={{ paddingLeft: "8px" }}
                    >
                      {labels
                        && labels.map((label, index) => (
                          <ListItem
                            key={`legend-item-exception-trend-${index}`}
                            className={classes.noPadding}
                          >
                            <div>
                              <span
                                style={{
                                  backgroundColor:
                                    kpi.datasets[index]
                                      .backgroundColor,
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                }}
                              />
                              {" "}
                              &nbsp;&nbsp;
                              <Typography
                                component="span"
                                className={classes.chartItemLegendItem}
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%",
                                  position: "absolute",
                                  left: 20,
                                  top: "-4px",
                                }}
                                title={labels && labels[index]}
                              >
                                {labels && labels[index]}
                              </Typography>
                            </div>
                          </ListItem>
                        ))}
                    </List>
                  );
                },
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      display: false,
                      stacked: true,
                    },
                  ],
                  yAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      display: true,
                      stacked: true,
                    },
                  ],
                },
              }}
            />
            <div className={classes.legendContainer}>{legend}</div>
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
}

export default ExceptionsTrend;
