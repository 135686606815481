import {
    CREATE_CUSTOM_ALERT,
    DELETE_CUSTOM_ALERT_BY_ID,
    FETCH_CUSTOM_ALERT,
    FETCH_CUSTOM_ALERTS,
    RESET_CUSTOM_ALERT_SCHEDULER,
    UPDATE_CUSTOM_ALERT,
    UPDATE_CUSTOM_ALERT_EMAIL_STATUS,
    UPDATE_CUSTOM_ALERT_SMS_STATUS,
    UPDATE_CUSTOM_ALERT_SUBSCRIPTION_STATUS
} from "../../../constants";
import { URL_CUSTOM_ALERT } from "../../../constants/endpoints";

export const fetchCustomAlerts = (filter = {}) => {
    const {
        searchCriteria, pageNo, pageSize, status, types, fleets, divisions
    } = filter;
    return {
        type: FETCH_CUSTOM_ALERTS,
        request: {
            url: encodeURI(`${URL_CUSTOM_ALERT}${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
            params: {
                pageNo,
                pageSize,
                status: status ? status.join(",") : null,
                types: types ? types.join(",") : null,
                fleetsIds: fleets ? fleets?.join(",") : null,
                divisionsIds: divisions ? divisions?.join(",") : null,
            },
        },
    };
};
export const deleteCustomAlert = (id, onSuccess, onError) => ({
    type: DELETE_CUSTOM_ALERT_BY_ID,
    request: {
        url: `${URL_CUSTOM_ALERT}${id}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError();
            return error;
        },
    },
});
export const enableOrDisableEmailForCustomAlert = (id, emailStatus) => ({
    type: UPDATE_CUSTOM_ALERT_EMAIL_STATUS,
    request: {
        url: `${URL_CUSTOM_ALERT}email/${id}/${emailStatus ? "disable" : "enable"}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
    },
});
export const enableOrDisableSmsForCustomAlert = (id, smsStatus) => ({
    type: UPDATE_CUSTOM_ALERT_SMS_STATUS,
    request: {
        url: `${URL_CUSTOM_ALERT}sms/${id}/${smsStatus ? "disable" : "enable"}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
    },
});

export const changeCustomAlertSubscriptionStatus = (id, status, onSuccess, onError) => ({
    type: UPDATE_CUSTOM_ALERT_SUBSCRIPTION_STATUS,
    request: {
        url: `${URL_CUSTOM_ALERT}${id}/${status ? "subscribe" : "unsubscribe"}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
    },
    meta: {
      onSuccess: (response) => {
          if (onSuccess) onSuccess(response);
          return response;
      },
      onError: (error) => {
          if (onError) onError(error);
          return error;
      },
  },
});

export const resetCustomAlertScheduler = (id, onSuccess, onError) => ({
    type: RESET_CUSTOM_ALERT_SCHEDULER,
    request: {
        url: `${URL_CUSTOM_ALERT}scheduler/reset/${id}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const createCustomAlert = (customAlert, onSuccess, onError) => ({
    type: CREATE_CUSTOM_ALERT,
    request: {
        url: `${URL_CUSTOM_ALERT}`,
        method: "post",
        data: JSON.stringify(customAlert),
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const fetchCustomAlert = (id) => ({
    type: `${FETCH_CUSTOM_ALERT}`,
    request: {
        url: `${URL_CUSTOM_ALERT}${id}`,
    },
});
export const updateCustomAlert = (
    customAlertId,
    customAlert,
    onSuccess,
    onError,
) => ({
    type: UPDATE_CUSTOM_ALERT,
    request: {
        url: `${URL_CUSTOM_ALERT}${customAlertId}`,
        method: "put",
        data: JSON.stringify(customAlert),
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
