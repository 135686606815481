import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboardWeeklyFTESaved,
} from "redux/actions/services/dashboard";
import {
 MONITORING_TIME, MONITORING_TIME_Params
} from "pages/newDashboard/dashboardUtils";

import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import FTESaved from "pages/newDashboard/DayView/FTESaved";

// const formattedData = (data) => ({
//   labels: data?.chartPeriodLabels,
//   datasets: [
//     {
//       yAxisID: "fte",
//       data: data?.hoursSaved,
//       fill: true,
//       type: "bar",
//       backgroundColor: theme.custom.color.chartColor1,
//       barThickness: 12,
//       borderRadius: 10,
//       borderColor: theme.custom.color.chartColor1,
//       borderWidth: 0,
//     },
//   ],
// });
//
// const options = (t, userFleet) => ({
//   elements: {
//     line: {
//       tension: 0,
//     },
//   },
//   legend: {
//     display: false,
//   },
//   scales: {
//     xAxes: LINE_CHART_X_AXES({ offset: true }),
//     yAxes: [
//       {
//         id: "fte",
//         display: false,
//         position: "right",
//         gridLines: {
//           drawOnChartArea: false,
//         },
//         ticks: {
//           beginAtZero: true,
//           display: false,
//         },
//         offset: true,
//       },
//     ],
//   },
//   plugins: {
//     datalabels: LINE_CHART_DATA_LABELS({ suffix: ` ${t("timeUnite.fte")}s`, format: (value) => secondsToFTPTime(value, userFleet?.hoursPerManDay, userFleet?.daysPerYear, t), formatValue: true }, false),
//   },
//   layout: {
//     padding: {
//       top: 30,
//     },
//   },
//   tooltips: {
//     ...LINE_CHART_TOOLTIP_OPTIONS,
//   },
// });

export default function WeeklyFTESaved({ userFleet }) {
  // const classes = useStyles()();
  // const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  // const [containerHeight, setContainerHeight] = useState(null);
  // const containerRef = useRef();
    const dispatch = useDispatch();
    const filterDashboard = useSelector(({ dashboardFilters }) => dashboardFilters);

    const [FTEsSavedData, setFTEsSavedData] = useState();
    const fetchWeeklyFTESaved = (withLoading = true) => {
        setIsLoading(withLoading);
        dispatch(
            fetchDashboardWeeklyFTESaved({
                ...filterDashboard
            })
        ).then((res) => {
            setFTEsSavedData(res.data);
            setIsLoading(false);
        });
    }

  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchWeeklyFTESaved, MONITORING_TIME_Params)
    return () => {
      clearInterval(fetchIntervalRef.current)
    }
  }, [filterDashboard])

    useEffect(() => {
        fetchWeeklyFTESaved()
    }, [filterDashboard]);

  // const chartData = formattedData({
  //   ...FTEsSavedData,
  //   chartPeriodLabels: chartPeriodLabels(t, filterDashboard.selectedDurationValue),
  // });
  // const chartOption = options(t, { ...userFleet, daysPerYear: chartPeriodFTE(filterDashboard.selectedDurationValue, userFleet?.daysPerYear) });
  // const headerDataList = [
  //   {
  //     label: t("Total time saved"),
  //     value: FTEsSavedData?.totalHoursSaved ? secondsToFTPTime(FTEsSavedData?.totalHoursSaved, userFleet?.hoursPerManDay, periodFTE(filterDashboard.selectedDurationValue, userFleet?.daysPerYear), t) : 0,
  //   },
  //   {
  //     label: t("average.FTEs.saved"),
  //     value: FTEsSavedData?.averageHoursSaved ? secondsToFTPTime(FTEsSavedData?.averageHoursSaved, userFleet?.hoursPerManDay, periodFTE(filterDashboard.selectedDurationValue, userFleet?.daysPerYear), t) : 0,
  //   },
  // ];
  // useEffect(() => {
  //   if (containerRef?.current) setContainerHeight(containerRef.current.clientHeight - 85);
  // }, [containerRef]);
  return (
    <FTESaved userFleet={userFleet} hoursSaved={FTEsSavedData?.totalHoursSaved} isLoading={isLoading} />

  );
}
