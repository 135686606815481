import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartStyles from "chartjs-plugin-style";
import { useTranslation } from "react-i18next";
import theme, { fontFamily } from "../../../../style/orchestratorTheme";
import { secondsToFTPTime, secondsToFTPTimeLabel } from "util";
import { LINE_CHART_TOOLTIP_OPTIONS } from "../../dashboardUtils"
import isEmpty from "lodash/isEmpty";
import DataNotFound from "../../../../components/DataNotFound";
import useStyles from "../../style";

const getData = (productivityData) => ({
  labels: productivityData?.map(({ entityName }) => entityName),
  datasets: [
    {
      data: productivityData?.map(({ values }) => values[0]?.toFixed(2)),
      backgroundColor: [
        theme.custom.color.chartColor2,
        theme.custom.color.chartColor3,
        theme.palette.primary.main,
        theme.custom.color.chartColor1,
        theme.custom.color.chartColor4,
      ],
      hoverOffset: 4,
    },
  ],
});

export const calculateMinValue = (data) => {
  const currentData = data?.isTable ? data : Object.values(data?.dataset?._meta)[0]
  const sum = currentData?.total;
  const numberOfResources = currentData?.data.filter((v) => !v.hidden).length;
  const rates = { 2: 0.15, 3: 0.2 };
  return (rates[numberOfResources] || 0.25) * sum;
}
function newLegendClickHandler(e, legendItem) {
  const { chart: ci } = this;
  const dataSets = ci.data.datasets.map((dataset, i) => ci.getDatasetMeta(i));
  const totalDisplayedSets = dataSets[0].data.filter((item) => !item.hidden).length;
  if (totalDisplayedSets > 1 || legendItem.hidden) {
    const targetItem = dataSets[0].data.find((item) => item._index === legendItem.index);
    targetItem.hidden = !legendItem.hidden;
  }
  ci.update();
}
const pieOptions = (md, ftp, t) => ({
  legend: {
    onClick: newLegendClickHandler,
    align: "start",
    position: "bottom",
    labels: {
      fontColor: theme.palette.primary.main,
      boxWidth: 13,
      boxHeight: 13,
      align: "center",
      fontFamily,
    },
  },
  layout: {
    padding: {
      top: 20,
      bottom: 20,
    },
  },
  plugins: {
    datalabels: {
      formatter: (value, chartData) => ((+value) <= calculateMinValue(chartData, value) ? "" : secondsToFTPTime((+value), md, ftp, t)),
      color: theme.custom.color.bakgroundColor,
      opacity: 1,
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => secondsToFTPTimeLabel((+data.datasets[0].data[tooltipItem.index]), md, ftp, t),
    },
    ...LINE_CHART_TOOLTIP_OPTIONS
  }
});

export default function ProductivityChart({ data, userFleet, setOpenTable }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const productivityData = getData(data);
  return (
    <>
      <Grid item container xs={12} justify="space-between" alignItems="center">
        <Grid item>
          <Typography component="span" className={classes.subtitle}>
            {t("productivity.hrs")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.seeAll} component="span" onClick={() => setOpenTable(true)}>
            {t("see.all")}
          </Typography>
        </Grid>
      </Grid>
      {isEmpty(data) || data?.every(({ values }) => values?.[0] === 0) ? (
        <DataNotFound
          backgroundColor="transparent"
          boxShadow="none"
          iconHeight="20%"
          iconWidth="20%"
          padding={50}
        />
      ) : (
        <Pie
          data={productivityData}
          options={pieOptions(userFleet?.hoursPerManDay, userFleet?.daysPerYear, t)}
          plugins={[ChartDataLabels, ChartStyles]}
          height={300}
        />
      )}
    </>
  );
}
