import React, { useEffect, useRef } from "react";
import Drawer from "@material-ui/core/Drawer";
import { Link, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import kebabCase from "lodash/kebabCase";
import { FETCH_CURRENT_USER } from "redux/constants";
import { Tooltip } from "@material-ui/core";
import { useQuery } from "@redux-requests/react";
import { toogleMenu } from "../../appSlice";
import { updateMenuLeftWidth } from "redux/slices/settingsSlice";
import { getPermissionMenu } from "./helpers.js";
import {
 reorderMenu, findIndexByKey, isEnvParamEnabled, getLocale
} from "util"
import logo from "./logo.png";
import logoReduced from "./logo-reduced.png";
import { isMenuPermitted } from "components/HasPermission";
import { ReactComponent as ToggleIcon } from "assets/navigationIcons/leftMenuIcons/toggle_icon.svg";
import { ReactComponent as ReduceIcon } from "assets/navigationIcons/leftMenuIcons/reduce_icon.svg";
import { footerMenuActions } from "../../menu";
import useStyles from "./style.js";

const MenuLeft = () => {
  const classes = useStyles();
  const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
    const menuLeftRef = useRef(null);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });
  const menu = getPermissionMenu(currentUser);
  const isCustomLogo = isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO");
  const tabValue = menu?.map(({ path }) => path.split("/")[1])?.findIndex((path) => path === pathname.split("/")[1])
  const handleLogoClick = () => {
    const permittedMenu = menu.slice(0, 2).find((menu) => isMenuPermitted(currentUser, menu.name));
    if (permittedMenu) {
      history.push(permittedMenu.path);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    scrollToTop()
  }, [tabValue])

  useEffect(() => {
    if (menuLeftRef?.current) dispatch(updateMenuLeftWidth(menuLeftRef.current.offsetWidth));
  }, [getLocale()?.code]);

  const renderMenu = (menu) => reorderMenu(menu).map(({ group, menus, index }) => (
    <Box className={!group && classes.footerMenuActionsContainer}>
      {group && (
        <Box
display="flex"
alignItems="center"
height={menuExpanded ? 16 : 10}
py={2}
                 visibility={menuExpanded ? "" : "hidden"}
key={`group-${group}-${index}`}>
          <Typography className={classes.groupTitle}>
            {t(group)}
          </Typography>
        </Box>
        )}
      {menus?.map(
            ({
               name, customLabel, icon, path, label, handleClick
             }, i) => {
              const TabIcon = icon
              const isSelected = path && tabValue === findIndexByKey(menu, path, "path");
              return (
                <Tooltip key={`menu-${name}-${i}`} title={!menuExpanded ? t(label ?? name).concat(customLabel || "") : ""} placement="right">
                  <Tab
                        key={`menu-item-${i}`}
                        id={`menu-item-${kebabCase((label ?? name).concat(customLabel || ""))}`}
                        icon={<TabIcon className={clsx(classes.tabIcon, menuExpanded && classes.tabIconExpanded, isSelected ? classes.tabIconSelected : "")} />}
                        label={menuExpanded ? t((label ?? name).concat(customLabel || "")) : ""}
                        component={Link}
                        to={path}
                        selected={isSelected}
                        className={classes.tab}
                        disableRipple
                        classes={{
                          wrapper: clsx(classes.tabWrapper, menuExpanded && classes.tabWrapperExpanded),
                          selected: isSelected && classes.tabSelected,
                        }}
                        onClick={handleClick && (() => handleClick(currentUser))}
                    />
                </Tooltip>
              )
            }
        )}
    </Box>
  ))

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.menuLeft,
          !menuExpanded && classes.menuLeftClose,
        ),
      }}
      open={menuExpanded}
    >
      <Box className={classes.menuButton} textAlign={menuExpanded ? "" : "center"}>
        <img
            src={menuExpanded ? logo : logoReduced}
            alt="Logo"
            className={clsx(isCustomLogo ? classes.riyadbanklogo : classes.logo, !menuExpanded && classes.logoReduced)}
            onClick={handleLogoClick}
        />
        <IconButton
          aria-label="open Menu"
          onClick={() => dispatch(toogleMenu())}
          className={clsx(classes.toggleButton, menuExpanded ? classes.toggleButtonOpen : classes.toggleButtonClose)}
          color="inherit"
        >
          {menuExpanded ? <ReduceIcon className={classes.toggleButtonIcon} /> : <ToggleIcon className={classes.toggleButtonIcon} />}
        </IconButton>
      </Box>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabValue}
        aria-label="Menu left"
        classes={{
          indicator: classes.indicator,
          scrollable: classes.scrollable,
        }}
        ref={menuLeftRef}
        scrollButtons="off"
        centered
      >
        <Box className={classes.menuContainer}>
          {renderMenu(menu)}
        </Box>
        <Box className={classes.footerMenu}>
          {renderMenu(footerMenuActions)}
        </Box>

      </Tabs>
    </Drawer>
  );
}

export default MenuLeft;
