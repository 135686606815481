import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  app: {
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: "1 0 auto",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    flex: "1 1 auto",
  },

  formWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 1 auto",
    marginTop: theme.spacing(2),
  },

  title: {
    margin: "20px 0 5px 0",
  },
  resetButton: {
    margin: "16px",
  },
  container: {
    justifyContent: "center",
    paddingTop: "30px",
  },
  formError: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 1 auto",
  },
  input: {
    marginButtom: "50px",
  },
  rootAppBar: {
    flexGrow: 1,
  },
  appBar: {
    borderTopLeftRadius: 5,
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
  },
  tabs: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    "& .MuiTabs-indicator": {
      backgroundColor: theme.custom.color.color6,
      height: 6,
    },
  },
  menuButton: {
    marginRight: 36,
  },
  rootGlobal: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  fleetForm: {
    width: "100%",
  },
  validation_error: {
    color: "red",
    margin: "0",
    marginTop: theme.spacing(1),
    position: "absolute",
  },
  formRow: {
    paddingBottom: theme.spacing(2),
  },
  inputsSection: {
    padding: theme.spacing(1, 2),
  },
  initialText: {
    textAlign: "initial",
  },
  root: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  tab: {
    border: `1px solid ${theme.custom.color.containersBorder}`,
  },
  // new
  profileContainer: {
    padding: theme.spacing(1, 3, 3, 3),
  },
  profileSectionContainer: {
    backgroundColor: theme.palette.background.default,
  },
  profileUserContainer: {
    padding: theme.spacing(0, 3),
    backgroundColor: theme.palette.background.default,
  },
  pictureSectionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.custom.color.containersBorder}`,
    padding: theme.spacing(0, 3),
  },
  profileSection: {
    position: "relative",
    padding: "20px 35px 35px",
    border: `1px solid ${theme.custom.color.containersBorder}`,
  },
  profileTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: 800
  },
  profileSectionTitle: {
    backgroundColor: theme.palette.background.default,
    position: "absolute",
    top: -17,
    left: 25,
    color: theme.palette.primary.main,
    fontSize: "1.3rem",
    fontWeight: 600,
    padding: "0 10px"
  },
  textField: {
    marginTop: 15
  },
  selectField: {
    marginTop: 11
  }
}));
