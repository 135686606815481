import { makeStyles } from "@material-ui/core/styles";
import theme from "style/orchestratorTheme";

export default makeStyles(() => ({
  xlsFile: {
    fontSize: "25px",
    color: theme.custom.color.color1,
    marginLeft: theme.spacing(1.5),
  },
  refreshBtn: {
    fontSize: "0.85rem",
  },
  refreshIcon: {
    color: theme.palette.primary.main,
  },
  nodataIcon: {
    fontSize: "80px",
    color: theme.palette.primary.main
  },
  deleteIcon: {
    color: theme.custom.color.icons.delete,
  },
  fileContainer: {
    backgroundColor: theme.custom.color.bakgroundColor,
    margin: "10px 0px 10px 0px",
  },
  fileName: {
    fontSize: "1rem",
    color: theme.palette.primary.main,
    fontWeight: "725",
    marginLeft: theme.spacing(1),
  },
  finishedStatus: {
    color: theme.custom.color.done,
    backgroundColor: theme.custom.color.doneBackground,
    borderColor: theme.custom.color.done,
  },
  collectingStatus: {
    color: theme.custom.color.pending,
    backgroundColor: theme.custom.color.pendingBackground,
    borderColor: theme.custom.color.pending,
  },
  exportingStatus: {
    color: theme.custom.color.exporting,
    backgroundColor: theme.custom.color.exportingBackground,
    borderColor: theme.custom.color.exporting,
  },
  defaultStatus: {
    color: theme.custom.color.unknown,
    backgroundColor: theme.custom.color.unknownBackground,
    borderColor: theme.custom.color.unknown,
  },
  status: {
    borderWidth: "0.15rem",
    fontSize: "0.8rem",
    fontWeight: "700",
    textTransform: "uppercase",
    maxWidth: "fit-content",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "700",
    color: theme.palette.primary.main,
  },
  closeIcon: {
    color: theme.custom.color.icons.delete,
    marginTop: theme.spacing(-0.7),
  },
  dialogPaper: {
    minWidth: "40%",
    padding: "0",
  },
  refreshButtonText: {
    fontSize: "0.9rem",
    fontWeight: "700",
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  dialogContent: {
    margin: theme.spacing(2),
  },
  dialogHeader: {
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));
