import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import Typography from "@material-ui/core/Typography";
import { headerSubMenus } from "util";
import clsx from "clsx";
import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import { toast } from "react-toastify";
import {
 fetchCurrentUser, fetchExportedFiles, logout, updateProfile
} from "../../../../redux/actions/services";
import UserProfile from "../../../../assets/navigationIcons/account.svg";
import GetAppIcon from "@material-ui/icons/GetAppOutlined";
import ExportDialog from "WrapperContainer/components/ExportDialog";
import { FETCH_CURRENT_USER, FETCH_EXPORTED_FILES } from "redux/constants";
import { useQuery } from "@redux-requests/react";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { headerMenus } from "WrapperContainer/menu";
import { getPermissionMenu } from "../../MenuLeft/helpers";
import useStyles from "../style.js";
import supportedLanguages from "components/Language/supportedLanguages";
import { ReactComponent as ToggleIcon } from "assets/navigationIcons/leftMenuIcons/toggle_icon.svg";
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  isSticky, nbrExeptions, dark,
}) => {
  const settings = useSelector(({ settings }) => settings);
  const classes = useStyles({ menuLeftWidth: settings.menuLeftWidth });
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const activeMenu = pathname?.split("/")?.[1];
  const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [anchorElLang, setAnchorElLang] = useState(null);
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const [highlightExport, setHighlightExport] = useState(false);
  const getImageURL = (path) => `${process.env.REACT_APP_DOMAIN}api/admin${path}`;
  const getUserInitials = () => {
    const f = get(currentUser, "firstName")
      ? currentUser.firstName.charAt(0).toUpperCase()
      : "";
    const l = get(currentUser, "lastName")
      ? currentUser.lastName.charAt(0).toUpperCase()
      : "";

    return f + l;
  };

  const alertsColor = (nbrExeptions) => {
    if (nbrExeptions < 11) {
      return classes.alertLow;
    }
    if (nbrExeptions < 20) {
      return classes.alertMeduim;
    }
    return classes.alertHigh;
  };
  const colorExeptions = alertsColor(nbrExeptions);
  const { t } = useTranslation();
  const location = useLocation();
  const [exportOpen, setExportOpen] = React.useState(false);
  const exportedFiles = useQuery({ type: FETCH_EXPORTED_FILES }).data;
  const [numberOfExport, setNumberOfExport] = React.useState(0);
  const menu = getPermissionMenu(currentUser, headerMenus);

  const onExportedFileOpen = () => {
    setExportOpen(true);
    dispatch(fetchExportedFiles(() => setHighlightExport(false)));
  }

  React.useEffect(() => {
    if (exportedFiles?.length > numberOfExport) {
      setHighlightExport(true);
    }
    setNumberOfExport(exportedFiles?.length);
  }, [exportedFiles?.length]);

  const openProfile = () => {
    history.push("/profile");
    setAnchorEl(null);
  }

  const onSaveError = () => {
    toast.error(t("Unexpected Error"));
  }
  const changeLanguage = (language, setAnchorLangEl) => {
    const dataToSubmit = {
      ...currentUser,
      local: language
    }
    const onSaveSuccess = () => {
      dispatch(fetchCurrentUser());
      setAnchorLangEl(null);
      i18n.changeLanguage(language);
      localStorage.setItem("lgn", language);
    }
    dispatch(updateProfile(dataToSubmit, null, onSaveSuccess, onSaveError));
  };

  const AvatarComponent = (isSubMenuItem) => (
    <Box component={isSubMenuItem ? "div" : IconButton} onClick={(e) => !isSubMenuItem && setAnchorEl(e.currentTarget)} className={classes.iconsButtons}>
      <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
        >
        <Avatar
              alt="Supplier logo"
              src={
                get(currentUser, "logo")
                    ? getImageURL(get(currentUser, "logo"))
                    : UserProfile
              }
              className={clsx(classes.purple, isSubMenuItem && classes.subMenuAvatar)}
          >
          {getUserInitials()}
        </Avatar>
      </Badge>
    </Box>
  )

  return (
    <AppBar
      color="inherit"
      position="sticky"
      className={clsx(
        classes.appBar,
        menuExpanded && classes.appBarShift,
        dark && classes.dark,
        location.pathname === "/404" && classes.withourAppBar,
      )}
      elevation={0}
    >
      <Toolbar className={classes.toolbar}>
        <Box display="flex" height="100%">
          {headerSubMenus?.[activeMenu] && headerSubMenus?.[activeMenu]?.items?.map((item, index) => (
            <Box
                      className={clsx(classes.subMenuItem, item?.path === pathname && classes.subMenuItemActive)}
                      onClick={() => history.push(item?.path)}
                      key={index}
                  >
              <Typography>
                {t(item.label)}
              </Typography>
            </Box>
              ))}
        </Box>
        <Box mx={2} display="flex" justifyContent="flex-end">
          <Tooltip title={t("exporting.title")}>
            <IconButton onClick={onExportedFileOpen} className={classes.iconsButtonsHover}>
              <Badge
                    badgeContent={exportedFiles?.length > 0 ? exportedFiles?.length : 0}
                    classes={{ badge: clsx(highlightExport ? classes.badgeStyled : colorExeptions, classes.exceptionBadge) }}
                >
                <GetAppIcon color="primary" fontSize="24px" />
              </Badge>
            </IconButton>
          </Tooltip>
          {isSticky && (
            <hr className={classes.darkDivider} />
            )}
          {isSticky && menu?.length && menu.length > 0 && (
            <>
              <IconButton onClick={(e) => setAnchorElSettings(e.currentTarget)} className={classes.iconsButtonsHover}>
                <SettingsRoundedIcon className={classes.settingsIcon} />
              </IconButton>
              <Popover
                      id="setting-popover"
                      open={Boolean(anchorElSettings)}
                      anchorEl={anchorElSettings}
                      onClose={() => setAnchorElSettings(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{ paper: clsx(classes.menuPaper, classes.subMenuPaper) }}
                      elevation={0}
                  >
                {menu?.map(({ name, label, path }, index) => (
                  <ListItemText
                            key={index}
                            primary={t(label ?? name)}
                            classes={{
                              primary: clsx(classes.popupItems, history?.location?.pathname?.includes(path) && classes.popupItemsActive),
                            }}
                            onClick={() => {
                              history.push(path)
                              setAnchorElSettings(null)
                            }}
                        />
                    ))}
              </Popover>
            </>
            )}
          {isSticky && (
            <>
              <Tooltip title={t("Profile")}>
                {AvatarComponent()}
              </Tooltip>
              <Popover
                      id="profile-popover"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{ paper: clsx(classes.menuPaper, classes.menuPaperProfile) }}
                      elevation={0}
                  >
                <Box display="grid" gridTemplateColumns="31px 1FR" gridColumnGap="10px" px={1} alignItems="center" mb="5px">
                  {AvatarComponent(true)}
                  <Box>
                    <Typography className={classes.nameItem}>
                      {`${get(currentUser, "firstName")} ${get(currentUser, "lastName")}`}
                    </Typography>
                    <Typography className={classes.emailItem}>
                      {get(currentUser, "email")}
                    </Typography>
                  </Box>
                </Box>
                <ListItemText
                          primary={t("Personal settings")}
                          classes={{
                            primary: classes.popupItems,
                          }}
                          onClick={openProfile}
                      />
                <ListItemText
                    primary={(
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography color="inherit">{t("language")}</Typography>
                        <ToggleIcon className={classes.popupItemIcon} />
                      </Box>
              )}
                    classes={{
                      primary: classes.popupItems,
                    }}
                    onClick={(e) => setAnchorElLang(e.currentTarget)}
                />
                <ListItemText
                          primary={t("Logout")}
                          classes={{
                            primary: classes.popupItems,
                          }}
                          onClick={() => dispatch(logout())}
                      />
              </Popover>
              <Popover
                      id="langauge-popover"
                      open={Boolean(anchorElLang)}
                      anchorEl={anchorElLang}
                      onClose={() => setAnchorElLang(null)}
                      anchorOrigin={{
                        vertical: 5,
                        horizontal: -16,
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                      }}
                      classes={{ paper: classes.menuPaper }}
                      marginThreshold={0}
                      elevation={0}
                  >
                {supportedLanguages.map(({ key, text }) => (
                  <ListItemText
                        key={key}
                        primary={text}
                        classes={{
                          primary: clsx(classes.popupItems, key === i18n.language && classes.popupItemsActive),
                        }}
                        onClick={() => changeLanguage(key, setAnchorElLang)}
                    />
                ))}
              </Popover>
            </>
            )}
        </Box>
      </Toolbar>
      <ExportDialog setExportOpen={setExportOpen} exportOpen={exportOpen} />
    </AppBar>
  );
}
