import { createSlice } from "@reduxjs/toolkit";

const initialFilter = {
  search: "",
  fleet: [],
  tags: [],
  showAll: false,
  pageNo: 0,
  pageSize: 10,
  sortBy: "recentActivity,DESC",
  status: [],
  division: [],
};
const filterListProcess = createSlice({
  name: "filterProcess",
  initialState: initialFilter,
  reducers: {
    updateFilter: (state, action) => (state = action.payload), // eslint-disable-line
    initFilter: (state) => (state = initialFilter), // eslint-disable-line
  },
});

export const { updateFilter, initFilter } = filterListProcess.actions;

export default filterListProcess.reducer;
