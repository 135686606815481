import React, { useEffect, useMemo, useState } from "react";
import CustomDialog from "components/CustomDialog";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { toast } from "react-toastify";
import unset from "lodash/unset";
import i18n from "../../../../../i18n";
import format from "date-fns/format";
import { Controller, useForm } from "react-hook-form";
import {
  addPermissionsRole, fetchPermissionsRole,
  updatePermissionsRole, fetchFleetsForFilter,
} from "redux/actions/services";
import { useDispatch } from "react-redux";
import CustomAutoComplete, {
  CustomTextField,
} from "components/FormFields/CustomAutoComplete";
import CustomButton from "components/CustomButton";
import CircularLoader from "components/Loaders/CircularLoader";
import EnhancedTableHead from "components/DataTable/EnhancedTableHead";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import { getDatePattern } from "util/helpers/date.helpers";
import CustomTableRow from "components/TableComponents/CustomTableRow";
import CustomTableCell from "components/TableComponents/CustomTableCell";
import PermissionManagement from "pages/Administration/PermissionsManagement/PermissionsManagement";
import CustomViewIcon from "components/TableComponents/CustomActions/CustomViewIcon";
import useStyles from "../style";

export default function RoleForm({ open, handleClose, currentRole }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { language } = i18n;
  const [userFleet, setUserFleet] = useState();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [openPermissionsTemplate, setOpenPermissionsTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    unregister,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
    clearErrors,
        trigger
  } = useForm({
    defaultValues: {},
  });

  const headCells = [
    { id: "Interfaces", label: t("Interfaces") },
    { id: "Creation Date", label: t("Creation date") },
    { id: "Last modified date", label: t("Last update") },
    { id: "actions", label: t("Actions") },
  ];

  const CloseCleanUp = () => {
    unregister();
    reset();
    clearErrors();
    setIsSubmitLoading(false);
    setIsNameError(false);
  };
  const onSuccess = () => {
    setIsSubmitLoading(false);
    toast.success(t("changes.saved.successfully"));
    CloseCleanUp();
    handleClose();
  };
  const onError = (data) => {
    if (data?.response?.data?.title === "unique.name.constraint") {
      toast.error(t("A role with the same already exists"))
      setIsNameError(true);
      trigger("name");
    } else {
      toast.error(t("Unexpected Error"));
    }
    setIsSubmitLoading(false);
  };

  const onSubmit = async (data) => {
    const tempData = {
      ...data,
      fleets: data?.fleets?.map(({ id, label }) => ({
        id,
        label,
      })),
    };
    unset(tempData, "createdAt");
    unset(tempData, "updatedAt");
    if (!currentRole) {
      dispatch(addPermissionsRole(tempData, onSuccess, onError));
    } else {
      dispatch(updatePermissionsRole(tempData, onSuccess, onError));
    }
  };

  const getPermissionsInterfaces = (permissions) => {
      const smartRobotPermissions = permissions?.find((module) => module?.name === "Smart Robot");
      const smartRobotTotalInterfaces = smartRobotPermissions?.modules?.length;
      const smartRobotEnabledInterfaces = smartRobotPermissions?.modules?.filter((permission) => permission?.enabled)?.length;
      return `${smartRobotEnabledInterfaces}/${smartRobotTotalInterfaces}`;
  }

  const handleTemplateSave = (permissions) => {
      setValue("permissions", JSON.stringify(permissions));
      clearErrors("permissions");
      if (!getValues("createdAt")) setValue("createdAt", format(new Date(), getDatePattern(language, false)))
      setValue("updatedAt", format(new Date(), getDatePattern(language, false)));
      setValue("interfaces", getPermissionsInterfaces(permissions));
      setOpenPermissionsTemplate(false);
  }

  const showPermissionsTemplate = useMemo(() => !!getValues("permissions"), [getValues("permissions")]);

  const onFetchCurrentRuleSuccess = (res) => {
    const tempRole = res?.data;
    if (!(userFleet?.find(({ id }) => tempRole?.fleets?.map(({ id }) => id).includes(id))) && !getValues("fleets")) setUserFleet((prevState) => ([...prevState, ...tempRole.fleets]))
    setValue("id", tempRole?.id);
    setValue("name", tempRole?.name);
    setValue("description", tempRole?.description);
    setValue("fleets", userFleet?.filter(({ id }) => tempRole?.fleets?.map(({ id }) => id).includes(id)));
    setValue("permissions", tempRole?.permissions);
    setValue("createdAt", format(new Date(tempRole?.createdAt), getDatePattern(language, false)));
    setValue("updatedAt", format(new Date(tempRole?.updatedAt), getDatePattern(language, false)));
    setValue("interfaces", tempRole?.interfaces);
    setIsLoading(false);
  }

  const onFetchFixedRule = () => {
    setValue("id", currentRole?.id);
    setValue("isFixed", true);
    setValue("name", currentRole?.name);
    setValue("description", currentRole?.description);
    setValue("permissions", currentRole?.permissions);
    setValue("createdAt", null);
    setValue("updatedAt", null);
    setValue("interfaces", getPermissionsInterfaces(JSON.parse(currentRole?.permissions)));
  }

  const onFetchCurrentRuleError = () => {
    setIsLoading(false);
    toast.error(t("Unexpected Error"));
    handleClose();
  };

  const registerPermissionsValidation = () => {
    register("permissions", {
      validate: (value) => value?.length > 0 || t("Permissions are required")
    })
  };

  useEffect(() => {
    registerPermissionsValidation();

    dispatch(fetchFleetsForFilter()).then((res) => {
      setUserFleet(res.data);
    });
    return () => {
      CloseCleanUp();
    }
  }, []);

  useEffect(() => {
    if (currentRole && userFleet) {
      if (currentRole?.isFixed) onFetchFixedRule()
      else {
        setIsLoading(true);
        dispatch(
            fetchPermissionsRole(
                currentRole?.id,
                onFetchCurrentRuleSuccess,
                onFetchCurrentRuleError
            )
        );
      }
    }
  }, [currentRole, userFleet]);

  return (
    <>
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxHeight="99%"
        width={660}
        fullWidth
        open={open}
        disableBackdropClick
        PaperProps={{
          square: true,
        }}
        title={t("Role Configuration")}
        actionElements={[
          {
            label: t("Cancel"),
            onClick: handleClose,
            color: "cancelModal",
            disabled: isSubmitLoading,
          },
          {
            label: currentRole ? t("Update Role") : t("Create Role"),
            view: "primary",
            type: "submit",
            isLoading: isSubmitLoading,
            disabled: isSubmitLoading || isLoading,
            hidden: currentRole?.isFixed,
          },
        ]}
        formProps={{
          onSubmit: handleSubmit(onSubmit),
        }}
        borderRadius={6}
      >
        {isLoading ? (
          <CircularLoader height={400} />
        ) : (
          <>
            <Box>
              <Box mb="10px">
                <CustomTextField
                      {...register("name", {
                        required: t("field.is.required", {
                          field: t("Role Name"),
                        }),
                        validate: !isNameError
                      })}
                      valued={getValues("name")}
                      label={`${t("Role Name")} *`}
                      error={!!errors.name?.message}
                      helperText={errors.name && errors.name?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: !!watch("name"),
                      }}
                  />
              </Box>
              <Box mb="10px">
                <CustomTextField
                      {...register("description", {
                        required: t("field.is.required", {
                          field: t("Description"),
                        }),
                      })}
                      valued={getValues("description")}
                      label={`${t("Description")} *`}
                      error={!!errors.description?.message}
                      helperText={errors.description && errors.description?.message}
                      fullWidth
                      InputLabelProps={{
                        shrink: !!watch("description"),
                      }}
                  />
              </Box>
              {!currentRole?.isFixed && (
              <FormControl fullWidth margin="none">
                <Controller
                        control={control}
                        name="fleets"
                        render={(field) => (
                          <CustomAutoComplete
                                {...field}
                                multiple
                                label={t("Apply to this group")}
                                optionLabel="companyName"
                                options={userFleet ?? []}
                                value={userFleet?.filter(({ id }) => getValues("fleets")?.map(({ id }) => id).includes(id))}
                                onChange={(value) => {
                                  setValue("fleets", value);
                                }}
                                disableClearable
                            />
                        )}
                    />
              </FormControl>
              )}
            </Box>
            <Box className={classes.sectionContainer}>
              <Box className={classes.sectionTitle}>
                <Typography className={classes.sectionTitleText}>
                  {t("user.add.permissions")}
                </Typography>
              </Box>
              {!showPermissionsTemplate && (
                <CustomButton
                        view="primary"
                        onClick={() => setOpenPermissionsTemplate(true)}
                        startIcon={<AddIcon />}
                        className={classes.selectPermissionButton}
                    >
                  {t("Select Permissions")}
                </CustomButton>
                )}
              {showPermissionsTemplate && (
                <TableContainer className={classes.infoTableContainer}>
                  <Table
                          stickyHeader
                          aria-labelledby="tableTitle"
                          size="medium"
                          aria-label="enhanced table"
                          width="100%"
                      >
                    <EnhancedTableHead
                            numSelected={0}
                            headCells={headCells}
                            paddingLeft={23}
                            height={40}
                        />
                    <TableBody>
                      <CustomTableRow>
                        <CustomTableCell align="left" width={150}>
                          <Typography
                                  variant="caption"
                                  className={classes.tableCellText}
                              >
                            {getValues("interfaces")}
                          </Typography>
                        </CustomTableCell>
                        <CustomTableCell align="left">
                          <Typography
                                  variant="caption"
                                  className={classes.tableCellText}
                              >
                            {getValues("createdAt") ?? "--"}
                          </Typography>
                        </CustomTableCell>
                        <CustomTableCell align="left">
                          <Typography
                                  variant="caption"
                                  className={classes.tableCellText}
                              >
                            {getValues("updatedAt") ?? "--"}
                          </Typography>
                        </CustomTableCell>
                        <CustomTableCell align="center" width={100}>
                          {currentRole?.isFixed ? (
                            <CustomViewIcon
                                  onClick={() => setOpenPermissionsTemplate(true)}
                                  size="small"
                              />
                          ) : (
                            <CustomEditIcon
                                    onClick={() => setOpenPermissionsTemplate(true)}
                                    size="small"
                                />
                          )}

                        </CustomTableCell>
                      </CustomTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                )}
            </Box>
            <Typography color="error" variant="caption">
              {errors?.permissions?.message}
            </Typography>
          </>
        )}

      </CustomDialog>
      {openPermissionsTemplate
            && (
            <PermissionManagement
                    dispatch={dispatch}
                    setIsOpen={setOpenPermissionsTemplate}
                    isOpen={openPermissionsTemplate}
                    handleTemplateSave={handleTemplateSave}
                    isTemplate
                    row={watch()}
                    fetchEntities={() => ""}
                />
            )}
    </>
  );
}
