import React from "react";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";

const generateAlertColor = (severity, theme) => {
    let alertPalette;
    switch (severity) {
        case "error":
            alertPalette = {
                backgroundColor: theme.custom.color.alert.errorBg,
                color: theme.custom.color.alert.errorText,
            };
            break;
        case "info":
            alertPalette = {
                backgroundColor: theme.custom.color.alert.infoBg,
                color: theme.custom.color.alert.infoText,
            };
            break;
        default:
            alertPalette = {
                backgroundColor: theme.custom.color.alert.defaultBg,
                color: theme.custom.color.alert.defaultText,
            };
    }
    return alertPalette;
};

const CustomStyledAlert = withStyles((theme) => ({
    root: (props) => ({
        ...generateAlertColor(props?.severity, theme),
        margin: theme.spacing(2, 0),
        fontSize: "0.9rem",
        fontWeight: 500,
        borderRadius: "7px",
        width: "100%"
    }),
}))(Alert);
const CustomAlert = ({ children, severity, alertMsg }) => (
  <CustomStyledAlert severity={severity} icon={false}>
    {alertMsg}
    {children}
  </CustomStyledAlert>
    );

export default CustomAlert;
