import {
  React, useCallback, useEffect, useMemo, useState
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { debounce, isEmpty, isEqual } from "lodash";
import { formatImagePath } from "util/index";
import {
 addToSelected, removeFromAvailable, selectAll, selectItem
} from "util/helpers/dragDropSelctors.helpers";
import EmptyDropSection from "../../../components/EmptyDropSection";
import Pagination from "../../../components/SelectorPagination";
import SearchField from "../../../components/EmptyDropSection/SearchField";
import DataNotFound from "../../../components/DataNotFound";
import CustomButton from "components/CustomButton";
import InformationIcon from "components/InformationIcon";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"
import useStyles from "./style.js";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: `${grid}px ${grid * 2}px`,
  margin: `0 0 ${grid}px 0`,
  boxShadow:
      "0px 1px 2px 0px rgb(172,186,255)",
  fontWeight: 400,
  fontSize: 14,
  borderRadius: "2px",
  // change background colour if dragging
  background: "#FFFFFF",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ProcessSelector = ({
  availableProcesses,
  selectedProcesses,
  isDisabled,
  setAvailableProcesses,
  setSelectedProcesses,
  onAssignProcess,
  onUnAssignProcess,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [availableSearchTerm, setAvailableSearchTerm] = useState("")
  const [assignedSearchTerm, setAssignedSearchTerm] = useState("")
  const [checkedProcesses, setCheckedProcesses] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [checkedAssignedItems, setCheckedAssignedItems] = useState([]);
  const itemsPerPage = 10;
  const getList = (id) => (id === "droppable" ? availableProcesses : selectedProcesses);
  const [currentPages, setCurrentPages] = useState({
    availableProcesses: {
      currentPage: 1,
      totalPages: Math.ceil(availableProcesses.length / itemsPerPage),
    },
    selectedItems: {
      currentPage: 1,
      totalPages: Math.ceil(selectedProcesses.length / itemsPerPage),
    },
  });
  const indexOfLastItemProcesses = currentPages.availableProcesses.currentPage * itemsPerPage;
  const indexOfFirstItemProcesses = indexOfLastItemProcesses - itemsPerPage;
  const sortProcessesList = (list) => list?.map((item, i) => ({ ...item, originalIndex: i })).filter((item) => (availableSearchTerm.trim() !== "" ? (item?.processName?.toLowerCase()?.includes(availableSearchTerm.trim()) || item?.processDescription?.processDisplayName?.toLowerCase()?.includes(availableSearchTerm.trim())) : true))

  const pagedAvailableProcesses = useMemo(() => sortProcessesList(availableProcesses)?.slice(indexOfFirstItemProcesses, indexOfLastItemProcesses), [availableProcesses, currentPages?.availableProcesses?.currentPage, availableSearchTerm])
  useEffect(() => {
    setCurrentPages((prevState) => ({ ...prevState, availableProcesses: { ...prevState?.availableProcesses, ...(availableSearchTerm ? { currentPage: 1 } : {}), totalPages: Math.ceil(sortProcessesList(availableProcesses).length / itemsPerPage) } }));
  }, [availableProcesses, availableSearchTerm]);
  const indexOfLastItemSelectedItems = currentPages.selectedItems.currentPage * itemsPerPage;
  const indexOfFirstItemSelectedItems = indexOfLastItemSelectedItems - itemsPerPage;
  const sortSelectedList = (list) => list?.map((item, i) => ({ ...item, originalIndex: i })).filter((item) => (assignedSearchTerm.trim() !== "" ? item?.processName?.toLowerCase()?.includes(assignedSearchTerm.trim()) || item?.processDescription?.processDisplayName?.toLowerCase()?.includes(assignedSearchTerm.trim()) : true))
  const pagedSelectedProcesses = useMemo(() => sortSelectedList(selectedProcesses)?.slice(indexOfFirstItemSelectedItems, indexOfLastItemSelectedItems), [checkedAssignedItems, currentPages?.selectedItems?.currentPage, assignedSearchTerm]);
  useEffect(() => {
    setCurrentPages((prevState) => ({ ...prevState, selectedItems: { ...prevState?.selectedItems, totalPages: Math.ceil(sortSelectedList(selectedProcesses).length / itemsPerPage) } }));
  }, [selectedProcesses, assignedSearchTerm]);

  useEffect(() => {
    setSelectedItems([...(selectedProcesses ?? [])]);
    setCurrentPages((prevState) => ({ ...prevState, selectedItems: { ...prevState?.selectedItems, totalPages: Math.ceil(selectedItems.length / itemsPerPage) } }));
  }, [selectedProcesses, availableProcesses]);

  const handleSearch = useCallback(debounce((searchTerm) => {
    setSelectedItems([...(selectedProcesses ?? [])]?.map((item, i) => ({ ...item, i })).filter((item) => (searchTerm.trim() !== "" ? item?.processName?.toLowerCase()?.includes(searchTerm.trim()) || item?.processDescription?.processDisplayName?.toLowerCase()?.includes(searchTerm.trim()) || item?.name?.toLowerCase()?.includes(searchTerm.trim()) : true)))
  }, 300), []);

  const validateDragDrop = (
    source,
    destination,
    droppableSource,
    droppableDestination,
  ) => {
    const sourceClone = Array.from(source);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    if (droppableDestination.droppableId === "droppable") {
      if (onUnAssignProcess) return onUnAssignProcess(removed);
    } else if (onAssignProcess) return onAssignProcess(removed);
    return true;
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sourceList = source.droppableId === "droppable" ? pagedAvailableProcesses : pagedSelectedProcesses;
    const destinationList = destination.droppableId === "droppable" ? pagedAvailableProcesses : pagedSelectedProcesses;

    const adjustedSourceIndex = sourceList[source.index]?.originalIndex;
    const adjustedDestinationIndex = destinationList[destination.index]?.originalIndex || destinationList[destination.index - 1]?.originalIndex;

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        adjustedSourceIndex,
        adjustedDestinationIndex
      );

      // let state = { items };
      if (source.droppableId === "droppable2") {
        setSelectedProcesses(items);
      } else {
        setAvailableProcesses(items);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        { ...source, index: adjustedSourceIndex },
        { ...destination, index: adjustedDestinationIndex }
      );
      setAvailableProcesses(result.droppable);
      setSelectedProcesses(result.droppable2);

      const res = await validateDragDrop(
        getList(source.droppableId),
        getList(destination.droppableId),
        { ...source, index: adjustedSourceIndex },
        { ...destination, index: adjustedDestinationIndex }
      );
      if (!res) {
        setAvailableProcesses(result.droppable);
        setSelectedProcesses(result.droppable2);
      }
    }
  };
  const isBothEmpty = isEmpty(selectedProcesses) && isEmpty(pagedAvailableProcesses);
  const handleUnassignProcesses = () => {
    setSelectedProcesses((prevProcesses) => removeFromAvailable(prevProcesses, checkedAssignedItems));
    setAvailableProcesses((prevAvailableProcesses) => [...prevAvailableProcesses, ...checkedAssignedItems]);
    setCheckedAssignedItems([]);
  };
  const handleAssignProcesses = () => {
    setSelectedProcesses((prevSelectedProcesses) => addToSelected(prevSelectedProcesses, checkedProcesses));
    setAvailableProcesses((prevAvailableProcesses) => removeFromAvailable(prevAvailableProcesses, checkedProcesses));
    setCheckedProcesses([]);
  };

  useEffect(() => {
    setCheckedProcesses(checkedProcesses.filter((process) => !pagedSelectedProcesses.includes(process)));
    setCheckedAssignedItems(checkedAssignedItems?.filter((item) => !pagedAvailableProcesses.includes(item)));
  }, [selectedProcesses]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid item container xs={12}>
        <Grid
          item
          container
          className={clsx(classes.dropabbleTitle, classes.processContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("processesSelector.availableProcess")}</Typography>
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid
          item
          container
          className={clsx(classes.dropabbleTitle, classes.processContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("processesSelector.assignedProcess")}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <SearchField
              searchTerm={availableSearchTerm}
              setSearchTerm={setAvailableSearchTerm}
              placeholder={t("search.available.processes")}
              disabled={isEmpty(availableProcesses)}
              justify="flex-start"
          />
          <SearchField
              searchTerm={assignedSearchTerm}
              setSearchTerm={(searchTerm) => {
                setAssignedSearchTerm(searchTerm);
                handleSearch(searchTerm);
              }}
              placeholder={t("search.assigned.processes")}
              disabled={isEmpty(selectedProcesses)}
              justify="flex-end"
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item className={classes.processContainer}>
          {!isDisabled && (
          <FormControlLabel
                  control={
                    <Checkbox
                        checked={isEqual(availableProcesses, checkedProcesses) && checkedProcesses?.length}
                        onChange={(e) => selectAll(e, availableProcesses, setCheckedProcesses)}
                        disabled={isEmpty(pagedAvailableProcesses)}
                    />
                  }
                  label={
                    <Typography className={classes.checkBoxLabel}>
                      {t("select.all")}
                    </Typography>
                  }
                  className={classes.checkboxContainer}
              />)}
          <Card className={classes.card}>
            <Droppable droppableId="droppable" className={classes.droppableContainer}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isBothEmpty ? <DataNotFound iconHeight={40} />
                  : isEmpty(pagedAvailableProcesses)
                          ? <EmptyDropSection />

                  : pagedAvailableProcesses?.map((item, i) => ({ ...item, i })).filter((item) => (availableSearchTerm.trim() !== "" ? item?.processDescription?.processDisplayName?.toLowerCase()?.includes(availableSearchTerm.trim()) : true))?.map((item) => (
                    <Draggable
                      key={`available-process-draggable-${item.id}`}
                      draggableId={`available-process-draggable-${item.id}`}
                      index={item.i}
                      isDragDisabled={isDisabled}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          style={{ background: "none", boxShadow: "none" }}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            <Box display="flex" alignItems="center" gridColumnGap={10}>
                              <Box>
                                {item?.icon ? (
                                  <img
                                        src={formatImagePath(item?.icon)}
                                        align="left"
                                        alt="Service"
                                        className={classes.processAvatar}
                                    />
                                ) : (
                                  <ProcessDefaultIcon className={classes.processAvatar} />
                                )}
                              </Box>
                              <Box>
                                {!isDisabled && (
                                <Checkbox
                                        checked={checkedProcesses?.some((elt) => elt?.id === item?.id)}
                                        onChange={(e) => selectItem(e, item, checkedProcesses, setCheckedProcesses)}
                                />)}
                              </Box>
                              <Box>
                                <InformationIcon
                                    titleContent={item?.processDescription?.processDisplayName}
                                    originalContent={(
                                      <Box component="span">
                                        {item?.processDescription?.processDisplayName}
                                      </Box>
                                    )}
                                />
                              </Box>
                            </Box>
                          </div>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {currentPages?.availableProcesses && (
            <Pagination listkey="availableProcesses" currentPages={currentPages} setCurrentPages={setCurrentPages} />
            )}
          </Card>
          {!isDisabled && (
          <CustomButton
                  view="outlinedPrimary"
                  onClick={handleAssignProcesses}
                  disabled={isEmpty(checkedProcesses)}
                  className={classes.assigningBtns}
                  fullWidth
              >
            {t("assign")}
          </CustomButton>)}
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid item container className={classes.processContainer}>
          {!isDisabled && (
          <FormControlLabel
                  control={
                    <Checkbox
                        checked={isEqual(selectedProcesses, checkedAssignedItems) && checkedAssignedItems?.length}
                        onChange={(e) => selectAll(e, selectedProcesses, setCheckedAssignedItems)}
                        disabled={isEmpty(pagedSelectedProcesses)}
                    />
                  }
                  label={
                    <Typography className={classes.checkBoxLabel}>
                      {t("select.all")}
                    </Typography>
                  }
                  className={classes.checkboxContainer}
              />)}
          <Card className={classes.card}>
            <Droppable droppableId="droppable2" className={classes.droppableContainer}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isBothEmpty ? <DataNotFound iconHeight={40} />
                  : isEmpty(pagedSelectedProcesses) ? (
                    <EmptyDropSection />
                  )
                      : (
                      pagedSelectedProcesses?.map((item, i) => ({ ...item, i })).filter((item) => (assignedSearchTerm.trim() !== "" ? item?.processDescription?.processDisplayName?.toLowerCase()?.includes(assignedSearchTerm.trim()) : true))?.map((item) => (
                        <Draggable
                              key={`selected-process-draggable-${item.id}`}
                              draggableId={`selected-process-draggable-${item.id}`}
                              index={item.i}
                              isDragDisabled={isDisabled}
                          >
                          {(provided, snapshot) => (
                            <Paper style={{ background: "none", boxShadow: "none" }}>
                              <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                      )}
                                  >
                                <Box display="flex" alignItems="center" gridColumnGap={10}>
                                  {item?.icon ? (
                                    <img
                                          src={formatImagePath(item?.icon)}
                                          align="left"
                                          alt="Service"
                                          className={classes.processAvatar}
                                      />
                                  ) : (
                                    <ProcessDefaultIcon className={classes.processAvatar} />
                                  )}
                                  {!isDisabled && (
                                  <Checkbox
                                          checked={checkedAssignedItems?.some((elt) => elt?.id === item?.id)}
                                          onChange={(e) => selectItem(e, item, checkedAssignedItems, setCheckedAssignedItems)}
                                      />)}
                                  <InformationIcon
                                      titleContent={item?.processDescription?.processDisplayName}
                                      originalContent={(
                                        <Box component="span">
                                          {item?.processDescription?.processDisplayName}
                                        </Box>
                                      )}
                                  />
                                </Box>
                              </div>
                            </Paper>
                            )}
                        </Draggable>
                      ))
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {currentPages?.selectedItems && (
            <Pagination listkey="selectedItems" currentPages={currentPages} setCurrentPages={setCurrentPages} />
            )}
          </Card>
          {!isDisabled && (
          <CustomButton
                  view="outlinedSecondary"
                  onClick={handleUnassignProcesses}
                  disabled={isEmpty(checkedAssignedItems)}
                  className={classes.assigningBtns}
                  fullWidth
              >
            {t("unassign")}
          </CustomButton>)}
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default ProcessSelector;
