import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Clear from "@material-ui/icons/Clear";
import Done from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import {
  formatImagePath,
  getProcessAbbreviation,
  formatDateByLanguage,
} from "util";
import OccurrencesNotFound from "../../assets/Process_Overview.svg";
import { useSelector, useDispatch } from "react-redux";
import { close as closeExecutionManager } from "../../redux/slices/executionManagerSlice";
import get from "lodash/get";
import { toast } from "react-toastify";
import { useQuery } from "@redux-requests/react";
import {
  fetchSrQueue,
  fetchSrQueueExecutions,
  removeExecutionFromSrQueue,
  revokePriorityFromAnExecution,
  updateSrExecutionPriority,
} from "../../redux/actions/services";
import {
  FETCH_CURRENT_USER,
  FETCH_SR_QUEUE_EXECUTIONS,
} from "../../redux/constants";
import PriorityChip from "../Services/components/Process/components/PriorityChip";
import StatusBadge from "components/StatusBadge";
import ConfirmMessage from "../../components/ConfirmMessage";
import DataNotFound from "components/DataNotFound";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import CircularLoader from "../../components/Loaders/CircularLoader";
import useStyles from "./style";

function ExecutionManager() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open, queueId } = useSelector(
    ({ executionManager }) => executionManager
  );
  const queue = useSelector(({ requests }) => get(requests, "queries.FETCH_SR_QUEUE.data"));
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [executionToDelete, setExecutionToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isQueueLoading, setIsQueueLoading] = useState(false);
  const [newPriority, setNewPriority] = useState(null);
  const [takenPriorities, setTakenPriorities] = useState([]);
  const [lastClickedPriorityIndex, setLastClickedPriorityIndex] = useState(null);
  const columns = [
    "Priority",
    "Process",
    "Schedule",
    "task",
    "allocated.priority",
    "Resource",
    "Launching",
    "Trigger",
    "Action",
  ];
  const { data: executions } = useQuery({
    type: FETCH_SR_QUEUE_EXECUTIONS,
  });

  useEffect(() => {
    if (queueId) {
      setIsQueueLoading(true);
      dispatch(fetchSrQueue(queueId)).then(() => {
        dispatch(fetchSrQueueExecutions(queueId)).then(() => {
          setIsQueueLoading(false);
        });
      });
    }
  }, [queueId]);

  useEffect(() => {
    if (Array.isArray(executions)) {
      let taken = [];
      // there is one execution it's already in first position no need to prioritize
      if (executions.length === 1) taken.push(1, 2, 3);
      else {
        taken = executions
          .filter((execution) => execution.usePriorityNum)
          .map((exec) => exec.displayPriorityNum);
      }
      setTakenPriorities(taken);
    }
  }, [executions]);

  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setOpenDeletePopup(!openDeletePopup);
  };

  const confirmDeletion = () => {
    if (executionToDelete) {
      setIsLoading(true);
      dispatch(
        removeExecutionFromSrQueue(executionToDelete, () => {
          dispatch(fetchSrQueueExecutions(queueId));
          handleCloseConfirmationPopup();
        })
      );
    }
  };

  const updatePriority = (executionToUpdate) => {
    if (executionToUpdate) {
      if (newPriority === 0) {
        dispatch(
          revokePriorityFromAnExecution(executionToUpdate.id, () => {
            dispatch(fetchSrQueueExecutions(queueId));
          }),
          () => toast.error(t("something went wrong"))
        );
        return;
      }
      dispatch(
        updateSrExecutionPriority(executionToUpdate, newPriority, () => {
          dispatch(fetchSrQueueExecutions(queueId));
        }),
        () => toast.error(t("something went wrong"))
      );
    }
  };

  const isPriorityAllowed = (priority) => {
    // if no priority is taken  , only priority 1 is permitted
    if (takenPriorities.length === 0) {
      return priority === 1;
    }
    // else  only last taken priority + 1  is allowed
    return priority - takenPriorities[takenPriorities.length - 1] === 1;
  };

  const SectionTitle = ({ title, count = 0 }) => (
    <Box className={classes.sectionTitleBox}>
      <Typography className={classes.sectionTitle}>
        {title}
        {" "}
      </Typography>
      <PriorityChip priority={count} style={classes.chipCount} />
    </Box>
  );

  const close = () => dispatch(closeExecutionManager());

  const handlePriorityChanged = (event) => {
    setNewPriority(event.target.value);
  };

  return (
    <>
      <CustomDialog
        open={open}
        onClose={close}
        classes={{
          paper: classes.dialogPaper,
        }}
        maxWidth={false}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          {!isQueueLoading && (
            <Typography variant="h5" className={classes.headerTitle}>
              {queue?.name}
            </Typography>
          )}
          <CustomCloseButton onClick={close} />
        </DialogTitle>
        <Divider className={classes.divider} />
        <DialogContent className={classes.mainDialog}>
          {isQueueLoading ? (
            <CircularLoader height="100%" />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={4} display="flex">
                <Card className={classes.cardBox}>
                  <CardContent>
                    <SectionTitle
                      title={t("queue.management.nb-licences")}
                      count={queue?.nbLicences}
                    />
                  </CardContent>
                </Card>
                <Card className={classes.cardBox}>
                  <CardContent>
                    <SectionTitle
                      title={t("processesSelector.assignedProcess")}
                      count={queue?.processes.length}
                    />
                    {queue?.processes.length > 0 && (
                      <List className={classes.list}>
                        {queue?.processes.map((process) => (
                          <ListItem
                            key={process.id}
                            className={classes.listItem}
                          >
                            <Grid item xs={1}>
                              {process?.icon ? (
                                <img
                                  src={formatImagePath(process.icon)}
                                  align="left"
                                  alt="Service"
                                  className={classes.image}
                                />
                              ) : (
                                <Avatar
                                  className={classes.resourceAvatar}
                                  alt="avatar"
                                >
                                  {getProcessAbbreviation(
                                    process?.processDescription
                                      ?.processDisplayName
                                  )}
                                </Avatar>
                              )}
                            </Grid>
                            <ListItemText className={classes.processName}>
                              {process.processDescription?.processDisplayName}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </CardContent>
                </Card>
                <Card className={classes.cardBox}>
                  <CardContent>
                    <SectionTitle
                      title={t("resource-selector.assigned-resources")}
                      count={queue?.resources.length}
                    />
                    {queue?.resources.length > 0 && (
                      <List className={classes.list}>
                        {queue?.resources.map((resource) => (
                          <ListItem key={resource.id} className={classes.listItem}>
                            <Grid item xs={1}>
                              <StatusBadge level={resource.displayStatus} />
                            </Grid>
                            <ListItemText className={classes.processName}>
                              {resource.resourceDisplayName}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={8}>
                <TableContainer className={classes.tableContainer}>
                  <Table>
                    {Array.isArray(executions) && executions.length > 0 ? (
                      <>
                        <TableHead className={classes.TAbHeader}>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell>{t(column)}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {executions.map((execution, i) => (
                            <TableRow
                              key={execution.id}
                              className={classes.tabRow}
                            >
                              <TableCell>
                                <Grid container>
                                  <Typography variant="h4" align="center">
                                    <span className={classes.lineContentValue}>
                                      {!executions[i].notEdit && (
                                        <PriorityChip
                                          priority={
                                            execution.displayPriorityNum
                                          }
                                          onClick={(event) => {
                                            setNewPriority(
                                              execution.displayPriorityNum
                                            );
                                            event.stopPropagation();
                                            if (
                                              takenPriorities.length !== 3
                                              || execution?.usePriorityNum
                                            ) {
                                              if (lastClickedPriorityIndex) {
                                                executions[
                                                  lastClickedPriorityIndex
                                                ].notEdit = false;
                                              }
                                              executions[i].notEdit = true;
                                              setLastClickedPriorityIndex(i);
                                            }
                                          }}
                                          style={classes.chipDefined}
                                        />
                                      )}
                                      {executions[i].notEdit && (
                                        <label>
                                          <FormControl
                                            variant="standard"
                                            sx={{ m: 1, minWidth: 120 }}
                                          >
                                            <Select
                                              labelId="demo-simple-select-standard-label"
                                              id="demo-simple-select-standard"
                                              value={newPriority}
                                              onChange={(e) => handlePriorityChanged(e)}
                                            >
                                              {execution?.usePriorityNum ? (
                                                <MenuItem value={0}>
                                                  <em>{t("revoke")}</em>
                                                </MenuItem>
                                              ) : (
                                                [1, 2, 3]
                                                  .filter(
                                                    (num) => !takenPriorities.includes(
                                                        num
                                                      )
                                                      && isPriorityAllowed(num)
                                                  )
                                                  .map((priority) => (
                                                    <MenuItem value={priority}>
                                                      <em>{priority}</em>
                                                    </MenuItem>
                                                  ))
                                              )}
                                            </Select>
                                          </FormControl>
                                          <IconButton
                                            component="span"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              updatePriority(execution);
                                            }}
                                            className={classes.checkIcon}
                                            size="small"
                                          >
                                            <Done />
                                          </IconButton>
                                          <IconButton
                                            color="danger"
                                            component="span"
                                            size="small"
                                            onClick={() => {
                                              setNewPriority(null);
                                              executions[i].notEdit = false;
                                            }}
                                            className={classes.uncheckIcon}
                                          >
                                            <Clear />
                                          </IconButton>
                                        </label>
                                      )}
                                    </span>
                                  </Typography>
                                </Grid>
                              </TableCell>
                              <TableCell className={classes.TAbBody}>
                                {
                                  execution.process.processDescription
                                    ?.processDisplayName
                                }
                              </TableCell>
                              <TableCell className={classes.TAbBody}>
                                {
                                  execution.scheduleName
                                }
                              </TableCell>
                              <TableCell className={classes.TAbBody}>
                                {
                                  execution.taskName
                                }
                              </TableCell>
                              <TableCell className={classes.TAbBody}>
                                {
                                  execution?.process?.processRestriction?.priority
                                }
                              </TableCell>
                              <TableCell className={classes.TAbBody}>
                                {execution.resource?.resourceDisplayName}
                              </TableCell>
                              <TableCell className={classes.TAbBody}>
                                {formatDateByLanguage(execution.launchingTime)}
                              </TableCell>
                              <TableCell className={classes.TAbBody}>
                                {t(execution.executionTrigger)}
                              </TableCell>
                              <TableCell className={classes.TAbBody}>
                                <Grid container item justify="flex-end">
                                  <Tooltip
                                    placement="top"
                                    title={t("tooltip.action.delete")}
                                  >
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        setOpenDeletePopup(true);
                                        execution.username = currentUser?.fullName;
                                        setExecutionToDelete(execution);
                                      }}
                                    >
                                      <DeleteIcon
                                        className={classes.deleteButton}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    ) : (
                      <DataNotFound
                        message={t("no.executions.message")}
                        icon={OccurrencesNotFound}
                        boxShadow="none"
                      />
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </CustomDialog>
      <ConfirmMessage
        message={t("Are you sure you want to delete the selected execution ?")}
        openStart={openDeletePopup}
        onCancel={handleCloseConfirmationPopup}
        onConfirm={(e) => {
          e.stopPropagation();
          confirmDeletion();
        }}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isLoading={isLoading}
      />
    </>
  );
}

export default ExecutionManager;
