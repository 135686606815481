import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@redux-requests/react";
import {
  countQueuesSchedules,
  deleteQueuesSchedules,
  deleteSrQueues,
  migrateQueuesSchedules,
} from "redux/actions/services";
import {
  triggerQueueAction,
  updateQueueAction,
} from "redux/slices/executionManagerSlice";
import { displayDeletedItems } from "util";
import { ASK_DELETE_ONE_QUEUE, QUEUE_DELETED } from "util/constants";
import ConfirmMessage from "components/ConfirmMessage/index.js";
import CustomDeleteIcon from "components/TableComponents/CustomActions/CustomDeleteIcon";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import { isPermitted } from "components/HasPermission";
import useStyles from "../../../../../components/TableComponents/style";

export default function QueueTableHeader({
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedIndeterminate,
  fetchEntities,
  page,
  rowsPerPage,
  enableDeleteMultiple,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isPopUpActionLoading, setIsPopUpActionLoading] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [action, setAction] = useState("");
  const [confirmBtnText, setConfirmBtnText] = useState("");

  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setOpenDeletePopup(false);
    setOpenPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const { data } = useQuery({ type: fetchEntities().type });
  const { queueAction } = useSelector(
    ({ executionManager }) => executionManager,
  );

  const deleteAll = (newSelected = selected) => {
    dispatch(
      deleteSrQueues(
        newSelected?.map((queue) => queue?.id),
        () => {
          setIsLoading(false);
          dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
          setSelected([]);
          handleCloseConfirmationPopup();
          toast.success(displayDeletedItems(selected, "name", t("queueSingle"), t("queuePlural"), t));
        },
        () => {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        },
      ),
    );
  };
  React.useEffect(() => {
    if (queueAction === QUEUE_DELETED) {
      handleCloseConfirmationPopup();
      setSelected([]);
      dispatch(updateQueueAction({ queueAction: null }));
      dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
    }
  }, [queueAction]);

  const onClick = () => {
    setIsLoading(true);
    const newSelected = selected;
    // if only one queue is selected , trigger action to AdminTableRow to reuse delete one queue logic
    if (newSelected.length === 1) {
      dispatch(
        triggerQueueAction({
          queueAction: ASK_DELETE_ONE_QUEUE,
          id: newSelected[0].id,
        }),
      );
      return;
    }
    // else check if queues have schedules with robot and without
    dispatch(countQueuesSchedules(selected?.map((item) => item?.id), handleQueuesCountSchedules));
  };
  const handleQueuesCountSchedules = (response) => {
    const countSchedules = response.data;
    if (countSchedules === 0) {
      // delete all
      // deleteAll();
      return;
    }

    // migrate to bp
    setAction("MIGRATE");
    setConfirmBtnText(t("confirm"));
    setOpenPopup(true);
    setPopUpMessage(
        t("queue.management.unassign.process.part2_plural", {
          nbSchedules: t("some"),
        })
    );
  }
  const closePopUp = () => {
    setIsPopUpActionLoading(false);
    setOpenPopup(false);
  };
  const confirm = () => {
    if (action === "MIGRATE") migrateSchedules();
    else {
      deleteAllSchedules();
    }
  };
  const migrateSchedules = () => {
    setIsPopUpActionLoading(true);
    dispatch(
      migrateQueuesSchedules(selected.map((item) => item?.id), (res) => {
        // proceed to delete all
        if (res.data) deleteAll();
        else {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        }
        closePopUp();
      }),
      () => {
        handleCloseConfirmationPopup();
        closePopUp();
      },
    );
  };

  const deleteAllSchedules = () => {
    setIsPopUpActionLoading(true);
    dispatch(
      deleteQueuesSchedules(selected.map((item) => item?.id), () => {
        // proceed to delete all
        deleteAll();
        closePopUp();
      }),
      () => {
        handleCloseConfirmationPopup();
        toast.error(t("something went wrong"));
        closePopUp();
      },
    );
  };
const headerCells = [
  { label: "" },
  { label: t("queue.management.name") },
  { label: t("queue.management.active-since") },
  { label: t("queue.management.nb-licences") },
  { label: t("queue.management.nb-licences") },
  {
    label:
  <>
    {enableDeleteMultiple
    && isPermitted(currentUser, "Delete SR Queue")
        && data?.totalElements > 0 && (
        <Grid container item xs={12} justify="flex-end" alignItems="center">
          <Grid item>
            <CustomDeleteIcon
              className={classes.rowActionButtons}
              aria-label="delete"
              disabled={isEmpty(selected)}
              onClick={() => {
                setOpenDeletePopup(true);
              }}
          />
            <ConfirmMessage
              message={t("queue.management.bulk-delete.confirm-msg")}
              openStart={openDeletePopup}
              onCancel={handleCloseConfirmationPopup}
              onConfirm={onClick}
              buttonConfirm={t("Delete")}
              buttonCancel={t("Cancel")}
              isLoading={isLoading}
          />
          </Grid>
          <Grid item>
            <Checkbox
              checked={checkedAll}
              className={classes.rowActionButtons}
              indeterminate={checkedIndeterminate}
              onChange={(e) => {
                handleSelectedAll(e.target.checked);
              }}
          />
          </Grid>
        </Grid>
    )}
    <ConfirmMessage
        message={popUpMessage}
        openStart={openPopup}
        onCancel={() => {
          closePopUp();
          handleCloseConfirmationPopup();
        }}
        onConfirm={confirm}
        buttonConfirm={confirmBtnText}
        buttonCancel={t("Cancel")}
        isLoading={isPopUpActionLoading}
    />
  </>
  }
];
  return (
    <CustomTableHeader labels={headerCells} />
  );
}
