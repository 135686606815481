import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import InformationIcon from "components/InformationIcon";
import { updateFilter } from "redux/slices/exceptionFilter";
import { exceptionCurrentModule } from "util/index";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "../../style";

export default function StateItem({ state, isLoading, isLast }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const filterExceptionList = useSelector(
      ({ filterListException }) => filterListException,
  );
  const pushTo = () => {
    if (state?.pushTo) {
      dispatch(
          updateFilter({
            ...filterExceptionList,
            [exceptionCurrentModule.ITEMS]: {
              pageNo: 0,
              workflowStatus: ["READY", "TAKEN"],
            },
          }),
      );
      history.push("/exceptions/exception?fdash=true")
    }
  }
  return (
    <ButtonBase component={Grid} item container xs={state.xs} alignItems="center" className={state?.pushTo ? classes.pointerCursor : classes.defaultCursor} disableRipple={!state?.pushTo} onClick={pushTo}>
      <Grid item xs={3}>
        <img src={state.icon} alt={state.label} className={classes.stateIcon} />
      </Grid>
      <Grid item xs={8}>
        {isLoading ? (
          <CircularLoader justifyContent="flex-start" height={20} size={15} />
      ) : (
        <Typography component="h4" className={classes.stateTitle}>
          {state.state}
        </Typography>
      )}
        <InformationIcon
          titleContent={state.tooltipTitle}
          originalContent={(

            <Typography component="h6" className={classes.stateSubtitle}>
              {t(state.label)}
            </Typography>
          )}
          placement="right"
      />
      </Grid>
      {!isLast && (<Divider orientation="vertical" className={classes.divider} />)}
    </ButtonBase>

  );
}
