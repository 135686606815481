import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Query } from "@redux-requests/react";
import { useHistory } from "react-router-dom";
import DataNotFound from "../../../components/DataNotFound";
import AlertsTableFilter from "./AlertsTableFilter/index.js";
import AlertsTableRow from "./AlertsTableRow/index.js";
import NoAlerts from "../../../assets/NoAlerts.svg";
import { useQuery } from "../../../components/Filter/index.js";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import {
  updateFilter,
  updateModule,
} from "../../../redux/slices/alertFilterSlice.js";
import CustomPagination from "pages/Services/components/CustomPagination";
import CustomTableContainer from "../../../components/TableComponents/CustomTableContainer";
import CustomTable from "../../../components/TableComponents/CustomTable";
import CustomTableHeader from "components/TableComponents/CustomTableHeader";
import PageHeader from "components/PageHeader";

export default function AlertsTable({
  displayFields,
  headerCells,
  entityBaseUrl,
  fetchEntities,
  deleteEntity,
  entityQuery,
  labels,
  searchField,
  isDefaultAlertsPage,
  isCustomAlertsPage,
  statusList,
  typeList,
  handleAlertDialogOpen,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const queryParam = query.get("q");
  const history = useHistory();
  const [countAlerts, setCountAlerts] = React.useState(0);

  const [selectedStatus, setSelectedStatus] = React.useState([]);
  const [selectedTypes, setSelectedTypes] = React.useState([]);
  const currModule = useSelector(
    ({ filterListAlert }) => filterListAlert.currModule,
  );

  const module = React.useMemo(() => history.location.pathname.split("/").slice(2, 3).join("/"));

  const filter = useSelector(({ filterListAlert }) => filterListAlert);

  useEffect(() => {
    dispatch(updateModule({ module }));
  }, []);

  useEffect(() => {
    if (module !== currModule) {
      dispatch(updateModule({ module }));
    }
  }, [module]);

  const handleFilterValueChange = (e) => {
    dispatch(
      updateFilter({
        ...filter,
        [module]: {
          ...filter[module],
          searchCriteria: e.target.value,
          pageNo: 0,
        },
      }),
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        ...filter,
        [module]: { ...filter[module], pageNo: newPage },
      }),
    );
  };

  const handleNext = (page) => {
    dispatch(
      updateFilter({
        ...filter,
        [module]: { ...filter[module], pageNo: page + 1 },
      }),
    );
    }
    const handlePrevious = (page) => {
      dispatch(
        updateFilter({
          ...filter,
          [module]: { ...filter[module], pageNo: page - 1 },
        }),
      );
    }

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        ...filter,
        [module]: {
          ...filter[module],
          pageNo: 0,
          pageSize: parseInt(event.target.value, 10),
        },
      }),
    );
  };

  const handleChangeStatus = (event) => {
    setSelectedStatus(event.map(({ code }) => code));
    dispatch(
      updateFilter({
        ...filter,
        [module]: { ...filter[module], status: event.map(({ code }) => code), pageNo: 0 },
      }),
    );
  };
  const handleChangeTypes = (value) => {
    setSelectedTypes(value);
    dispatch(
      updateFilter({
        ...filter,
        [module]: { ...filter[module], types: value.map((item) => item.code), pageNo: 0 },
      }),
    );
  };
  const handleChangeDivisions = (value) => {
    dispatch(
      updateFilter({
        ...filter,
        [module]: { ...filter[module], divisions: value.map(({ id }) => id), pageNo: 0 },
      }),
    );
  };

    const handleChangeFleets = (value) => {
        dispatch(
            updateFilter({
                ...filter,
                [module]: { ...filter[module], fleets: value.map(({ id }) => id), pageNo: 0 },
            }),
        );
    };

  const fetchEntitiesWithFilter = () => dispatch(
    fetchEntities({ ...filter[module], [searchField]: queryParam }),
  );

  const fetchEntitiesReset = () => {
    const pageNo = (countAlerts - 1) % filter[module].pageSize === 0
      ? filter[module].pageNo - 1
      : filter[module].pageNo;
    return dispatch(
      fetchEntities({
        ...filter[module],
        pageNo: pageNo === -1 ? 0 : pageNo,
        pageSize: filter[module].pageSize,
        [searchField]: queryParam,
      }),
    ).then(() => dispatch(
        updateFilter({
            ...filter,
            [module]: { ...filter[module], pageNo: pageNo === -1 ? 0 : pageNo },
        }),
    ))
  };

  useEffect(() => {
    dispatch(
      fetchEntities({
        ...filter[module],
        status: selectedStatus,
        types: selectedTypes,
      }),
    );
  }, [filter[module], selectedTypes, selectedStatus]);

  useEffect(() => {
    dispatch(fetchEntities(filter[module]));
  }, [filter]);

  return (
    <Grid xs={12}>
      <PageHeader title={labels.title} />
      <AlertsTableFilter
        pageSize={filter[module].pageSize}
        fetchEntities={fetchEntities}
        searchLabel={labels.searchPlaceHolder}
        searchField={searchField}
        handleFilterValueChange={handleFilterValueChange}
        selectedStatus={selectedStatus}
        selectedTypes={selectedTypes}
        handleChangeStatus={handleChangeStatus}
        handleChangeTypes={handleChangeTypes}
        handleChangeDivisions={handleChangeDivisions}
        handleChangeFleets={handleChangeFleets}
        statusList={statusList}
        typeList={typeList}
        entityBaseUrl={entityBaseUrl}
        handleAlertDialogOpen={handleAlertDialogOpen}
      />
      <CustomTableContainer>
        <CustomTable>
          <Query type={entityQuery} loadingComponent={CircularLoader}>
            {({ data }) => {
                const length = data.totalElements;
                setCountAlerts(length);
                return data.content?.length > 0 ? (
                  <>
                    <CustomTableHeader labels={headerCells} />
                    {data.content.map((row, index) => (
                      <AlertsTableRow
                        row={row}
                        index={index}
                        displayFields={displayFields}
                        entityBaseUrl={entityBaseUrl}
                        fetchEntities={fetchEntitiesWithFilter}
                        deleteEntity={deleteEntity}
                        deleteEntityLabel={labels.deleteEntity}
                        isDefaultAlertsPage={isDefaultAlertsPage}
                        isCustomAlertsPage={isCustomAlertsPage}
                        headerCellTitles={headerCells}
                        fetchEntitiesReset={fetchEntitiesReset}
                        handleAlertDialogOpen={handleAlertDialogOpen}
                      />
                    ))}
                  </>
                ) : (
                  <DataNotFound message={t("no.alerts.message")} icon={NoAlerts} />
                );
              }}
          </Query>
        </CustomTable>
      </CustomTableContainer>
      {countAlerts > 0 && <CustomPagination
        rowsPerPageOptions={[5, 10, 25]}
        count={countAlerts ?? 0}
        rowsPerPage={filter[module].pageSize}
        page={filter[module].pageNo}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />}
    </Grid>
  );
}
