import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({

  field: {
    fontWeight: 800,
    wordWrap: "break-word",
    color: theme.palette.primary.main,
  },
  value: {
    wordWrap: "break-word",
    "& a": {
      textDecoration: "none",
      color: "inherit"
    },
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  closeButton: {
    color: theme.custom.color.color6,
    marginTop: theme.spacing(-0.5),
    marginRight: theme.spacing(2.5),
  },
  dialogContent: {
    margin: theme.spacing(1),
    maxHeight: "calc(100vh - 300px)",
    overflowY: "auto",
  },
  dataGrid: {
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.hoverColor,
    },
    borderRadius: "5px",
    padding: theme.spacing(0, 1),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: 20,
    color: theme.custom.color.color6,
    padding: theme.spacing(0.5),
  },
  linkIcon: {
    fontSize: 16,
    opacity: 0.8,
    marginBottom: -3,
    marginLeft: 3
  },
  dialogHeader: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dialogPaper: {
    minWidth: "40%",
    padding: "0",
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "700",
    color: theme.palette.primary.main,
  },
}));
