import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  chip: {
    maxWidth: "calc(95% - 65px)",
    height: 25,
    backgroundColor: theme.custom.color.containersBorder,
    fontSize: 12,
    borderRadius: 4,
    border: `1px ${theme.custom.color.chartColor1} solid`,
    marginRight: 2
  },
  chipIcon: {
    width: 15
  },
  chipText: {
    fontSize: 12,
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  itemText: {
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  chipBadge: {
    position: "relative",
    transform: "revert",
    fontSize: 9,
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default
  },
  chipBadgeClose: {
    backgroundColor: theme.custom.color.chartColor3,
    cursor: "pointer"
  },
  chipTag: {
    position: "absolute",
    right: 21,
    visibility: "visible",
    display: "block"
  },
  closeIcon: {
    fontSize: 12,
    cursor: "pointer"
  },
  selectedInput: {
    "& .MuiAutocomplete-input": {
      width: 10
    }
  },
  selectedMonoInput: {
    fontSize: 14,
    maxWidth: "90%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  customRenderOptionText: {
    maxWidth: "85%"
  },
  optionItem: {
    paddingRight: 4
  },
  optionText: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  dot: {
    backgroundColor: "#44b700",
    color: "#44b700",
    marginRight: 5,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    height: 10,
    width: 10,
    borderRadius: "50%",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: "\"\"",
    },
  },
  OFFLINE: {
    backgroundColor: theme.custom.color.color6,
    color: theme.custom.color.color6,
  },
  ACTIVE: {
    backgroundColor: theme.custom.color.greenStatus,
    color: theme.custom.color.greenStatus,
  },
  UNKNOWN: {
    backgroundColor: theme.custom.color.chartColor4,
    color: theme.custom.color.chartColor4,
  },
  IDLE: {
    backgroundColor: theme.custom.color.blueStatus,
    color: theme.custom.color.blueStatus,
  },
  badge: {
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  caret: {
    caretColor: "transparent",
  },
  tooltip: {
    marginLeft: 60,
    marginTop: -15,
    maxWidth: 250
  },
  groupLabel: {
    color: theme.custom.color.inputShrink,
    fontSize: "1rem",
    marginLeft: theme.spacing(3)
  }
}));
