import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ProcessSelector from "../../../ProcessSelector";
import { useDispatch } from "react-redux";
import { fetchProcessesListByFleet } from "../../../../../redux/actions/services";
import { fetchAllProcesses } from "util";

export default function DivisionProcess({
  selectedFleetId,
  isEdit,
  assignedProcessesData,
  setProcessesField,
  isViewMode,
}) {
  const dispatch = useDispatch();
  const [assignedProcesses, setAssignedProcesses] = React.useState(
    assignedProcessesData ?? []
  );
  const [availableProcesses, setAvailableProcesses] = React.useState([]);

  useEffect(() => {
    if (isEdit) setAssignedProcesses(assignedProcessesData);
  }, [isEdit]);

  useEffect(() => {
    setProcessesField("processes", assignedProcesses);
  }, [assignedProcesses]);

  useEffect(() => {
    const currentProcessesIds = assignedProcessesData?.map(({ id }) => id);
    dispatch(
      fetchProcessesListByFleet(selectedFleetId, fetchAllProcesses)
    ).then((res) => setAvailableProcesses(
        res?.data?.list?.filter(
          ({ id }) => !currentProcessesIds?.includes(id)
        ) ?? []
      ));
  }, [selectedFleetId]);

  return (
    <Grid item xs={12} spacing={1}>
      <ProcessSelector
        availableProcesses={availableProcesses}
        selectedProcesses={assignedProcesses}
        isDisabled={isViewMode}
        setAvailableProcesses={setAvailableProcesses}
        setSelectedProcesses={setAssignedProcesses}
      />
    </Grid>
  );
}
