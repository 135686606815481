import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "transparent",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  warningColor: {
    color: theme.custom.color.color3,
  },
  errorColor: {
    color: theme.custom.color.color4,
  },
  infoColor: {
    color: theme.palette.secondary.main,
  },
  iconStyle: {
    verticalAlign: "middle",
    marginRight: 6,
    fontSize: 16
  },
  active: {
    color: theme.palette.secondary.main,
    marginLeft: 5,
    cursor: "pointer",
  },
  inactive: {
    color: theme.custom.color.alertDefaultIconColor,
    marginLeft: 5,
    cursor: "pointer",
  },
  subscribe: {
    color: theme.palette.background.default,
    backgroundColor: theme.custom.color.chartColor2,
    cursor: "pointer",
    view: "contained",
    "&:hover": {
      backgroundColor: theme.custom.color.chartColor2,
    },
  },
  unsubscribe: {
    color: theme.palette.background.default,
    backgroundColor: theme.custom.color.chartColor1,
    view: "contained",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.custom.color.chartColor1,
    },
  },
}));
