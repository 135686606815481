import React, { useEffect, useState } from "react"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useTranslation } from "react-i18next";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import useStyles from "./styles";

const isValued = (value) => ((value || `${value}` === "0") && (isArray(value) ? !isEmpty(value) : true))
const getBorderColor = (props, theme) => {
    if (props.isOutlined && !props.disabled) {
        return theme.custom.color.runningBackground;
    } if (isValued(props?.value) && (!props?.isViewMode && !props.disabled)) {
        return theme.custom.color.chartColor2;
    } if (props.disabled) {
        return theme.custom.color.chartColor4;
    } return theme.custom.color.chartColor1;
};
const CustomInputBase = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(1.8),
            color: theme.palette.primary.main,
            fontWeight: 600
        },
    },
    input: {
        position: "relative",
        backgroundColor: "transparent",
        border: (props) => (props.isOutlined ? "2px solid" : ""),
        borderBottom: (props) => (props.isOutlined ? "" : "3px solid"),
        borderColor: (props) => getBorderColor(props, theme),
        fontSize: 15,
        padding: (props) => (props.isOutlined ? 9 : ""),
        paddingLeft: (props) => (props.isOutlined ? 15 : ""),
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:hover": {
            borderColor: (props) => (props.disabled ? theme.custom.color.chartColor4 : theme.custom.color.chartColor2),
        },
    },
}))(InputBase);

export default function CustomSelectField({
 value,
 label,
 labelClassName,
 customOptionLabel,
 formControlClassName,
 additionalMenuNode,
 onChange,
 register,
 options,
 optionValue,
 optionLabel,
 multiple,
 disabled,
 error,
 selectMenuClassName,
 children,
 fullWidth = true,
 isOutlined = false,
 isViewMode = false,
 isCustom = false,
    ...rest
}) {
    const classes = useStyles();
    const { t } = useTranslation()
    const enableShrink = (value) => !(Array.isArray(value) && value.length === 0)
    const [iconComponent, setIconComponent] = useState(null);
    const [iconClass, setIconClass] = useState("");
    const [input, setInput] = useState(<> </>);
    const [classNameLabel, setClassNameLabel] = useState("");

    useEffect(() => {
        if (isCustom) {
            setIconComponent(isCustom ? ExpandMoreIcon : ArrowDropDownIcon);
            isViewMode ? setIconClass(classes.popupIconDefault) : setIconClass(disabled ? classes.popupIconDisabled : classes.popupIcon);
            setInput(<CustomInputBase isOutlined={isOutlined} isViewMode={isViewMode} />);
        } else {
            setIconComponent(ArrowDropDownIcon);
            setIconClass(disabled ? classes.popupIconDisabled : "");
            setInput(null);
        }

        if (value) {
            setClassNameLabel(classes.inputLabelShrinked);
        } else if (disabled) {
            setClassNameLabel(classes.popupIconDisabled);
        }
        else {
            setClassNameLabel(classes.inputLabel);
        }
    }, [isCustom, disabled, value, isOutlined]);

    return (
      <FormControl className={formControlClassName} fullWidth={fullWidth}>
        {label && (
          <InputLabel shrink={!!value && enableShrink(value)} className={classNameLabel}>{label}</InputLabel>
          )}
        <Select
                key={`select-${value}`}
                value={value}
                multiple={multiple}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                    getContentAnchorEl: null,
                }}
                IconComponent={iconComponent}
                classes={{ icon: iconClass, selectMenu: selectMenuClassName }}
                input={input}
                onChange={onChange}
                disabled={disabled}
                variant={isOutlined ? "outlined" : "standard"}
                {...register}
                error={error}
                {...rest}
                autoFocus={false}
            >

          { children || options?.map((row, i) => (
            <MenuItem
                        key={`${row[optionLabel]}-${i}`}
                        value={optionValue ? row[optionValue] : row}
                    >
              {customOptionLabel ? customOptionLabel(row) : t(row[optionLabel])}
            </MenuItem>
                ))}
          {additionalMenuNode}
        </Select>
        {error && <FormHelperText error>{rest?.helperText}</FormHelperText>}
      </FormControl>
    )
}
