import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    pagination: {
        marginTop: "auto",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
        color: theme.palette.primary.main,
        fontSize: 13
    },
    range: {
        marginRight: 5,
        marginLeft: 20
    },
    root: {
        textTransform: "none",
        fontWeight: "700"
    },
    active: {
        color: theme.custom.color.color2,
        cursor: "pointer",
        borderBottom: `2px solid ${theme.custom.color.color6}`,
        paddingBottom: "0px",
        paddingLeft: 2,
        paddingRight: 2,
        fontWeight: "700"
    },
    inactive: {
        color: theme.custom.color.chartColor4,
        cursor: "pointer",
        marginLeft: 2,
        marginRight: 2,
        paddingLeft: 2,
        paddingRight: 2,
        fontWeight: "700"
    },
    paginationContainer: {
        margin: 4
    },
    customTypography: {
        cursor: "pointer",
        position: "relative",
        display: "inline-block",
        "&::after": {
            content: "''",
            position: "absolute",
            left: "-10%",
            bottom: 0,
            width: "120%",
            borderBottom: `2px solid ${theme.custom.color.chartColor2}`,
        },
        lineHeight: 1,
        fontWeight: "700",
        paddingBottom: 4,
    },
    paginationText: {
        fontWeight: "700"
    }
}));
