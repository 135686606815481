import React from "react";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { getApiDomain } from "util";
import { ReactComponent as PersonIcon } from "../../../assets/common/personicon.svg";
import useStyles from "./style.js";

export default function AvatarTableCell({
  indexRow,
  valPath,
  valAvatarPath,
  indexField,
}) {
  const classes = useStyles();

  const getImageURL = (path) => {
    const imageUrl = indexField === "tagIcon"
        ? `${getApiDomain()}api/orchestrator/tags/${indexRow}/icon`
        : `${getApiDomain()}api/admin${path}`;

    const checkImageExists = (url) => new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = url;
      });

    return checkImageExists(imageUrl) ? imageUrl : null;
  };
  const getInitials = (name) => (name
      ? name
          .split(" ")
          .reduce(
            (acc, word, index) => (index === 0 ? word.charAt(0).toUpperCase() : acc),
            "",
          )
      : null);

  let avatarComponent;
  const avatarPath = valAvatarPath;
  const valuePath = valPath;
  if (avatarPath) {
    avatarComponent = (
      <Avatar
        className={classes.userAvatar}
        alt={valuePath}
        src={getImageURL(avatarPath)}
      />
    );
  } else if (getInitials(valuePath)) {
    avatarComponent = (
      <Avatar className={clsx(classes.userAvatar, classes.avatarColor)}>
        {getInitials(valuePath)}
      </Avatar>
    );
  } else {
    avatarComponent = (
      <Avatar className={clsx(classes.userAvatar, classes.defaultAvatar)}>
        <PersonIcon />
      </Avatar>
    );
  }

  return avatarComponent;
}
