import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    chipIcon: {
        fontSize: 16,
        margin: 0
    },
    chip: {
        display: "flex",
        flexDirection: "column",
        height: 51,
        width: 31,
        gap: "5px",
        padding: 0,
        alignItems: "center",
        borderRadius: 4,
        fontSize: 10,
        fontWeight: 500
    },
    label: {
        fontSize: 14,
    },
    checkboxRoot: {
        color: theme.custom.color.chartColor1,
    },
    checkboxChecked: {
        color: `${theme.custom.color.chartColor2} !important`,
    },
    container: {
        display: "grid",
        rowGap: "10px",
    },
    input: {
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: "400",
        "& .MuiInput-underline:not(.Mui-disabled):not(.Mui-error):before, & .MuiInput-underline:not(.Mui-disabled):not(.Mui-error):after, & .MuiInput-underline:not(.Mui-disabled):hover:not(.Mui-error):before": {
            borderBottomColor: theme.custom.color.chartColor2,
            borderWidth: 3,
        },
        "& .Mui-disabled:before": {
            borderBottom: "solid",
        },
    },
    checkboxContainer: {
        width: "fit-content"
    }
}));
