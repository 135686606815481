import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: fade(theme.palette.background.default, 0.8),
    overflowX: "hidden",
    overflowY: "hidden",
  },
  grid: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5px",
    margin: "auto",
  },
  contentContainer: {
    maxHeight: 300,
    overflowY: "auto",
    padding: "24px",
  },
  cardActions: {
    marginRight: theme.spacing(2.75),
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
  typography: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: "600",
  },
  paper: {
    padding: "20px",
  },
  processName: {
    maxWidth: "90%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  processNameTooltip: {
    top: "-23px !important",
  },
  tableTitle: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(-1),
    fontSize: "1rem",
    fontWeight: "800",
  },
  dialogTitle: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(-2.25),
    marginLeft: theme.spacing(2.5),
    fontSize: "1.5rem",
    fontWeight: "700",
  },
  dialogPaper: {
    width: "43%",
    borderRadius: "1%",
    padding: theme.spacing(2.5),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  processContainer: {
    backgroundColor: theme.custom.color.containerBackground,
    margin: "10px 0px 10px 0px",
    padding: "10px 10px 10px 10px",
  },
  processNameTitle: {
    marginLeft: theme.spacing(1.25),
  },
  riskMarginTitle: {
    marginLeft: theme.spacing(-0.5),
  },
}));
