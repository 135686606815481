import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  listItem: { borderRadius: "20px" },
  icon: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
  },
  dialogActionsContainer: {
    justifyContent: "center"
  },
  dialogTitle: {
    fontWeight: "800",
    fontSize: "1.3rem",
    color: theme.palette.primary.main,
  },
  dialogPaper: {
    width: "25%",
    borderRadius: "2%",
  },
  noResourceAlert: {
    color: theme.custom.color.color6,
    backgroundColor: theme.custom.color.lightPink,
    alignItems: "center",
    justifyContent: "center",
    height: "4rem",
  },
  alertText: {
    fontSize: "1rem",
    fontWeight: "700",
  },
  resourceName: {
    fontSize: "1.1rem",
    fontWeight: "600",
    color: theme.palette.primary.main,
  },
}));
