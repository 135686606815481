import React from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { formtNumberByLanguage } from "util";
import clsx from "clsx";
import useStyles from "./style.js";

export default function PageHeader({ title, filteredCount, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Typography
              variant="h5"
              color="secondary"
              className={clsx(rest.className, classes.pageHeaderTitle)}
          >
      {t(title)}
      {filteredCount ? (
        <Typography
                      variant="h6"
                      className={classes.pageFilteredTitle}
                  >
          {` ${formtNumberByLanguage(filteredCount)}`}
        </Typography>
              ) : null}
    </Typography>
  );
}
