import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants";
import { fetchReducedFleet } from "redux/actions/services";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardTodayData } from "redux/actions/services/dashboard";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import { MONITORING_TIME, MONITORING_TIME_Params } from "pages/newDashboard/dashboardUtils";
import PageHeader from "components/PageHeader";
import { isPermitted } from "components/HasPermission";
import DashboardFilter from "./DashboardFilter";
import DashboardStates from "./DashboardStates";
import FTESaved from "pages/newDashboard/DayView/FTESaved";
import AvgHandlingTime from "pages/newDashboard/DayView/AvgHandlingTime";
import SuccessRatePercentage from "pages/newDashboard/DayView/SuccesRatePercentage";
import Transactions from "pages/newDashboard/DayView/Transactions";
import WeeklyAverageHandlingTime from "pages/newDashboard/WeeklyView/WeeklyAverageHandlingTime";
import WeeklyFTESaved from "pages/newDashboard/WeeklyView/WeeklyFTESaved";
import WeeklySuccessRate from "pages/newDashboard/WeeklyView/WeeklySuccessRate";
import WeeklyTransactions from "pages/newDashboard/WeeklyView/WeeklyTransactions";
import useStyles from "./style";
// import Processes from "pages/newDashboard/DayView/Processes";

export default function Dashboard() {
  const classes = useStyles()();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [userFleet, setUserFleet] = useState();
  const [transactionsCompleted, setTransactionsCompleted] = useState();
  const [successRate, setSuccessRate] = useState();
  const [avgHandlingTime, setAvgHandlingTime] = useState();
  const [hoursSaved, setHoursSaved] = useState();
  const [exceptionsCounts, setExceptionsCounts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const filterDashboard = useSelector(
    ({ dashboardFilters }) => dashboardFilters,
  );
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const hasFtePermission = isPermitted(currentUser, "FTEs saved");
  const gridWeeklySize = hasFtePermission ? 4 : 6;

  const fetchNewDashboardStates = (withLoading = true) => {
    setIsLoading(withLoading);

    dispatch(fetchDashboardTodayData(filterDashboard, (res) => {
      setIsLoading(false);
      setTransactionsCompleted(res.data.completedTransactions);
      setSuccessRate(res.data.successRate);
      setAvgHandlingTime(res.data.averageHandlingTime);
      setHoursSaved(res.data.hoursSaved);
      setExceptionsCounts({
        systemExceptions: res.data.systemExceptions,
        businessExceptions: res.data.businessExceptions,
      })
      }));
  };

  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchNewDashboardStates, MONITORING_TIME_Params)
    return () => {
      clearInterval(fetchIntervalRef.current)
    }
  }, [filterDashboard])

  useEffect(() => {
    if (filterDashboard?.selectedDurationValue === "DAY") {
      fetchNewDashboardStates();
    }
  }, [filterDashboard]);

  useEffect(() => {
    dispatch(fetchReducedFleet(currentUser?.fleet?.id)).then((res) => {
      setUserFleet(res.data);
    });
  }, [dispatch]);

  return (
    <Grid container justify="space-between" className={classes.rootContainer}>
      {isPermitted(currentUser, "All time stats") && (
        <>
          <PageHeader title={t("newDashboard.AllStats.title")} className={classes.paddingTop} />
          <DashboardStates userFleet={userFleet} xs={12} />
        </>
      )}
      <PageHeader title={t("newDashboard.CurrentStats.title")} className={classes.paddingTop} />
      {filterDashboard?.selectedDurationValue === "DAY" ? (
        <Grid container item className={classes.container} alignItems="stretch">
          <Grid item xs={6}>
            <DashboardFilter />
          </Grid>
          <Grid container item xs={12}>
            {isPermitted(currentUser, "FTEs saved") ? (
              <Grid container item direction="column" xs={3} justify="space-between">
                <Grid item className={classes.halfHeight}>
                  <FTESaved userFleet={userFleet} hoursSaved={hoursSaved} isLoading={isLoading} />
                </Grid>
                <Box component={Grid} item className={classes.halfHeight}>
                  <AvgHandlingTime avgHandlingTime={avgHandlingTime} isLoading={isLoading} />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={3}>
                <AvgHandlingTime avgHandlingTime={avgHandlingTime} isLoading={isLoading} fillContainer />
              </Grid>
            )}
            <Grid item xs={3}>
              <SuccessRatePercentage successRate={successRate} isLoading={isLoading} />
            </Grid>
            <Grid item xs={3}>
              <Transactions transactionsCompleted={transactionsCompleted} exceptionsCounts={exceptionsCounts} isLoading={isLoading} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container item className={classes.container} alignItems="stretch">
          <Grid container item xs={12} className={classes.filterContainer}>
            <Grid container item xs={6}>
              <DashboardFilter />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            {hasFtePermission ? (
              <Grid container item direction="column" xs={3} justify="space-between">
                <Grid item className={classes.halfHeight}>
                  <WeeklyFTESaved userFleet={userFleet} />
                </Grid>
                <Box component={Grid} item className={classes.halfHeight}>
                  <WeeklyAverageHandlingTime />
                </Box>
              </Grid>) : (
                <Grid item xs={3}>
                  <WeeklyAverageHandlingTime />
                </Grid>
            )}
            <Grid item xs={gridWeeklySize}>
              <WeeklySuccessRate />
            </Grid>
            <Grid item xs={gridWeeklySize}>
              <WeeklyTransactions />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
