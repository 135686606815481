import React, {
 useCallback, useEffect, useMemo, useState
} from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import { useTheme, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { useTranslation } from "react-i18next";
import {
  exportDashboardAllResourcesProductivity,
  fetchDashboardAllRobotsProductivity,
  fetchDashboardAllRobotsProductivityCount
} from "redux/actions/services/dashboard";
import { fetchExportedFiles } from "redux/actions/services";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { secondsToFTPTime } from "util";
import CustomDialog from "components/CustomDialog";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";
// eslint-disable-next-line import/no-named-as-default
import { ReactComponent as SearchIcon } from "assets/common/searchicon.svg";
import DataNotFound from "components/DataNotFound";
import CircularLoader from "components/Loaders/CircularLoader";
import EnhancedTableHead from "components/DataTable/EnhancedTableHead";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "pages/Services/components/CustomPagination";
import ExportButton from "components/ExportButton";
import useStyles from "./style";

const CustomTableCell = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
    padding: "10px",
    borderBottom: `2px solid ${theme.custom.color.staticInput}`,
  },
}))(TableCell);
const sortFields = [
  { id: 1, sortField: "productivity", label: "productivity.hrs" },
  { id: 2, sortField: "productivity,DESC", label: "productivity.descending" },
  { id: 3, sortField: "productivity", label: "occupancy.ascending" },
  { id: 4, sortField: "productivity,DESC", label: "occupancy.descending" }
]
export default function AllRobotsProductivity({
  open,
  handleDialogClose,
  userFleet,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState({ id: 2, sortField: "productivity,DESC", label: "productivity.descending" });
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [robotsCount, setRobotsCount] = useState(0);

  const headCells = [
    { id: "robotName", label: "resource.management.resourceName" },
    { id: "productivity", label: "productivity.hrs" },
    { id: "occupancy", label: "robots.occupancy" },
  ];

  const debouncer = useCallback(
    debounce((newVale) => setSearchQuery(newVale), 500),
    []
  );

  const handleClose = () => {
    setIsLoading(false);
    setData(null);
    handleDialogClose();
  };

  const handleSearchQueryChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    debouncer(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleNext = (page) => {
    setPage(page + 1);
  };
  const handlePrevious = (page) => {
    setPage(page - 1);
  };

  const formattedData = useMemo(
    () => data?.productivity?.map((item, index) => ({
        id: item?.id,
        robotName: item?.entityName,
        productivity: item?.values?.[0],
        occupancy: data?.occupancy?.[index]?.values?.[0] ? `${data?.occupancy?.[index]?.values?.[0]?.toFixed(2)} (%)` : "--",
      })),
    [data, open]
  );

  const handleProductivityDisplay = (value) => (
    secondsToFTPTime(
      +value,
      userFleet?.hoursPerManDay,
      userFleet?.daysPerYear,
      t
    ));

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      const params = {
        searchQuery,
        period: filterDashboard?.selectedDurationValue,
        page,
        size: rowsPerPage,
        sort: sort.sortField
      };
      dispatch(fetchDashboardAllRobotsProductivity(params)).then((res) => {
        setData(res?.data);
        setIsLoading(false);
        dispatch(fetchDashboardAllRobotsProductivityCount(params)).then((res) => {
          setRobotsCount(res?.data ?? 0)
        })
      });
    }
  }, [
    searchQuery,
    filterDashboard?.selectedDurationValue,
    page,
    sort,
    rowsPerPage,
    open,
  ]);

  const onExportSuccess = () => {
    dispatch(fetchExportedFiles());
    toast.success(t("export.successful.notification"));
  }

  const onExportError = () => {
    toast.error(t("Unexpected Error"))
  }

  const handleExportResourcesProductivity = () => {
    const params = {
      searchQuery,
      period: filterDashboard?.selectedDurationValue,
      page,
      size: rowsPerPage,
      sort: sort.sortField
    };
    dispatch(exportDashboardAllResourcesProductivity({ ...params, onSuccess: onExportSuccess, onError: onExportError }))
  }

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
      fullWidth
      open={open}
      disableBackdropClick
      style={{ overflow: "unset" }}
      PaperProps={{
        square: true,
      }}
      title={
        <Typography className={classes.dialogTitle} component="span">
          {t("Resource Productivity")}
        </Typography>
      }
    >
      <Box width={48} position="absolute" top={10} right={10}>
        <CustomCloseButton aria-label="close" onClick={handleDialogClose} />
      </Box>
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <CustomTextField
              color="secondary"
              id="standard-adornment-search"
              type="text"
              value={searchValue}
              onChange={handleSearchQueryChange}
              label={t("Search")}
              InputProps={{
                endAdornment: (
                  <SearchIcon
                    stroke={theme.custom.color.chartColor2}
                    height={15}
                  />
                ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomAutoComplete
                  defaultValue={{ id: 2, sortField: "productivity,DESC", label: "productivity.descending" }}
                  options={sortFields}
                  optionLabel="label"
                  value={sort}
                  onChange={(e) => { setSort(e) }}
                  label={t("Sort By")}
              />
          </Grid>
          <Box marginLeft="auto" pr={1.5} pt={3} display="flex" alignItems="center">
            <ExportButton
                onClick={handleExportResourcesProductivity}
            />
          </Box>
        </Grid>
        <TableContainer className={classes.infoTableContainer}>
          {isLoading ? (
            <CircularLoader height="100%" />
          ) : (
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              width="100%"
            >
              {formattedData?.length === 0 ? (
                <DataNotFound
                  backgroundColor="transparent"
                  boxShadow="none"
                  iconHeight="20%"
                  iconWidth="20%"
                  padding={50}
                />
              ) : (
                <>
                  <EnhancedTableHead numSelected={0} headCells={headCells} />
                  <TableBody>
                    {formattedData?.length
                      && formattedData?.map((row, index) => (
                        <TableRow key={`productivity-table-row-${index}`}>
                          <CustomTableCell align="left">
                            <Typography
                              variant="caption"
                              className={classes.tableCellText}
                            >
                              {row?.robotName}
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            <Typography
                              variant="caption"
                              className={classes.tableCellText}
                            >
                              {handleProductivityDisplay(row?.productivity)
                                || "--"}
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            <Typography
                              variant="caption"
                              className={classes.tableCellText}
                            >
                              {row?.occupancy}
                            </Typography>
                          </CustomTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </>
              )}
            </Table>
          )}
        </TableContainer>
        <CustomPagination
          count={robotsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onNext={handleNext}
          onPrevious={handlePrevious}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </CustomDialog>
  );
}
