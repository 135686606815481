import React from "react";
import theme, { fontFamily } from "../../style/orchestratorTheme";
import {
  abbreviateNumber, formtNumberByLanguage, numberFloatFormat, secondsToFTPTime
} from "util/index";
import getDay from "date-fns/getDay";
import format from "date-fns/format";
import subWeeks from "date-fns/subWeeks";
import subQuarters from "date-fns/subQuarters";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";

export const LINE_CHART_DATASET_OPTION = {
  fill: false,
  borderColor: theme.palette.primary.main,
  borderWidth: 4,
  pointRadius: 5,
  pointHoverRadius: 6,
  pointBorderColor: theme.palette.background.default,
  pointBackgroundColor: theme.custom.color.chartColor3,
  pointBorderWidth: 3,
  pointHoverBorderWidth: 3.5,
  shadowOffsetX: 0,
  shadowOffsetY: 2,
  shadowBlur: 6,
  shadowColor: "#a9b2f5",
  pointShadowColor: theme.custom.color.chartColor3,
  pointShadowBlur: 2,
  pointShadowOffsetX: 0,
  pointShadowOffsetY: 0,
  backgroundColor: (ctx) => {
    const gradient = ctx.chart.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 220);
    gradient.addColorStop(0, "rgba(208,216,255,0.6)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
    return gradient;
  },
};

export const LINE_CHART_DATA_LABELS = (
  customParams = {},
  formatLabels = true
) => ({
  formatter: (value) => {
    if (customParams?.formatValue) {
      return customParams?.format(value);
    }
      return (formatLabels ? `${abbreviateNumber(value)}`.concat(customParams?.suffix ?? "") : `${value}`.concat(customParams?.suffix ?? ""))
  },
  color: theme.palette.primary.main,
  opacity: 1,
  anchor: "end",
  align: "top",
  font: {
    weight: 800,
    family: fontFamily,
  },
  padding: {
    bottom: 0,
  },
  ...customParams,
});

export const LINE_CHART_X_AXES = (customParams) => [
  {
    gridLines: {
      drawOnChartArea: false,
      display: false,
    },
    ticks: {
      fontColor: theme.palette.primary.main,
      display: true,
    },
    offset: customParams?.offset,
  },
];

export const LINE_CHART_TOOLTIP_OPTIONS = {
  yAlign: "none",
  backgroundColor: theme.palette.background.default,
  titleFontColor: theme.custom.color.chartColor2,
  bodyFontColor: theme.palette.primary.main,
  xPadding: 10,
  yPadding: 10,
  bodyFontStyle: "bold",
  cornerRadius: 4,
  shadowOffsetX: -3,
  shadowOffsetY: 1,
  shadowBlur: 20,
  shadowColor: "rgba(123,176,255,0.35)",
  displayColors: false,
};

const getWeekDays = (t) => {
    const weekDays = [t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat"), t("Sun")];
    const currentDay = getDay(new Date());
    return Array.from({ length: 7 }, (_, index) => {
        const dayIndex = (currentDay - index - 1 + 7) % 7;
        return t(weekDays[dayIndex]);
    }).reverse();
}

const getMonthWeeks = (t) => [
  `${t("Week")} ${format(subWeeks(new Date(), 3), "w")}`,
  `${t("Week")} ${format(subWeeks(new Date(), 2), "w")}`,
  `${t("Week")} ${format(subWeeks(new Date(), 1), "w")}`,
  `${t("Week")} ${format(new Date(), "w")}`,
];

const getYearQuarter = () => {
  const fmt = "'Q'q yy";
  return [
    format(subQuarters(new Date(), 3), fmt),
    format(subQuarters(new Date(), 2), fmt),
    format(subQuarters(new Date(), 1), fmt),
    format(new Date(), fmt)
  ];
};

export const chartPeriodLabels = (t, period) => {
  const labels = {
    LAST_WEEK: getWeekDays(t),
    LAST_MONTH: getMonthWeeks(t),
    LAST_YEAR: getYearQuarter(t),
  }

  return labels?.[period] ?? [];
};

export default function renderValue(options) {
    const {
        value,
        formatFct = numberFloatFormat,
        suffix = "",
        ...otherParams
    } = options;
    return !value && isNaN(value)
        ? "---"
        : `${formatFct(value, ...Object.values(otherParams))}${suffix}`;
}

export function formatAverageHandlingTime(seconds, md, ftp, t) {
  if (seconds < 60) {
    return `${formtNumberByLanguage(seconds)}${t("timeUnite.sec")}`;
  }
  const minutes = seconds / 60;
  if (minutes < 60) {
    return `${formtNumberByLanguage(minutes)}${t("timeUnite.min")}`;
  }
  const hours = seconds / 3600;
  return secondsToFTPTime(hours, md, ftp, t);
}

export const renderContent = (isLoading, values, component) => {
  if (isLoading) {
    return <CircularLoader /> }
  if (values?.every((value) => typeof (value) === "number")) {
    return component;
  } return (
    <DataNotFound
          backgroundColor="transparent"
          boxShadow="none"
          iconHeight={70}
          iconWidth={70}
          height={120}
          padding={7}
      />
  );
}

export const MONITORING_TIME = 3 * 60 * 1000;
// Array structure : [withLoading]
export const MONITORING_TIME_Params = [false];

export const DOUGHNUT_CHART_DATASET_DATA = (value, color) => {
  let graphColor;
  switch (color) {
    case "light":
      graphColor = theme.custom.color.chartColor2;
      break;
    default:
      graphColor = theme.palette.primary.main;
  }
  return (
      {
        datasets: [
          {
            data: [value, 100 - value],
            backgroundColor: [
               graphColor,
               theme.custom.color.chartColor1,
            ],
            borderWidth: 0,
          },
        ],
      }
  )
}

export const DOUGHNUT_CHART_DATASET_OPTION = {
  cutoutPercentage: 80,
  rotation: -1.125 * Math.PI,
  circumference: 1.25 * Math.PI,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
};

export const translationXValue = (percentage) => -Math.cos((2.25 * percentage - 22.5) * 0.01745) * DOUGHNUT_CHART_DATASET_OPTION.cutoutPercentage
    + (percentage > 50 ? 15 : 0);
export const translationYValue = (percentage) => -Math.sin((2.25 * percentage - 22.5) * 0.01745) * DOUGHNUT_CHART_DATASET_OPTION.cutoutPercentage;
